import axios, { AxiosResponse } from "axios"

import { apiConfig } from "./config"
import { Configuration } from "./swagger/configuration"
import { EsfirusTablePropsPagination } from "./hooks/useHandlerPaginationTable"
import { FormDataAviso } from "@components/FormParte/FormAviso"
import { delay } from "@helpers/utils"
import { APIDataResponse } from "@models/api-models"
import { NoticeDefinitive, NoticeDraft } from "@models/notices"

interface ValidateBorradorParams {
  id?: string
}

interface GetListFilters {
  from: number
  to: number
  company?: string | number
  customer?: string | number
  obra?: string | number
  originEmployee?: string
  targetEmployee?: string
  responsibleEmployee?: string
  status?: string
  type?: string
  pagination: EsfirusTablePropsPagination
}

interface GetListBorradoresResponseType {
  items: NoticeDraft[]
  total_items: number
}

interface GetListDefinitivosResponseType {
  items: NoticeDefinitive[]
  total_items: number
}

class AvisosService {
  basePath = ""

  constructor(_apiConfig: Configuration) {
    this.basePath = (_apiConfig.basePath || '') as string
  }

  async getBorradorByUniqueId(id: string | number): Promise<AxiosResponse<APIDataResponse<FormDataAviso>, any>> {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + `/avisos-borradores/${id}`,
        headers: {
          Authorization: accessToken
        },
        method: "get",
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
      throw error
    }
  }

  async getByUniqueId(id: string | number): Promise<AxiosResponse<APIDataResponse<FormDataAviso>, any>> {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + `/avisos/${id}`,
        headers: {
          Authorization: accessToken
        },
        method: "get",
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
      throw error
    }
  }

  async getListBorradores({ from, to, company, customer, obra, originEmployee, targetEmployee, responsibleEmployee, status, type, pagination }: GetListFilters): Promise<AxiosResponse<APIDataResponse<GetListBorradoresResponseType>, any>> {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/avisos-borradores",
        headers: {
          Authorization: accessToken
        },
        method: "get",
        params: {
          from,
          to,
          company_id: company === "" ? null : company,
          client_id: customer === "" ? null : customer,
          work_id: obra === "" ? null : obra,
          origin_employee_id: originEmployee === "" ? null : originEmployee,
          target_employee_id: targetEmployee === "" ? null : targetEmployee,
          responsible_employee_id: responsibleEmployee === "" ? null : responsibleEmployee,
          status: status === "" ? null : status,
          type: type === "" ? null : type,
          limit: pagination.sizePage,
          page: pagination.page
        }
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
      throw error
    }
  }

  async getList({ from, to, company, customer, obra, originEmployee, targetEmployee, responsibleEmployee, status, type, pagination }: GetListFilters): Promise<AxiosResponse<APIDataResponse<GetListDefinitivosResponseType>, any>> {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/avisos",
        headers: {
          Authorization: accessToken
        },
        method: "get",
        params: {
          from,
          to,
          company_id: company === "" ? null : company,
          client_id: customer === "" ? null : customer,
          work_id: obra === "" ? null : obra,
          origin_employee_id: originEmployee === "" ? null : originEmployee,
          target_employee_id: targetEmployee === "" ? null : targetEmployee,
          responsible_employee_id: responsibleEmployee === "" ? null : responsibleEmployee,
          status: status === "" ? null : status,
          type: type === "" ? null : type,
          limit: pagination.sizePage,
          page: pagination.page
        }
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
      throw error
    }
  }

  async createBorrador(data: FormDataAviso, isLastRetry?: boolean): Promise<AxiosResponse<APIDataResponse<NoticeDefinitive>, any>> {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`

    try {
      const resp = await axios({
        baseURL: this.basePath + "/avisos-borradores",
        headers: {
          Authorization: accessToken
        },
        method: "post",
        data: {
          ...data,
          date: data.date.valueOf(),
          time: data.time.valueOf()
        }
      })

      return resp
    } catch (error) {
      if (isLastRetry) {
        apiConfig?.catchError(error)
        throw error
      }

      // Hacemos un reintento después de tres segundos.
      await delay(3000)
      const resp = await this.createBorrador(data, true)
      return resp
    }
  }

  async validateBorradorAviso({ id }: ValidateBorradorParams): Promise<AxiosResponse<APIDataResponse<null>, any>> {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/avisos-borradores/transfer",
        headers: {
          Authorization: accessToken
        },
        method: "post",
        data: { id: id }
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
      throw error
    }
  }
}

export const avisosService = new AvisosService(apiConfig)
