import { useEffect } from "react";
import "./Modules.scss";
import { configurationService } from "@services/configApp";
import { useNavigate } from "react-router-dom";
import Header from "@components/Header/Header";
import Module from "./Module";
import { isAdmin, isResponsable, isSupervisor } from "@services/hooks/UserHook";

function Modules() {
  const navigate = useNavigate()

  const configs = configurationService.getConfig()
  let { modulos } = configs

  const isRolSuperadmin = isAdmin()
  const isRolSupervisor = isSupervisor()
  const isUserResponsable = isResponsable()
  const viewPedidos = isRolSuperadmin || isRolSupervisor || isUserResponsable

  useEffect(() => {
    if (configs && modulos) {
      const modulosActivos = configurationService.getModulosActivos()

      if (modulos && modulosActivos.length === 1) {
        switch (modulosActivos[0]) {
          case "partes":
            navigate("/partes")
            break;
          case "fichajes":
            navigate("/time-tracking")
            break;
          case "pedidos":
            navigate("/pedidos")
            break;

        }
      }
    }
  }, [configs])


  return (
    <>
      <Header />

      <div className="modules">
        {modulos?.partes && <Module title="Partes" url="/partes" />}
        {modulos?.fichajes && <Module title="Fichar" url="/time-tracking" />}
        {modulos?.pedidos && viewPedidos && <Module title="Pedidos" url="/pedidos" />}
        {modulos?.avisos && <Module title="Avisos" url="/borradores-avisos" />}
      </div>

    </>
  );
}

export default Modules;
