const textos = {
    "modulos": "Módulos",
    "configuration": "Configuración de API",
    "partes": "Partes",
    "fichajes": "Fichajes",
    "pedidos": "Pedidos",
    "avisos": "Avisos",
    'empleados': 'Empleados',
    "esconnect": "Esconnect",
    "customerTarifa": "Tarifa de cliente",
    "graphql": "GraphQL",
    "ftp_client": "FTP",
    "listado": "Listado",
    "cabeceraParte": "Cabecera parte",
    "buscadorObra": "Buscador obras",
    "lineasParte": "Lineas de parte",
    "lineasPedido": "Lineas de pedido",
    "report": "Report mensual",
    "listadoParteShowClosed": "Listado de partes, mostrar partes confirmados en Rol empleado ",
    "listadoParteMeses": "meses scroll partes",
    // "listadoParteMesesAdmin" : "",
    "allocationsOfPartsInChapters": "Imputaciones de partes en capítulos",
    "listadoParteShowResume": "Mostrar pie de totales en listado de partes a empleados",
    "partesConfirmar": "Necesario confirmar los partes",
    "partesValidar": "Necesario validar los partes ",
    "partesFirmar": "Necesario firmar partes",
    "partesObservaciones": "Crear observaciones (Cabecera de los partes)",
    "autoRefParte": "Valor de la referencia del parte",
    "parteShowRefParte": "Mostrar referencia del parte",
    "editTipoparte": "Tipo de parte editable",
    "allowFutureDate": "Permitir partes con fecha futura",
    "showTipoParte": "Mostrar tipo de parte",
    "filtroObraShowEmpresa": "Permitir mostrar empresa en el filtro de búsqueda de obras",
    "filtroObraShowReferencia": "Permitir mostrar referencia en el filtro/buscador de obras",
    "filtroObraShowResponsable": "Permitir mostrar responsable en el filtro/buscador de obras",
    "filtroObraShowCliente": "Permitir mostrar cliente en el filtro/buscador de obras",
    "strictFindObra": "Permitir búsqueda esctricta por código de obra",
    "lineaGastoFacturableByDefault": "La optión facturable será activa por defecto",
    "opcionFacturableActive": "Permitir modificar la opción de facturable",
    "allowObraWithoutResponsable": "Permitir seleccionar una obra sin un responsable asignado",
    "showManoObraCategoria": "Mostrar categoría de mano de obra",
    "manoObra": "Control mano de obra por horario o por cantidad",
    "manoObraCategoria": "Permitir cambiar la categoría de mano de obra en el parte",
    // "manoObraCategoriaDefault" : "",
    "showManoObraTipoHora": "Mostrar tipo de hora en el parte",
    "manoObraTipoHora": "Permitir cambiar el tipo de hora en el parte",
    // "manoObraTipoHoraDefault" : "",
    "manoObraDiferenteDia": "Permitir horas en diferentes dias (madrugada)",
    "descManoObra": "Añadir campo descripcion en Mano de obra ",
    "lineaParte": "Tipos de lineas de parte admitidas",
    "lineaParteToCopy": "Tipos de lineas de parte que se copiarán a empresa de origen",
    "lineaPedido": "Tipos de lineas de pedido admitidas",
    "reportMeses": "Report mensual , meses anteriores",
    // "reportMesesAdmin" : "",
    "reportCerrarDias": "Report mensual permitir cerrar dias",
    "filterAllHours": "Mostrar filtro tipos de hora",
    "resumenHorasExtra": "Mostrar horas extras en resumen",
    "calendario": "Habilitar calendarios de tipos de horas",
    "calendario_defecto": "Calendario por defecto",
    "referencia_obra": "Permitir que las obras informen del calendario a usar",
    "colores": "Colores",
    "primary": "Color primario",
    "secondary": "Color secundario",
    "font": "Fuente",
    "ftp_method": "Método FTP",
    "gpsIsRequired": "La posición GPS es obligatoria para fichar",
    "hoursForAutomaticExit": "Horas a las que ejecutar una salida automática (desactivar dejando el valor en 0)",
    "hideRegister": "Ocultar registro a:",
    "hideMonthlySummary": "Ocultar resumen mensual a:",
    "statusByDefault": "Estado por defecto al crear un nuevo aviso",
    "priorityByDefault": "Prioridad por defecto al crear un nuevo aviso",
    "avisosConfirmar": "Es necesario confirmar los borradores de avisos que se crean",
    "manoObraSeleccionarEmpleado": "Permitir seleccionar el empleado en la línea mano de obra",
    "referenceToSave": "Referencia a guardar en el pedido",
    "allowGetBarcodesTable": "Permitir llamar a la tabla de códigos de barras",
    "generalApp": "General",
    "activeApp": "Activar acceso a la aplicación",
    "getDelegacionDeEmpleado": "Portal por delegación de empleado",
    "allowAcceptOfferWhenCreate": "Permitir aceptar una oferta al momento de crearla",
    getTarifaDeEmpleado: "Filtrar tarifas por la tarifa del empleado informada",
    sendEmailOnValidateReportSuccess: "Permitir enviar correo al validar un nuevo parte",
}

const _type_boolean = [{ label: "Si", value: true }, { label: "No", value: false }];
const _type_ftp_method = [{ label: "Fichero", value: "fichero" }, { label: "Borrador (endpoint GoManage)", value: "endpoint" }];

const types = [
    {
        property: "partes",
        type: "select",
        options: _type_boolean
    }, {
        property: "fichajes",
        type: "select",
        options: _type_boolean
    }, {
        property: "pedidos",
        type: "select",
        options: _type_boolean
    }, {
        property: "avisos",
        type: "select",
        options: _type_boolean
    },
    {
        property: "listadoParteShowClosed",
        type: "select",
        options: _type_boolean
    },
    {
        property: "listadoParteShowResume",
        type: "select",
        options: [
            { label: 'Todos', value: 'all' },
            { label: 'Solo jefes de obra / supervalidadores', value: 'only_admins' },
            { label: 'Ninguno', value: 'nobody' },
        ]
    },
    {
        property: "partesConfirmar",
        type: "select",
        options: _type_boolean
    },
    {
        property: "partesValidar",
        type: "select",
        options: _type_boolean
    },
    {
        property: "partesFirmar",
        type: "select",
        options: _type_boolean
    },
    {
        property: "partesObservaciones",
        type: "select",
        options: _type_boolean
    },
    {
        property: "parteShowRefParte",
        type: "select",
        options: _type_boolean
    },
    {
        property: "editTipoparte",
        type: "select",
        options: _type_boolean
    },
    {
        property: "allowFutureDate",
        type: 'select',
        multiple: false,
        options: [
            { label: "No", value: "no" },
            { label: "Sí", value: "yes" },
            { label: "Tipo de hora", value: "date" }
        ]
    },
    {
        property: "showTipoParte",
        type: "select",
        options: _type_boolean
    },
    {
        property: "filtroObraShowEmpresa",
        type: "select",
        options: _type_boolean
    },
    {
        property: "filtroObraShowReferencia",
        type: "select",
        options: _type_boolean
    },
    {
        property: "filtroObraShowResponsable",
        type: "select",
        options: _type_boolean
    },
    {
        property: "filtroObraShowCliente",
        type: "select",
        options: _type_boolean
    },
    {
        property: "strictFindObra",
        type: 'select',
        multiple: false,
        options: [
            {label: 'Si, todas las empresas', value: 'all'},
            {label: 'No', value: 'no'},
            {label: 'Sólo empresas externas', value: 'only_external'}
        ]
    },
    {
        property: "lineaGastoFacturableByDefault",
        type: "select",
        options: _type_boolean
    },
    {
        property: "opcionFacturableActive",
        type: "select",
        options: _type_boolean
    },
    {
        property: "allowObraWithoutResponsable",
        type: "select",
        options: _type_boolean
    },
    {
        property: "manoObraCategoria",
        type: "select",
        options: _type_boolean
    }, {
        property: "showManoObraCategoria",
        type: "select",
        options: _type_boolean
    }, {
        property: "showManoObraTipoHora",
        type: "select",
        options: _type_boolean
    },
    {
        property: "manoObraTipoHora",
        type: "select",
        options: _type_boolean
    },
    {
        property: "descManoObra",
        type: "select",
        options: _type_boolean
    },
    {
        property: "manoObraSeleccionarEmpleado",
        type: "select",
        options: _type_boolean
    },
    {
    property: "allocationsOfPartsInChapters",
        type: "select",
        options: _type_boolean
    },
    {
        property: "reportCerrarDias",
        type: "select",
        options: _type_boolean
    },
    {
        property: "filterAllHours",
        type: "select",
        options: _type_boolean
    },
    {
        property: "resumenHorasExtra",
        type: "select",
        options: _type_boolean
    },
    {
        property: "calendario",
        type: "select",
        options: _type_boolean
    }, {
        property: "referencia_obra",
        type: "select",
        options: _type_boolean
    },
    {
        property: "gpsIsRequired",
        type: "select",
        options: _type_boolean
    },


    {
        property: "token",
        type: "password",
    },

    {
        property: "password",
        type: "password",
    },
    {
        property: "ftp_method",
        type: "select",
        options: _type_ftp_method
    },

    {
        property: "manoObraCategoriaDefault",
        type: "select",
        options: []
    },
    {
        property: "manoObraTipoHoraDefault",
        type: "select",
        options: []
    },
    {
        property: "manoObra",
        type: "select",
        options: [{ label: 'hora', value: 'hora' }, { label: 'cantidad', value: 'cantidad' }]
    },
    {
        property: "manoObraDiferenteDia",
        type: "select",
        options: _type_boolean
    },

    {
        property: "reportMeses",
        type: "select",
        options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '9', value: '9' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' },
        ]
    },

    // Tipos de lineas de parte admitidas => lineaParte (array ["all"] | ["manoDeObra", "gasto", "servicio", "articulo", "texto"])
    {
        property: "lineaParte",
        type: "select",
        multiple: true,
        options: [
            { label: "All - (Mano de Obra, Gasto, Servicio, Artìculo, Texto)", value: "All" },
            { label: "Mano de Obra", value: "manoDeObra" },
            { label: "Gasto", value: "gasto" },
            { label: "Servicio", value: "servicio" },
            { label: "Artículo", value: "articulo" },
            { label: "Texto", value: "texto" }
        ]
    },
    {
        property: "lineaParteToCopy",
        type: "select",
        multiple: true,
        options: [
            { label: "All - (Mano de Obra, Gasto, Servicio, Artìculo, Texto)", value: "All" },
            { label: "Mano de Obra", value: "manoDeObra" },
            { label: "Gasto", value: "gasto" },
            { label: "Servicio", value: "servicio" },
            { label: "Artículo", value: "articulo" },
            { label: "Texto", value: "texto" }
        ]
    },
    {
        property: "lineaPedido",
        type: "select",
        multiple: true,
        options: [
            { label: "Servicio", value: "servicio" },
            { label: "Artículo", value: "articulo" },
            { label: "Texto", value: "texto" }
        ]
    },
    {
        property: "sendEmailOnValidateReportSuccess",
        type: "select",
        options: _type_boolean
    },
    {
        property: "getTarifaDeEmpleado",
        type: "select",
        options: _type_boolean
    },
    {
        property: "autoRefParte",
        type: "select",
        multiple: false,
        options: [
            { label: "Ninguna", value: "" },
            { label: "Código empleado", value: "codEmpleado" },
            { label: "Código referencia obra", value: "codReferencia" },
        ]
    },
    {
        property: "hideRegister",
        type: 'select',
        multiple: false,
        options: [
            {label: 'No ocultar', value: 'no'},
            {label: 'Ocultar a empleados', value: 'employees'},
            {label: 'Ocultar a jefes de obra', value: 'managers'},
            {label: 'Ocultar a empleados y jefes de obra', value: 'employees_and_managers'},
            {label: 'Ocultar a todos (excepto superadmin)', value: 'everyone'},
        ]
    },

    {
        property: "hideMonthlySummary",
        type: 'select',
        multiple: false,
        options: [
            {label: 'No ocultar', value: 'no'},
            {label: 'Ocultar a empleados', value: 'employees'},
            {label: 'Ocultar a jefes de obra', value: 'managers'},
            {label: 'Ocultar a empleados y jefes de obra', value: 'employees_and_managers'},
            {label: 'Ocultar a todos (excepto superadmin)', value: 'everyone'},
        ]
    },
    {
        property: "statusByDefault",
        type: "select",
        options: []
    },
    {
        property: "priorityByDefault",
        type: "select",
        options: []
    },
    {
        property: "avisosConfirmar",
        type: "select",
        options: _type_boolean
    },
    {
        property: "referenceToSave",
        type: "select",
        options: [
            { label: 'Referencia de obra', value: 'obraReference' },
            { label: 'Código de obra', value: 'obraCode' },
            { label: 'Vacío', value: 'custom' },
        ]
    },
    {
        property: "allowGetBarcodesTable",
        type: "select",
        options: _type_boolean
    },
    {
        property: "activeApp",
        type: "select",
        options: _type_boolean,
        disableBlank: true
    },
    {
        property: "getDelegacionDeEmpleado",
        type: "select",
        options: _type_boolean
    },
    {
        property: "allowAcceptOfferWhenCreate",
        type: "select",
        options: _type_boolean,
        disableBlank: true
    },
]

export {
    textos,
    types
}
