import "./Login.scss";
import logoImage from "@assets/images/logo.png";
import { Link } from "@mui/material";
import { useEffect, useState } from "react";
import EsfirusButton from "@ui/Button/EsfirusButton";
import ModalForgottenPassword from "@components/ModalForgottenPassword/ModalForgottenPassword";
import EsfirusTextInput from "@ui/Text-input/EsfirusTextInput";
import { useNavigate } from "react-router-dom";
import { authService } from "@services/auth";
import { resetUser } from "@store/slices/user";
import { resetArticulos } from "@store/slices/articulos-gastos-servicios";
import { resetCapitulos } from "@store/slices/capitulos";
import { resetCategorias } from "@store/slices/categorias";
// import { resetConfigs } from "@store/slices/configs";
import { resetEmpresas } from "@store/slices/empresas";
import { resetLines } from "@store/slices/lines";
import { resetObras } from "@store/slices/obras";
import { resetPartes } from "@store/slices/partes";
import { resetPresupuestos } from "@store/slices/presupuestos";
import { resetReports } from "@store/slices/reports";
import { resetSelectedObra } from "@store/slices/selectedObra";
import { resetTipoHora } from "@store/slices/tipoHora";
import { resetManoObra } from "@store/slices/manoObra";
import { resetCalendarios } from "@store/slices/calendarios";
import { resetNotices } from "@store/slices/notices";
import { resetUsers } from "@store/slices/users";

import { useDispatch } from "react-redux";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [openPassword, setOpenPassword] = useState(false);
  const [message, setMessage] = useState(null);

  const login = async () => {
    const res: any = await authService.post(user, password);
    if (res && typeof res?.msg == "undefined") {
      navigate("/splash");
    } else {
      setMessage(res?.msg)
    }
  };

  useEffect(() => {
    dispatch(resetUser({} as any));
    dispatch(resetArticulos({} as any));
    dispatch(resetCapitulos({} as any));
    dispatch(resetCategorias({} as any));
    // dispatch(resetConfigs({} as any));
    dispatch(resetEmpresas({} as any));
    dispatch(resetEmpresas({} as any));
    dispatch(resetLines({} as any));
    dispatch(resetObras({} as any));
    dispatch(resetPartes({} as any));
    dispatch(resetPresupuestos({} as any));
    dispatch(resetReports({} as any));
    dispatch(resetSelectedObra({} as any));
    dispatch(resetTipoHora({} as any));
    dispatch(resetCalendarios({} as any));
    dispatch(resetManoObra({} as any));
    dispatch(resetNotices());
    dispatch(resetUsers());

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const toConfig = urlParams.get("config");
    if (toConfig) localStorage.setItem("ef-navigate", "/backoffice/configs/conexion")

  }, []);


  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      login()
    }
  }

  return (
    <div className="login-container login-background">
      <img src={logoImage} className="App-logo" alt="logo" />
      <h4 className="text-center ph-30">Bienvenido a tu espacio</h4>

      <EsfirusTextInput
        onChange={(e: any) => setUser(e.target.value)}
        className="mb-40"
        placeholder="Usuario / email"
        onKeyDown={keyPress}
      />
      <EsfirusTextInput
        onChange={(e: any) => setPassword(e.target.value)}
        className="mb-40"
        placeholder="Contraseña"
        type="password"
        onKeyDown={keyPress}
      />

      <EsfirusButton width={220} fontSize={25} onClick={login} size="small">
        Enviar
      </EsfirusButton>
      {message && <p className="text-center mt-40 error">{message}</p>}
      <Link
        onClick={() => setOpenPassword(true)}
        className="mt-40 pointer"
        sx={{ color: "white" }}
        underline="none"
      >
        Recuperar contraseña
      </Link>

      <ModalForgottenPassword open={openPassword} setOpen={setOpenPassword} />
    </div>
  );
}

export default Login;
