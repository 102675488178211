import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableFilters, TableFiltersState } from './types/tableFilters.type';

const initialState: TableFiltersState = {};

const tableFiltersSlice = createSlice({
  name: 'tableFilters',
  initialState,
  reducers: {
    setTableFilters: (
      state,
      action: PayloadAction<{ tableId: string; filters: TableFilters }>
    ) => {
      state[action.payload.tableId] = action.payload.filters;
    },
    clearTableFilters: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    }
  },
});

export const { setTableFilters, clearTableFilters } =
  tableFiltersSlice.actions;
export default tableFiltersSlice.reducer;
