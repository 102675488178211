import { createSlice } from "@reduxjs/toolkit";
import { Empleado } from "@models/empleado";

interface EmpleadosSliceType {
  list: Empleado[]
}

const initial: EmpleadosSliceType = {
  list: [],
};

export const empleadosSlice = createSlice({
  name: "empleados",
  initialState: { ...initial },
  reducers: {
    resetEmpleados: (state) => {
      state.list = [...initial.list];
    },
    setempleados: (state, action) => {
      state.list = [...action.payload];
    },
  },
});

export default empleadosSlice.reducer;
export const { setempleados, resetEmpleados } = empleadosSlice.actions;
