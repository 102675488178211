import { ChangeEvent, ChangeEventHandler, MouseEventHandler, useEffect, useMemo, useState } from "react";

const range = (start: number, end: number) => {
  let length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

export interface EsfirusTablePropsPagination {
  page: number
  sizePage: number
}

interface IntervalItemsProps {
  initial: number
  final: number
}

export type HandleChangePaginationType = (data: EsfirusTablePropsPagination) => void

interface UseHandlerPaginationTableProps {
  customPagination?: EsfirusTablePropsPagination
  totalItems: number
  handleChangePagination?: HandleChangePaginationType
}

interface UseHandlerPaginationTableResult {
  isFirstPage: boolean
  isLastPage: boolean
  intervalItems: IntervalItemsProps
  sizePage: number
  goNextPage: MouseEventHandler<HTMLButtonElement>;
  goBeforePage: MouseEventHandler<HTMLButtonElement>;
  handleChangePageSize: ChangeEventHandler<HTMLSelectElement>;
  goToSpecifictPage: (page :number)=> void;
  currentPage: number;
  totalPages: number;
  paginationRange: Array<number | string>;
  resetPagination: VoidFunction;
}

const siblingCount = 1;
const DOTS = '...';

const useHandlerPaginationTable = ({ customPagination, totalItems, handleChangePagination }: UseHandlerPaginationTableProps): UseHandlerPaginationTableResult => {
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>(customPagination ?? { page: 0, sizePage: 50 })
  const [intervalItems, setIntervalItems] = useState<IntervalItemsProps>({ initial: 1, final: 50 })
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const newTotalPages = Math.ceil(totalItems / pagination.sizePage)

    const newInitial = pagination.page * pagination.sizePage + 1
    const theoricFinal = (pagination.page + 1) * pagination.sizePage
    const newFinal = theoricFinal > totalItems ? totalItems : theoricFinal

    setCurrentPage(pagination.page + 1);
    setIntervalItems({ initial: newInitial, final: newFinal })
    setTotalPages(newTotalPages)
  }, [pagination, totalItems])

  const goNextPage = () => {
    const newPaginationData = { ...pagination, page: pagination.page + 1 }
    setCurrentPage(pagination.page + 1);
    setPagination(newPaginationData)

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  const goBeforePage = () => {
    const newPaginationData = { ...pagination, page: pagination.page - 1 }
    setCurrentPage(pagination.page - 1);
    setPagination(newPaginationData)

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  const resetPagination = () => {
    const newPaginationData = { ...pagination, page: 0, }
    setPagination(newPaginationData);

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  const goToSpecifictPage = (page: number) => {
    const newPaginationData = { ...pagination, page: page - 1 }
    setCurrentPage(page - 1);
    setPagination(newPaginationData)

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  const handleChangePageSize = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    const newPaginationData = { ...pagination, page: 0, sizePage: Number(value) }
    setPagination(newPaginationData)

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;
    const firstPageIndex = 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {

      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPages - rightItemCount + 1,
        totalPages
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
       return [firstPageIndex, DOTS, ...middleRange, DOTS, totalPages];
    }
}, [totalPages, currentPage]);

  return {
    isFirstPage: pagination.page === 0,
    isLastPage: pagination.page === (totalPages - 1),
    intervalItems: intervalItems,
    sizePage: pagination.sizePage,
    goNextPage,
    goBeforePage,
    totalPages,
    currentPage,
    handleChangePageSize,
    goToSpecifictPage,
    resetPagination,
    paginationRange: paginationRange || []
  }
}

export default useHandlerPaginationTable