import { useEffect, useState } from "react";
import Header from "@components/Header/Header";
import EsfirusResume from "@components/ui/Resume/EsfirusResume";
import FicharHeader from "@components/HeaderFichaje/header";

import SubHeader from "./BBs/subheader/subheader";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import backIcon from "@assets/images/icons/back-arrow.svg";
import pendingIcon from "@assets/svgs/pending-icon.svg";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { filterFichajes, filterPartes } from "@helpers/parsers";
import { getTimeTrackList } from "@store/slices/timeTracking";
import EsfirusFichajeTable from "./BBs/table/Table";
import { isEmpleado } from "@services/hooks/UserHook";
import { emptyResourceRedirect } from "@helpers/navigate";

function TrackHorasList() {
  const fichajes = useSelector((state) => (state as any).timeTracking).list;
  const empresas = useSelector((state) => (state as any).empresas).list;

  const fichajesParsed = fichajes?.map((f: any) => {
    return {
      ...f,
      nombre_empleado: f.nombre_empleado + " " + f.apellidos_empleado,
      empresa_nombre: empresas.find((e: any) => e.Empresa == f.empresa)?.Nombre,
      date: new Date(f.fecha).getTime(),
    };
  });

  const dispatch = useDispatch();


  const [fichajesList, setFichajesList] = useState([]);
  const [seletedAll, setSeletedAll] = useState(false);
  const [search, setFilterBySearch] = useState<any>("");

  const selectedDateFrom = useSelector((state) => (state as any).partes.selectedDateFrom);
  const selectedEmpresa = useSelector((state) => (state as any).partes.selectedEmpresa);
  const selectedDateTo = useSelector((state) => (state as any).partes.selectedDateTo);
  const configuration = useSelector((state) => (state as any).configurations.config);
  const selectedEmpleado = useSelector((state) => (state as any).partes.selectedEmpleado);


  const navigate = useNavigate();
  const rol = localStorage.getItem("ef-user-rol")

  const [empleadosList, setEmpleadosList] = useState<any>([]);

  useEffect(() => {
    dispatch(getTimeTrackList() as any);

    emptyResourceRedirect({ check: empresas.length === 0, path: "/time-report", navigate });
  }, [])

  useEffect(() => {
    if (!fichajesList?.length || !fichajesParsed?.length) return;
  
    const uniqueCompanies = new Set(fichajesList.map((f: any) => f.empresa));
    const empleadosSet = new Set<string>();
  
    const listaEmpleados = fichajesParsed
      .filter((p: any) => uniqueCompanies.has(p.empresa))
      .map((p: any) => {
        const empleadoStr = `${p.nombre_empleado}#${p.user}`;
        if (!empleadosSet.has(empleadoStr)) {
          empleadosSet.add(empleadoStr);
          return { label: p.nombre_empleado, value: p.user };
        }
        return null;
      })
      .filter(Boolean)
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  
    setEmpleadosList(listaEmpleados);
  }, [fichajes, fichajesList, fichajesParsed]);
  

  useEffect(() => {


    setFichajesList(
      filterFichajes(
        fichajesOrdered(),
        fichajesParsed,
        selectedDateFrom,
        selectedDateTo,
        selectedEmpresa,
        search,
        selectedEmpleado,
        { ...configuration, listado: {} },
      )
    );
  }, [fichajes, selectedDateFrom, selectedDateTo, search, selectedEmpresa, seletedAll, selectedEmpleado]);

  // const deleteParte = (id: any) => {
  //   dispatch(deleteReport(id) as any)
  //     .then(
  //       () => dispatch(getTimeTrackList() as any)
  //     );
  // };
  const fichajesOrdered = () => {
    return fichajesParsed?.slice().sort((a: any, b: any) => b.fecha - a.fecha);
  };

  return (
    <>
      <Header />
      <FicharHeader />

      <div className="center">


        <div className="title noPrint">

          <h4 >Registro horarios</h4>
        </div>
        <SubHeader
          search={search}
          setFilterBySearch={setFilterBySearch}
          partes={fichajesList}
          selectedEmpresa={selectedEmpresa}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          selectedEmpleado={selectedEmpleado}
          empleadosList={empleadosList}
        ></SubHeader>
        <EsfirusFichajeTable
          seletedAll={seletedAll}
          partes={fichajesList as any}
          empresas={empresas as any}
          rol={rol as any}
          download={{ pdf: true, excel: true }}


        ></EsfirusFichajeTable>
      </div >
    </>
  );
}

export default TrackHorasList;
