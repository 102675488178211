import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment, { Moment } from "moment"
import { Box, FormControl } from "@mui/material"

import HeaderFormPedido from "./BBs/HeaderFormPedido/HeaderFormPedido"
import LinesContainer from "./BBs/LinesContainer/LinesContainer"
import AdjuntosContainer from "./BBs/AdjuntosContainer/AdjuntosContainer"
import EsfirusButton from "@components/ui/Button/EsfirusButton"

import { Obra } from "@models/obra"
import { Proveedor } from "@screens/NewParte/BBs/ModalNewProveedor/ModalNewProveedor"
import { reportLinesService } from "@services/reportLines"
import { removeSelectedObra, updateSelectedObra } from "@store/slices/selectedObra"
import { addLine, resetLines, updateLine } from "@store/slices/lines"
import { configurationService } from "@services/configApp"

import "./FormParte.scss"

export interface FormDataPedido {
  company: number | string
  obra: Obra | null
  proveedor: Proveedor | null
  employee: string | null
  date: Moment | number
  reference: string
  lineas: any[]
  files: FilePedido[]
  purchase_order_id?: number | string
  unique_id?: number | string
  employeeId?: string
}

export interface FilePedido {
  name: string
  base64?: string
  document_id?: string | number
  unique_id?: string | number
}

const FormPedido = (props: any) => {
  const dispatch = useDispatch()
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra
  const lineas = useSelector((state) => (state as any).lines).list
  const { referenceToSave } = configurationService.getConfigLineasPedido();

  // Recopilación de toda la data del formulario
  const [formData, setFormData] = useState<FormDataPedido>(props.initialData ?? {
    company: '',
    obra: null,
    proveedor: null,
    employee: localStorage?.getItem('ef-user-name'),
    date: moment(),
    reference: '',
    lineas: [],
    employeeId: '',
    files: []
  })

  // Validación para renderizar apartado de líneas o no
  const headerIsOkey = formData.company !== "" &&
  formData.obra &&
  formData.proveedor &&
  // formData.employee && => A petición de borrar https://esfirus.atlassian.net/browse/PARTRAB-752
  // formData.employee !== "" &&
  formData.date && 
  (typeof formData.date === "number" ? moment(formData.date).isValid() : formData.date.isValid())

  // Validación de fecha
  const dateIsInTheFuture = formData.date && moment(formData.date).isAfter(moment())

  // Deshabilitación del botón de guardado
  const saveDisabled =
    !headerIsOkey ||
    !formData.lineas.length ||
    reportLinesService.getValidatorBtn(formData.obra, formData.lineas, false) ||
    dateIsInTheFuture ||
    (referenceToSave === 'custom' && !formData.reference)

  // Actualización de los datos del contexto
  useEffect(() => {
    const getReferenceData = () => {
      return {
        obraReference: selectedobra.Referencia,
        obraCode: selectedobra.Obra,
        custom: ''
      }[referenceToSave] ?? selectedobra.reference
    }

    setFormData({
      ...formData,
      reference: formData.reference || getReferenceData(),
      company: selectedobra.company ?? selectedobra.Empresa ?? "",
      obra: selectedobra,
      lineas,
      employeeId: selectedobra.reference,
    })
  }, [selectedobra, lineas]);

  // Relleno si viene de view o edit
  useEffect(() => {
    if (props.initialData) {
      props.initialData.lineas.forEach((linea: any) => {
        const index = Math.floor(Math.random() * 1000000000)
        dispatch(addLine({ index, type: linea.type }))
        dispatch(updateLine({ index, ...linea }))
      })

      if (props.initialData.obra) dispatch(updateSelectedObra(props.initialData.obra))
    }
  }, [props.initialData])

  // Resetear ambos contextos al salir de la pantalla de pedido
  useEffect(() => {
    return () => {
      dispatch(resetLines())
      dispatch(removeSelectedObra())
    }
  }, [])

  return (
    <FormControl className="parte-form" fullWidth>
      <HeaderFormPedido
        formData={formData}
        setFormData={setFormData}
        dateIsInTheFuture={dateIsInTheFuture}
        editable={props.editable && !props.onlyEditAdjuntos}
        isView={props.isView}
      />
      {(headerIsOkey || !props.editable) && (
        <>
          <LinesContainer
            lineas={formData.lineas}
            editable={props.editable && !props.onlyEditAdjuntos}
            isPedido={true}
          />
          <AdjuntosContainer
            formData={formData}
            setFormData={setFormData}
            editable={props.editable}
            isView={props.isView}
          />
        </>
      )}
      <Box className="ml-20 mr-20 mt-40 botonera">
        {props?.handleSave && (
          <EsfirusButton
            className="new-parte-save-button"
            color="primary"
            onClick={async () => await props.handleSave(formData)}
            disabled={saveDisabled || props.isSaving}
          >
            {props.isSaving && <span style={{ height: 12, margin: 0 }} className="loader"></span>}
            Guardar
          </EsfirusButton>
        )}
        {props?.handleClose && (
          <EsfirusButton
            className="new-parte-save-button"
            color="primary"
            onClick={props.handleClose}
            disabled={props.isSaving}
          >
            Cerrar
          </EsfirusButton>
        )}
      </Box>
    </FormControl>
  )
}

export default FormPedido
