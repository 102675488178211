export const APP_USERS_ROLES = {
    admin: "superadmin",
    agente: "agent",
    cliente: "customer",
}

const textos = {
    "email": "Email",
    "nombre": "Nombre",
    "apellidos": "Apellidos",
    "rol": "Rol",
    "activo": "Activo",
    "external_empresa": "Empresa Externa",
    "external_empleado": "Empleado Externo",
    "internal_empresa": "Empresa Interna",
    "internal_empleado": "Empleado Interno",
    "empresa": "Empresa",
    "empleado": "Empleado",
    "password": "Contraseña",
    "data": "Datos de TMT",
}

const _type_boolean = [{ label: "Si", value: 1 }, { label: "No", value: 0 }];

const types = [
    {
        property: "activo",
        type: "select",
        options: _type_boolean
    },
    {
        property: "rol",
        type: "select",
        options: [
            { label: "Administrador", value: APP_USERS_ROLES.admin },
            { label: "Agente", value: APP_USERS_ROLES.agente },
            { label: "Cliente", value: APP_USERS_ROLES.cliente },
        ]
    },
    {
        property: "data",
        type: "textarea",
    }
]

export {
    textos,
    types
}
