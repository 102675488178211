import { createContext, useState } from 'react'
const AppPerfilContext = createContext()

const AppPerfilProvider = ({ children }) => {
    const [state, setState] = useState({
        email: '',
        nombre: '',
        apellidos: '',
        rol: '',
        activo: 1,
        password: '',
        data: {}
    })

    return (
        <AppPerfilContext.Provider value={{ state, setState }}>
            {children}
        </AppPerfilContext.Provider>
    )
}

export { AppPerfilContext, AppPerfilProvider }
