import Grid from "@mui/material/Grid";
import articuloIcon from "@assets/svgs/lineasArticulo.svg";
import EsfirusTextInput from "@ui/Text-input/EsfirusTextInput";
import "./LineasArticulo.scss";
import EsfirusSelect from "@ui/Select/EsfirusSelect";
import { useDispatch, useSelector } from "react-redux";
import { updateLine } from "@store/slices/lines";
import { Capitulo } from "@models/obra";
import { useEffect, useState } from "react";
import { InputAdornment } from "@mui/material";
import EsfirusIcon from "@components/ui/icons/EsfirusIcon";
import zoomIcon from "@assets/images/icons/zoomIcon.png";
import ModalNewArticulo from "@screens/NewParte/BBs/ModalNewArticulo/ModalNewArticulo";
import { erpService } from "@services/erp";
import useDebounce from "@services/hooks/useDebounce";
import { configurationService } from "@services/configApp";

export interface Articulo {
  Articulo: string;
  Ambito: number;
  Descripcion: string;
  Auxiliar: string;
  Marca: string;
  Gasto: boolean;
  Coste: number;
  Publicable: boolean;
  Referencia: string;
  Servicio: boolean;
  Tarifa: number;
  data_sales_pricing: any;
  barcodes?: any[];
}

function LineasArticulo(props: any) {
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);
  const [selectedArticulo, setSelectedArticulo] = useState({
    Articulo: "",
    Descripcion: "",
    Auxiliar: "",
    Marca: "",
    Gasto: false,
    Coste: 0,
    Publicable: false,
    Referencia: "",
    Servicio: false,
    Tarifa: 0,
  });
  const [openArticuloModal, setOpenArticuloModal] = useState(false);

  const config = configurationService.getConfig();
  const lineas = useSelector((state) => (state as any).lines);
  const gastosServicios = useSelector((state) => (state as any).articulosGastosServicios).list;
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;
  const cantidadDebounced = useDebounce(lineas?.list[props.index]?.amount, 500)

  useEffect(() => {
    const updateSalesPricing = async () => {
      const articuloRef = lineas.list[props.index]?.article
      const selectedArticulo = gastosServicios.find(
        (el: Articulo) => el.Articulo === articuloRef
      )
      const salesPricingData = await fetchSalesPricingData(selectedArticulo, Number(cantidadDebounced) ?? 1)

      dispatch(
        updateLine({
          ...lineas.list[props.index],
          data_sales_pricing: salesPricingData?.data_sales_pricing ?? {}
        })
      )
    }

    const goToFetchSalesPricing = selectedobra.Administracion && lineas?.list?.[props.index]?.article && config?.ftp_method === "endpoint"
    if (goToFetchSalesPricing) {
      updateSalesPricing()
    }
  }, [cantidadDebounced, lineas.list[props.index]?.article])

  const getChapterOptions = (): any[] => {
    if (!selectedobra.capitulosByPresupuesto) {
      return [];
    }

    return selectedobra.capitulosByPresupuesto?.map((cap: Capitulo) => ({
      ...cap,
      label: cap.label,
      value: cap.Capitulo,
    }));
  };

  const selectArticulo = async (articulo: Articulo) => {
    // Nunca se muestra según PARTRAB-305.
    // if ((selectedobra.Administracion && !articulo.Coste_admin) || (!selectedobra.Administracion && !articulo.Coste)) {
    //   setHide(true)
    // } else {
    //   setHide(false)
    // }

    setSelectedArticulo(articulo)

    const precio = articulo.Coste

    dispatch(
      updateLine({
        ...lineas.list[props.index],
        article: articulo.Articulo,
        articleExterno: articulo.Articulo,
        description: articulo.Descripcion,
        amount: 1,
        tarifa: articulo.Tarifa,
        totalPrice: precio ?? 0,
        precio: precio ?? 0,
      })
    );
  }

  const fetchSalesPricingData = async (servicio: any, quantity: any) => {
    if (!servicio || !quantity) return {}

    const salesResponse = await erpService.getSalesPricing(servicio, selectedobra, quantity, lineas.list[props.index])
    const sales = salesResponse?.data?.data ?? {}
    return sales
  }

  return (
    <>
      <Grid
        className="line-services-container"
        container
        spacing={1}
        alignItems={"center"}
      >
        <Grid
          className="flex-row"
          item
          xs={12}
          md={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img className="logo mr-10" src={articuloIcon} alt="article" />
          <h4>AR</h4>
        </Grid>
        <Grid item xs={12} md={10} container spacing={2}>
          {!selectedobra.Administracion && (selectedobra.SolCapitulo &&
            selectedobra.capitulosByPresupuesto.length !== 0) && (
              <Grid item xs={12} md={4}>
                <label>Capítulo</label>
                <EsfirusSelect
                  disabled={!props.editable}
                  optionsStyle={{ textAlign: "left", fontWeight: "bold" }}
                  options={getChapterOptions()}
                  value={lineas?.list[props.index]?.chapter?.Capitulo}
                  change={(e: any) => {
                    const selectedChapter =
                      selectedobra.capitulosByPresupuesto.find(
                        (el: Capitulo) => el.Capitulo === e
                      );

                    dispatch(
                      updateLine({
                        ...lineas.list[props.index],
                        chapter: selectedChapter,
                      })
                    );
                  }}
                  placeholder={"Capítulo"}
                  modified={!!lineas.list[props.index]?.validationModifications?.datos?.chapter}
                ></EsfirusSelect>
              </Grid>
            )}

          <Grid item xs={12} md={4}>
            <label>Artículo</label>
            <EsfirusTextInput
              fullWidth
              aria-readonly
              value={lineas?.list[props.index]?.article ?? ""}
              size="small"
              inputProps={{ readOnly: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    className="pointer"
                    onClick={() => {
                      if (props.editable) setOpenArticuloModal(true);
                    }}
                  >
                    <EsfirusIcon path={zoomIcon} />
                  </InputAdornment>
                ),
              }}
              disabled={!props.editable}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <label>Descripción</label>
            <EsfirusTextInput
              value={lineas?.list[props.index]?.description}
              fullWidth
              disabled={!props.editable}
              onChange={(e: any) => {
                dispatch(
                  updateLine({
                    ...lineas.list[props.index],
                    description: e.target.value,
                  })
                );
              }}
              modified={!!lineas.list[props.index]?.validationModifications?.datos?.description}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <label>Cantidad</label>
            <EsfirusTextInput
              type="number"
              value={lineas?.list[props.index]?.amount}
              fullWidth
              disabled={!props.editable}
              InputProps={{ inputProps: { min: 1 } }}
              onChange={(e: any) => {
                const newCantidad = e.target.value

                const totalPrice = Number(lineas.list[props.index].precio) * Number(newCantidad);

                dispatch(
                  updateLine({
                    ...lineas.list[props.index],
                    amount: newCantidad,
                    totalPrice,
                  })
                );
              }}
              modified={!!lineas.list[props.index]?.validationModifications?.datos?.amount}
            />
          </Grid>

          {/* Ocultamos siempre precio por requerimiento de Jesús en PARTRAB-274 */}
          {/* {!hide && (
            <Grid item xs={6} md={2}>
              <label>Precio</label>
              <EsfirusTextInput
                type="number"
                fullWidth
                disabled={!props.editable}
                value={lineas?.list[props.index]?.precio}
                onChange={(e: any) => {
                  const totalPrice = Number(e.target.value ?? 0) * Number(lineas.list[props.index].amount ?? 0)
                  dispatch(
                    updateLine({
                      ...lineas.list[props.index],
                      precio: e.target.value,
                      totalPrice,
                    })
                  );
                }}
                modified={!!lineas.list[props.index]?.validationModifications?.datos?.precio}
              />
            </Grid>
          )} */}
        </Grid>
      </Grid>

      {
        openArticuloModal && (
          <ModalNewArticulo
            open={openArticuloModal}
            setOpen={setOpenArticuloModal}
            selectArticulo={selectArticulo}
          />
        )
      }
    </>
  );
}

export { LineasArticulo };
