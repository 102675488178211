import { FormControl } from "@mui/material";
import { HeaderFormParte } from "./BBs/HeaderFormParte/HeaderFormParte";
import LinesContainer from "./BBs/LinesContainer/LinesContainer";
import "./FormParte.scss";

export default function FormParte(props: any) {
  return (
    <FormControl className="parte-form" fullWidth>
      <HeaderFormParte
        allowFutureDate={props?.allowFutureDate}
        selectedobra={props?.selectedobra}
        presupuesto={props?.presupuesto}
        capitulos={props?.capitulos}
        lineas={props?.lineas}
        editable={props.editable}
        dateInvalid={props.dateInvalid}
        setDateInvalid={props.setDateInvalid}
        closedDays={props.closedDays}
        isCreation={props.isCreation}
        avisoData={props.avisoData}
      ></HeaderFormParte>
      {!!props?.headerOk && (
        <LinesContainer
          lineas={props?.lineas}
          editable={props.editable}
          data={props?.data}
          setSolapamiento={props?.setSolapamiento}
          solapamiento={props?.solapamiento}
        ></LinesContainer>
      )}
    </FormControl>
  );
}
