import { configFrontProps } from "@models/config";
import { createSlice } from "@reduxjs/toolkit";

interface ConfigurationsSliceType {
  config: configFrontProps | {};
}

const initial: ConfigurationsSliceType = {
  config: {}
};

export const configSlice = createSlice({
  name: "configurations",
  initialState: { ...initial },
  reducers: {
    resetConfig: (state) => {
      state.config = initial.config;
    },
    setConfigurations: (state, action) => {
      const hideConfirmar = action.payload?.report?.reportCerrarDias || !action.payload?.cabeceraParte?.partesConfirmar;
      state.config = { ...action.payload, hideConfirmar };
    },
  },
});

export default configSlice.reducer;
export const { setConfigurations } = configSlice.actions;
