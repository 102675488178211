import { Articulo } from "@components/FormParte/BBs/LineasArticulo/LineasArticulo";
import { postRequest } from "@screens/Backoffice/utils/requests";

type UserData = {
  id: string;
  empleado: number;
  empresa: number;
}

export const checkResponsableObraIsIntern = async (userData: Array<UserData>) => {
  try {
    const { data } = await postRequest(`users/find`, { data: JSON.stringify(userData) });

    return Object.keys(data).reduce((acc: Record<string, boolean>, key: string) => {
      const userExtraData = JSON.parse(data[key]?.data ?? '{}');
      const obra = userData.find(({ id }: UserData) => id === key);
      
      const isIntern = userExtraData.internal?.some(
        (internal: UserData) => internal.empresa === obra?.empresa && internal.empleado === obra?.empleado
      );

      acc[key] = isIntern;
      
      return acc;
    }, {});
  } catch (error) {
    return {};
  }
};

export const matchArticlesWithBarcodes = (articles: any[] = [], barcodes: any[] = []): any[] => {
  if (!articles.length) return []
  if (!barcodes.length) return articles

  const articleProductsIds = new Set(articles.map(article => article.Articulo))
  const filteredBarcodes = barcodes.filter(barcode => articleProductsIds.has(barcode.product_id))

  const barcodesByProductId = filteredBarcodes.reduce((acc, barcode) => {
    const { product_id, barcode: barcodeValue } = barcode
    if (!acc[product_id]) {
      acc[product_id] = []
    }
    acc[product_id].push(barcodeValue)
    return acc
  }, {})

  return articles.map(article => {
    const { Articulo } = article
    return {
      ...article,
      barcodes: barcodesByProductId[Articulo] || [],
    }
  })
}

export const searchArticuloByWord = (articulo: Articulo, word: string): boolean => {
  if (!word || word === "") return true

  const searchWord = word.toLowerCase()

  const fieldsToSearch = ["Descripcion", "Articulo", "Referencia", "Auxiliar", "Marca"]
  const foundInFields = fieldsToSearch.some(field => {
    const fieldValue = articulo[field as keyof Articulo]?.toString()?.toLowerCase()
    return fieldValue?.includes(searchWord)
  })

  const foundInBarcodes = articulo?.barcodes?.some(barcode => barcode?.toString()?.toLowerCase().includes(searchWord))

  return Boolean(foundInFields || foundInBarcodes)
}