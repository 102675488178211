import { useSelector } from '@services/hooks/redux-hooks';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import EsfirusSelect from '@ui/Select/EsfirusSelect';
import DatePickerUI from '../../../../../components/ui/DatePickerUI/DatePickerUI';
import { getUserId, isAdmin, isSupervisor } from '@services/hooks/UserHook';
import { TIPOS_ESTADOS_PEDIDOS } from '../../constants';
import { sortEmpleados } from '@helpers/utils';
import "./ListadoPedidosFilters.scss";

const ListadoPedidosFilters = ({ filters, setFilters, items }: any) => {
  const empresas = useSelector(state => state.empresas.list)
  const users = useSelector(state => state.users.list)
  const userCanSupervise = isAdmin() || isSupervisor()

  const empleadosList = useMemo(() => {
    if (!items.length) return [];

    return sortEmpleados(users).reduce((acc: { label: string; value: string | number }[], empleado) => {
      const { internal = [], external = [] } = empleado.data;
      const idsEmpresas = [...internal, ...external].map((e) => Number(e.empresa));
      const filteredItems = new Set(items.map((item: any) => item.user_character3));
  
      if (idsEmpresas.includes(+filters.empresa) || !filters.empresa) {
        const idEmpresaInterna = empleado.data.internal[0].empresa;
        const empresaObject = empresas.find(
          (empresa) => empresa.Empresa === Number(idEmpresaInterna),
        );

        if(filteredItems.has(String(empleado.data.internal[0].empleado))) {
          const label = `${empleado.nombre} ${empleado.apellidos} - ${empresaObject?.Nombre ?? '-'}`;
          acc.push({
            label,
            value: empleado.id,
          });
        }
      }
      return acc;
    }, []);
  }, [users, filters.empresa, empresas, items]);

  const companyList = useMemo(() => {
    const filteredItems = new Set(items.map((item: any) => Number( item.company_r_403 )));
  
    return empresas
      .filter((e: any) => filteredItems.has(Number(e.Empresa)))
      .map((e: any) => ({ label: e.Nombre, value: e.Empresa }));
  }, [items, empresas]);

  // Opciones del filtro de estado
  const statusOptions = useMemo(() => {
    const filteredItems = new Set(items.map((item: any) => item.status_r_606 ));

    if (filteredItems.has(TIPOS_ESTADOS_PEDIDOS.ABIERTO.value) && filteredItems.has(TIPOS_ESTADOS_PEDIDOS.CONFIRMADO.value)) {
      filteredItems.add('Confirmado&Abierto');
    }

    return Object.values(TIPOS_ESTADOS_PEDIDOS).filter(status => filteredItems.has(status.value));
  }, [items]);
  
  useEffect(() => {
    if (!userCanSupervise) {
      const internalEmployeeId = String(getUserId());
      if (filters.empleado !== internalEmployeeId) {
        setFilters((prevFilters: any) => ({ ...prevFilters, empleado: internalEmployeeId }))
      }
    }
  }, [empresas]);

  return (
    <div className='ef-pedidos-filtros'>
      <div className="ef-left">
        {/* Filtro de empresa */}
        {userCanSupervise && (
          <EsfirusSelect
            placeholder='Seleccionar Empresa'
            options={companyList}
            value={filters.empresa}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empresa: e }))}
          />
        )}

        {/* Filtro de fecha inicio */}
        <DatePickerUI
          className='datePickerFilter'
          value={filters.dateFrom}
          handleChange={newDate => {
            const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
            const isCorrect = !filters.dateTo || dateSelected.isBefore(filters.dateTo)
            if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateFrom: dateSelected }))
          }}
        />

        {/* Filtro de fecha fin */}
        <DatePickerUI
          className='datePickerFilter'
          value={filters.dateTo}
          handleChange={newDate => {
            const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
            const isCorrect = !filters.dateFrom || dateSelected.isAfter(filters.dateFrom)
            if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateTo: dateSelected }))
          }}
        />

        {/* Filtro de empleado */}
        {userCanSupervise && (
          <EsfirusSelect
            options={empleadosList}
            value={filters.empleado}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleado: e }))}
            placeholder="Todos los Empleados"
          />
        )}

        {/* Filtro de estado */}
        <EsfirusSelect
          options={statusOptions}
          value={filters.status}
          change={(e: any) => {
            const excludeStatus = statusOptions.find(option => option.value === e)?.excludeStatus ?? false;
            setFilters((prevFilters: any) => ({
              ...prevFilters,
              status: e,
              excludeStatus
            }));
          }}
          placeholder="Todos los Estados"
        />
      </div>
    </div>
  );
}

export default ListadoPedidosFilters;