export const OPCIONES_USER_FOR_ME_FILTER = {
  EMPTY: "",
  DESTINADOS: "DESTINADOS",
  ORIGEN: "ORIGEN",
  RESPONSABLE: "RESPONSABLE"
} as const

export const OPCIONES_USER_FOR_ME_LABELS = {
  [OPCIONES_USER_FOR_ME_FILTER.EMPTY]: "Seleccionar 'Para mí' ...",
  [OPCIONES_USER_FOR_ME_FILTER.DESTINADOS]: "Destinados a mí",
  [OPCIONES_USER_FOR_ME_FILTER.ORIGEN]: "Originados por mí",
  [OPCIONES_USER_FOR_ME_FILTER.RESPONSABLE]: "Soy responsable",
} as const

export const BORRADORES_AVISOS_STATUS_TYPES = {
  PDTE_VALIDAR: "PV",
  CON_ERRORES: "ER",
  TRASPASADO: "TR"
}

export const BORRADORES_AVISOS_STATUS_TYPES_LABELS = {
  [BORRADORES_AVISOS_STATUS_TYPES.PDTE_VALIDAR]: "Pdte. Validar",
  [BORRADORES_AVISOS_STATUS_TYPES.CON_ERRORES]: "Con errores",
  [BORRADORES_AVISOS_STATUS_TYPES.TRASPASADO]: "Traspasado"
} as const