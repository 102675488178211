import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"

import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput"

interface DatePickerUIProps {
  value: number | string;
  handleChange: (e: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  fill?: boolean;
}

const DatePickerUI = ({ value = "", handleChange = () => { }, disabled = false, readOnly = false, className = "", fill }: DatePickerUIProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DatePicker
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <EsfirusTextInput fullWidth fill={fill} {...params} />
        )}
        inputFormat="DD/MM/YYYY"
        disabled={disabled}
        readOnly={readOnly}
        className={className}
      />
    </LocalizationProvider>
  )
}

export default DatePickerUI