import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { partesService } from "@services/partes";
import { reportLinesService } from "@services/reportLines";

const initial: any = {
  // Listados.
  list: [],
  listValidating: [],
  // Contadores.
  totalItems: 0,
  totalItemsAll: 0,
  totalItemsAbiertos: 0,
  totalItemsConfirmados: 0,
  totalItemsValidados: 0,
  totalItemsRechazados: 0,
  // Filtros.
  selectedDateTo: null,
  selectedDateFrom: null,
  selectedTypeHour: 1,
  selectedEmpresa: null,//getInternalCompany(),
  selectedEmpleado: null,
  selectedEmpleadoHome: null,
  selectedResponsable: null,
  selectedObra: null,
  filterByType: "",
  search: null,
  // Otras. Revisar estas.
  closedDays: [],
  lines: [],
  resumeTotalItems: 0,
  resumeTotalHoras: 0,
  resumeTotalImporte: 0,
  loading: false
};

export const parteSlice = createSlice({
  name: "partes",
  initialState: { ...initial },
  reducers: {
    setLoading: (state, action: any) => {
      state.loading = action.payload;
    },
    resetProperty: (state, action: any) => {
      state[action.payload] = initial[action.payload]
    },
    resetPartes: (state, action: any) => {
      state.list = [...initial.list];
      state.selectedDateTo = initial.selecteddateto;
      state.selectedDateFrom = initial.selecteddatefrom;
      state.selectedTypeHour = initial.selectedtypehour;
      state.selectedEmpresa = initial.selectedempresa;
      state.filterByType = initial.filterByType;
      state.search = initial.search;

      state.selectedEmpleado = initial.selectedEmpleado;
      state.selectedEmpleadoHome = initial.selectedEmpleadoHome;
      state.selectedResponsable = initial.selectedResponsable;
    },
    setPartes: (state, action: PayloadAction<{ list: any; totalItems: any }>) => {
      const { list = [], totalItems = 0 } = action.payload
      state.list = [...list] as any;
      state.totalItems = totalItems as any;
    },
    setPartesAllTotalItems: (state, action: PayloadAction<{ totalItemsAll: any; totalItemsAbiertos: any; totalItemsConfirmados: any; totalItemsValidados: any; totalItemsRechazados: any }>) => {
      const { totalItemsAll = 0, totalItemsAbiertos = 0, totalItemsConfirmados = 0, totalItemsValidados = 0, totalItemsRechazados = 0 } = action.payload
      state.totalItemsAll = totalItemsAll as any;
      state.totalItemsAbiertos = totalItemsAbiertos as any;
      state.totalItemsConfirmados = totalItemsConfirmados as any;
      state.totalItemsValidados = totalItemsValidados as any;
      state.totalItemsRechazados = totalItemsRechazados as any;
    },
    setPartesResume: (state, action: PayloadAction<{ resumeTotalItems: any; resumeTotalHoras: any; resumeTotalImporte: any }>) => {
      const { resumeTotalItems = 0, resumeTotalHoras = 0, resumeTotalImporte = 0 } = action.payload
      state.resumeTotalItems = resumeTotalItems as any;
      state.resumeTotalHoras = resumeTotalHoras as any;
      state.resumeTotalImporte = resumeTotalImporte as any;
    },
    setPartesValidating: (state, action: any) => {
      state.listValidating = [...action.payload] as any;
    },
    updateParte: (state, action: any) => {
      state.list = [...state.list].map((parte: any) => {
        if (parte.id === action.payload.id) {
          return action.payload.parte;
        }

        return parte;
      }) as any;
    },
    setSelectedDateFrom: (state, action: any) => {
      (state.selectedDateFrom as any) = new Date(action.payload).getTime()
    },
    setSelectedDateTo: (state, action: any) => {
      (state.selectedDateTo as any) = new Date(action.payload).getTime()
    },
    setSelectedEmpresa: (state, action: any) => {
      state.selectedEmpresa = action.payload
    },
    setSelectedObra: (state, action: any) => {
      state.selectedObra = action.payload
    },
    setSelectedTypeHour: (state, action: any) => {
      state.selectedTypeHour = action.payload
    },
    setFilterByType: (state, action: any) => {
      state.filterByType = action.payload
    },
    setClosedDay: (state, action: any) => {
      state.closedDays = [...action.payload] as any;
    },
    setLines: (state, action: any) => {
      state.lines = [...action.payload] as any;
    },
    setSelectedEmpleado: (state, action: any) => {
      state.selectedEmpleado = action.payload
    },
    setSelectedEmpleadoHome: (state, action: any) => {
      state.selectedEmpleadoHome = action.payload
    },
    setSelectedResponsable: (state, action: any) => {
      state.selectedResponsable = action.payload
    },
  },
});

export default parteSlice.reducer;
export const {
  setPartes,
  setPartesAllTotalItems,
  setPartesResume,
  setPartesValidating,
  resetPartes,
  updateParte,
  setSelectedDateFrom,
  setSelectedDateTo,
  setSelectedTypeHour,
  setSelectedEmpresa,
  setSelectedObra,
  setFilterByType,
  setClosedDay,
  setLines,
  setLoading,
  setSelectedEmpleado,
  setSelectedEmpleadoHome,
  setSelectedResponsable,
  resetProperty
} = parteSlice.actions;

export const getPartes = (
  months: number = 1,
  page: number = 0,
  pageSize: number = 50,
  estado: number,
  filters: any,
  sort: Array<string> = [],
  reload: boolean = false) => async (dispatch: any) => {
  try {
    // Parseador, pra que coincidan los campos front de filtro con el back
    const sortValues: Record<string, string> = {
      _id: 'id',
      date: 'date',
      nombreObra2: 'idObra',
      nombre_empleado: 'nombre_empleado',
      horas: 'horas',
      importe: 'importe'
    }

    if (sort.length && !sortValues[sort[0]]) return;

    const _sort: string[] = sort.length && sortValues[sort[0]] 
      ? [sortValues[sort[0]], sort[1]] 
      : [];

    reload && dispatch(setLoading(true as any));

    const partesRes = await partesService.get(months, page, pageSize, estado, filters, _sort);

    dispatch(setPartes({
      list: partesRes.data?.data?.items ?? [],
      totalItems: partesRes.data?.data?.total_items ?? 0,
    }));

    reload && dispatch(setLoading(false as any));

    const [totalItemsRes, closedDayRes, partesValidatingRes, reportLinesRes, partesResumeRes] = await Promise.all([
      partesService.getAllTotalItems(months, filters),
      partesService.getClosedDay(),
      partesService.getPartesValidating(months),
      reportLinesService.get(months),
      partesService.getResume(months, estado, filters)
    ]);

    if (totalItemsRes) {
      dispatch(setPartesAllTotalItems({
        totalItemsAll: totalItemsRes.data?.data?.total_items_all ?? 0,
        totalItemsAbiertos: totalItemsRes.data?.data?.total_items_abiertos ?? 0,
        totalItemsConfirmados: totalItemsRes.data?.data?.total_items_confirmados ?? 0,
        totalItemsValidados: totalItemsRes.data?.data?.total_items_validados ?? 0,
        totalItemsRechazados: totalItemsRes.data?.data?.total_items_rechazados ?? 0,
      }));
    }

    dispatch(setClosedDay(closedDayRes.data?.data as any));
    if (partesValidatingRes) {
      dispatch(setPartesValidating(partesValidatingRes.data?.data as any));
    }
    dispatch(setLines(reportLinesRes.data?.data as any));
    
    if (partesResumeRes) {
      dispatch(setPartesResume({
        resumeTotalItems: partesResumeRes.data?.data?.total_items ?? 0,
        resumeTotalHoras: partesResumeRes.data?.data?.total_hours ?? 0,
        resumeTotalImporte: partesResumeRes.data?.data?.total_importe ?? 0,
      }));
    }
  } catch (error) {
    console.error("Error fetching partes data:", error);
  }
};
