import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { TextField, TextFieldProps } from "@mui/material";

const CustomTextField = styled(TextField)<TextFieldProps>(({ theme, modified, valid, fill }: any) => ({
  borderRadius: 20,
  borderWidth: 1,
  border: modified ? "2px solid #D97938" : valid ? "2px solid #82d08e" : "1px solid #075577",
  backgroundColor: fill ? "#075577" : "white",
  "& .MuiOutlinedInput-root": {
    borderRadius: 20,
    borderWidth: 0,
    border: 0,
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    backgroundColor: "#efefef",
  },
  "& input": {
    textAlign: "center",
    color: fill ? "white" : "#075577",
  },
  "& fieldset": {
    border: 0,
  },
  "& .MuiSvgIcon-fontSizeMedium": {
    ...(fill && { color: "white" }),
  },
}));

const EsfirusTextInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  return (
    <CustomTextField
      size="small"
      color="info"
      inputProps={props?.inputProps}
      inputRef={ref}
      {...props}
    >
      {props.children}
    </CustomTextField>
  );
});

export default EsfirusTextInput;
