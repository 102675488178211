import { IconButton } from "@mui/material"
import { Refresh } from '@mui/icons-material';
import { getRequest } from "@screens/Backoffice/utils/requests";
import { configurationService } from "@services/configApp";

const propsToShow = [{
  key: "calendarios",
  label: "Calendarios",
}, {
  key: "capitulos",
  label: "Capitulos",
}, {
  key: "categorias",
  label: "Categorias",
}, {
  key: "empleados",
  label: "Empleados",
}, {
  key: "empresas",
  label: "Empresas",
}, {
  key: "mano_obra",
  label: "Manos de obra",
}, {
  key: "obras",
  label: "Obras",
}, {
  key: "presupuestos",
  label: "Presupuestos",
}, {
  key: "proveedores",
  label: "Proveedores",
}, {
  key: "tarifas",
  label: "Tarifas",
}, {
  key: "tipos",
  label: "Tipos de hora",
}, {
  key: "tipos_hora_empleado",
  label: "Tipos de hora por Empleado",
}, {
  key: "notices_priorities",
  label: "Prioridades de avisos",
}, {
  key: "notices_statuses",
  label: "Estados de avisos",
}, {
  key: "notices_types",
  label: "Tipos de avisos",
}, {
  key: "products_barcodes",
  label: "Barcodes de productos",
}]

const CacheInfo = ({ setCache = () => { }, cache = {}, setCategorias = () => { }, setTipos = () => { } }) => {
  const {
    configuration: { allowGetBarcodesTable }
  } = configurationService.getConfig();

  const handleRefreshCacheDataInstance = async (instance) => {
    if (!instance) return

    setCache(prev => ({ ...prev, [instance]: "loading" }))

    try {
      await getRequest(`config/refresh_data?instance=${instance}`)
    } catch (error) {
      alert(error?.error?.displayMessage || "Ha ocurrido un problema y no se ha podido actualizar la instancia.")
    } finally {
      // Refrescamos datos locales de caché
      const { data: dataCache } = await getRequest(`cache`)
      if (instance === "categorias") setCategorias(dataCache.categorias)
      if (instance === "tipos") setTipos(dataCache.tipos)
      setCache(dataCache)
    }
  }

  const isDisabledTable = (table) => {
    return {
      products_barcodes: !allowGetBarcodesTable
    }[table] ?? false
  };
  
  return (
    <div className="cache-info-wrapper">
      <span className="cache-info-title">Datos del servidor</span>
      <table className="cache-info-table">
        <thead>
          <tr>
            <th>Instancia</th>
            <th>Resultado</th>
            <th className="cache-info-td-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {propsToShow.map(instance => {
            const isDisabled = isDisabledTable(instance.key);
            const noCharge = !cache[instance.key]
            const noItems = cache[instance.key] && !cache[instance.key].length
            const loading = cache[instance.key] === "loading"
            const message = loading
              ? "Cargando items..."
              : noCharge
                ? isDisabled ? 'La tabla está desactivada' : "No cargado en servidor."
                : noItems
                  ? "No hay items de esta instancia."
                  : `Hay ${cache[instance.key].length} elementos de esta instancia`
            
            const getColor = () => {
              if (noCharge && isDisabled) return '#9d881a';
              if (noCharge || noItems) return 'red';
              if (loading) return 'grey';
              return 'green';
            }

            return (
              <tr key={`build-item-list-${instance.label}`}>
                <td>
                  {instance.label}
                </td>
                <td>
                <span
                  style={{ color: getColor() }}>
                  {message}
                </span>;
                </td>
               <td className="cache-info-td-center">
                 { !isDisabled &&  <IconButton disabled={loading} onClick={() => handleRefreshCacheDataInstance(instance.key)}>
                    <Refresh />
                  </IconButton> }
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CacheInfo