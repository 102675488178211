import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Modal, Paper } from "@mui/material"
import Loader from "@components/ui/Loader/Loader"
import FormAviso, { FormDataAviso } from "@components/FormParte/FormAviso"
import { avisosService } from "@services/avisos"
import "./AvisoIndividual.scss"
import moment from "moment"

const AvisoIndividual = ({ isEditing = false }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [initialData, setInitialData] = useState<FormDataAviso | null>(null)
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [isValidating, setIsValidating] = useState<boolean>(false)

  useEffect(() => {
    let componentMounted = true

    if (id) {
      const fetchAviso = async () => {
        setIsLoadingData(true)
        try {
          const resp = await avisosService.getBorradorByUniqueId(id)
          if (componentMounted) setInitialData({ ...resp?.data?.data, date: moment(resp?.data?.data?.date), time: moment(resp?.data?.data?.time) })
        } catch {
          handleClose()
        } finally {
          if (componentMounted) setIsLoadingData(false)
        }
      }

      fetchAviso()
    }

    return () => {
      componentMounted = false
    }
  }, [id])

  const handleClose = () => {
    navigate("/borradores-avisos")
  }

  const handleValidate = async (data: FormDataAviso) => {
    setIsValidating(true)

    try {
      await avisosService.validateBorradorAviso({ id: data.unique_id })
      navigate('/borradores-avisos', { state: { refresh: true } });
    } catch (error) {
    } finally {
      setIsValidating(false)
    }
  }

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-aviso-title"
      aria-describedby="modal-modal-aviso-description"
    >
      <div className="see-modal">
        <Paper
          elevation={3}
          sx={{
            maxWidth: 1048,
            margin: "auto",
            borderRadius: "25px",
            paddingBottom: "30px",
          }}
        >
          {isLoadingData ? (
            <Box className="ml-20 mr-20 mt-40">
              <Loader message="Cargando datos del borrador de aviso" />
            </Box>
          ) : (
            <>
              <div className="paper-header">
                {!isEditing ? (
                  <h5>Nº Borrador de aviso {initialData ? initialData.draft_id : ""}</h5>
                ) : (
                  <h5>Editar Nº Borrador de aviso {initialData ? initialData.draft_id : ""}</h5>
                )}
              </div>
              <Box className="ml-20 mr-20 mt-40">
                <FormAviso
                  initialData={initialData}
                  handleClose={handleClose}
                  handleValidate={handleValidate}
                  editable={isEditing}
                  isValidating={isValidating}
                />
              </Box>
            </>
          )}
        </Paper>
      </div>
    </Modal>
  )
}

export default AvisoIndividual