export interface Obra {
  name?: string;
  description?: string;
  codigoCliente?: number;
  delegacion?: number;
  obraCode?: number;
  reference?: string;
  calRef?: string;
  date?: any;
  responsible?: number;
  status?: ObraStatus;
  empleado?: number;
  company?: number;
  companyDestino?: number;
  presupuestos?: any[];
  tipo?: any;
  tipo_obra?: any;
  userEmpleadoId?: number | string;
  id?: string | number;
  prefixId?: string | number;
  duplicated?: boolean;
  notification?: string | number;
  // Provenientes de graph...
  Administracion: boolean;
  Cliente?: number;
  CodigoContacto?: number;
  Delegacion?: number
  Descripcion?: string;
  Empresa?: number;
  EmpresaDestino?: string;
  Estado?: ObraStatus;
  Fecha?: string;
  NombreCliente?: string;
  Obra?: number;
  ObraExterna?: string | number;
  Referencia?: string;
  Responsable?: number;
  SolActividad?: boolean;
  SolCapitulo?: boolean;
  SolPresupuesto?: boolean;
  StockObra?: boolean;
  Tipo?: number;
}

export enum ObraStatus {
  OPEN, EC, GA
}

export interface Presupuesto {
  Descripcion: string;
  Estado: string;
  Fecha: string;
  Obra: number;
  Presupuesto: number;
  Anexo: number;
  Version: number;
  Empresa: number;
  Delegacion: number;
  Referencia: string;
  Tipo: number;
  value: any;
  label: string;
}

export interface Capitulo {
  Capitulo: string;
  Descripcion: string;
  Nivel: number;
  Partida: boolean;
  Presupuesto: number;
  codhpre: number;
  label?: string;
}
