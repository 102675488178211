/* tslint:disable */
/* eslint-disable */
/**
 * Microtech partes API
 * API Library for SmartyBar application
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportGridGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/report/grid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            // for (const key in options.params) {
            //     query.set(key, options.params[key]);
            // }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling reportIdDelete.');
            }
            const localVarPath = `/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling reportIdGet.');
            }
            const localVarPath = `/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling reportIdPut.');
            }
            const localVarPath = `/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportIdValidatePut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling reportIdValidatePut.');
            }
            const localVarPath = `/report/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (options.params.sort.length) {
                options.params.sortBy = options.params.sort[0];
                options.params.order = options.params.sort[1];

            }
            delete options.params.sort;
            
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            delete localVarRequestOptions.params

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportClosedDayPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/closedDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },

        /**
         * 
         * @summary Returns a list of closedDays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetClosedDay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/closedDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            // for (const key in options.params) {
            //     query.set(key, options.params[key]);
            // }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },

    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportGridGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportGridGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    
                    configuration?.catchError(error);
                });
            };
        },
        /**
         * 
         * @summary Update a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Returns a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Update a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportIdPut(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Validate a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdValidatePut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportIdValidatePut(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Create report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Returns a list of reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Closed Days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportClosedDayPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportClosedDayPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Returns a list of closedDays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetClosedDay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportsGetClosedDay(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportGridGet(options?: AxiosRequestConfig): Promise<any> {
            return ReportsApiFp(configuration).reportGridGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdDelete(id: string, options?: AxiosRequestConfig): Promise<any> {
            return ReportsApiFp(configuration).reportIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdGet(id: string, options?: AxiosRequestConfig): Promise<any> {
            return ReportsApiFp(configuration).reportIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdPut(id: string, options?: AxiosRequestConfig): Promise<any> {
            return ReportsApiFp(configuration).reportIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate a report by ID
         * @param {string} id The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIdValidatePut(id: string, options?: AxiosRequestConfig): Promise<any> {
            return ReportsApiFp(configuration).reportIdValidatePut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPost(options?: AxiosRequestConfig): Promise<any> {
            return ReportsApiFp(configuration).reportPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGet(options?: AxiosRequestConfig): Promise<any> {
            return ReportsApiFp(configuration).reportsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Create report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportGridGet(options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportGridGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a report by ID
     * @param {string} id The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportIdDelete(id: string, options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportIdDelete(id, options).then((request) => {
            return request(this.axios, this.basePath)
        });
    }
    /**
     * 
     * @summary Returns a report by ID
     * @param {string} id The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportIdGet(id: string, options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a report by ID
     * @param {string} id The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportIdPut(id: string, options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Validate a report by ID
     * @param {string} id The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportIdValidatePut(id: string, options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportIdValidatePut(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportPost(options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns a list of reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportsGet(options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report Closed Day
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportClosedDayPost(options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportClosedDayPost(options).then((request) => request(this.axios, this.basePath));
    }


    /**
     * 
     * @summary Returns a list of closedDays
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public async reportsGetClosedDay(options?: AxiosRequestConfig): Promise<any> {
        return ReportsApiFp(this.configuration).reportsGetClosedDay(options).then((request) => request(this.axios, this.basePath));
    }

}
