import { Obra } from "@models/obra";
import { createSlice } from "@reduxjs/toolkit";

export interface SelectedObraSliceType {
  obra: Obra | any
}

const initial: SelectedObraSliceType = {
  obra: {},
};

export const SelectedObraSlice = createSlice({
  name: "selectedobra",
  initialState: { ...initial },
  reducers: {
    resetSelectedObra: (state, action: any) => {
      state.obra = { ...initial.obra };
    },
    updateSelectedObra: (state, action: any) => {
      state.obra = { ...action.payload } as Obra;
    },
    removeSelectedObra: (state) => {
      state.obra = {};
    },
  },
});

export default SelectedObraSlice.reducer;
export const { updateSelectedObra, removeSelectedObra, resetSelectedObra } =
  SelectedObraSlice.actions;
