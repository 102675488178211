import moment from "moment"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import ListadoAvisosFilters from "../BBs/ListadoAvisosFilters/ListadoAvisosFilters"
import ListadoAvisosDefinitivosTable from "./BBs/ListadoAvisosDefinitivosTable/ListadoAvisosDefinitivosTable"

import Loader from "@components/ui/Loader/Loader"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable"
import { avisosService } from "@services/avisos"
import { NoticeDefinitive } from "@models/notices"

const ListadoAvisosDefinitivos = () => {
  const [filters, setFilters] = useState({
    empresa: "",
    dateFrom: moment().startOf("day").add(-3, "month"),
    dateTo: moment().startOf("day").add(1, "day").add(-1, "second"),
    customer: "",
    obra: "",
    empleadoDestino: "",
    empleadoOrigen: "",
    empleadoResponsable: "",
    status: "",
    type: ""
  })

  const [avisos, setAvisos] = useState<NoticeDefinitive[]>([])
  const [totalItems, setTotalItems] = useState<number>(0)
  const [loadingAvisos, setLoadingAvisos] = useState<boolean>(false)
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>({ page: 0, sizePage: 50 })

  useEffect(() => {
    let componentMounted = true

    const fetchAvisos = async () => {
      setLoadingAvisos(true)
      try {
        const resp = await avisosService.getList({
          from: filters.dateFrom.unix(),
          to: filters.dateTo.unix(),
          company: filters.empresa,
          customer: filters.customer,
          obra: filters.obra,
          originEmployee: filters.empleadoOrigen,
          targetEmployee: filters.empleadoDestino,
          responsibleEmployee: filters.empleadoResponsable,
          status: filters.status,
          type: filters.type,
          pagination: pagination
        })
        const newItems = resp?.data?.data?.items ?? []
        const newTotalItems = resp?.data?.data?.total_items ?? 0
        if (componentMounted) {
          setAvisos(newItems)
          setTotalItems(newTotalItems)
        }
      } catch {
        setAvisos([])
        setTotalItems(0)
      } finally {
        if (componentMounted) setLoadingAvisos(false)
      }
    }

    fetchAvisos()

    return () => {
      componentMounted = false
    }
  }, [filters, pagination])

  const handleChangePagination: HandleChangePaginationType = (newPaginationData) => {
    setPagination(newPaginationData)
  }

  return (
    <>
      <ListadoAvisosFilters filters={filters} setFilters={setFilters} items={avisos} />
      {loadingAvisos ? (
        <Loader message="Cargando listado de avisos" />
      ) : (
        <ListadoAvisosDefinitivosTable
          avisos={avisos}
          pagination={pagination}
          totalItems={totalItems}
          handleChangePagination={handleChangePagination}
        />
      )}

      {/* Para cargar el modal de view y edit avisos */}
      <Outlet />
    </>
  );
}

export default ListadoAvisosDefinitivos;
