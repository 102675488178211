import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from '@services/hooks/redux-hooks';
import { Grid } from '@mui/material';
import moment from 'moment';

import EsfirusSelect, { EsfirusSelectPropsOption } from '@ui/Select/EsfirusSelect';
import DatePickerUI from '../../../../components/ui/DatePickerUI/DatePickerUI';

import { BORRADORES_AVISOS_STATUS_TYPES_LABELS, OPCIONES_USER_FOR_ME_FILTER, OPCIONES_USER_FOR_ME_LABELS } from '@screens/Avisos/constants';
import { getUserId, isAdmin, isSupervisor } from '@services/hooks/UserHook';

import "./ListadoAvisosFilters.scss"
import { findUserByCompanyAndEmployeeIds, sortEmpleados } from '@helpers/utils';
import { Obra } from '@models/obra';

const ListadoAvisosFilters = ({ filters, setFilters, items, isForBorrador = false }: any) => {
  const empresas = useSelector(state => state.empresas.list)
  const obras = useSelector(state => state.obras.list)
  const noticeStatuses = useSelector(state => state.notices.listStatuses)
  const noticeTypes = useSelector(state => state.notices.listTypes)
  const users = useSelector(state => state.users.list)

  // Posibilidad de deshabilitar los filtros de empleados y gestionar el filtro "para mí".
  const [forMeValue, setForMeValue] = useState<string>(OPCIONES_USER_FOR_ME_FILTER.EMPTY)
  const [disableEmployeeFilters, setDisableEmployeeFilters] = useState<{ origin: boolean, target: boolean, responsible: boolean }>({ origin: false, target: false, responsible: false })

  const userForMeOptions = Object.entries(OPCIONES_USER_FOR_ME_LABELS).map(([value, label]) => ({ label, value }))
  const draftStatusOptions = Object.entries(BORRADORES_AVISOS_STATUS_TYPES_LABELS).map(([value, label]) => ({ label, value }))

  const usersOptions = useCallback(
    (type: string) => {
      const mapTypes: Record<string, string> = {
        empleadoOrigen: isForBorrador ? 'origin_worker' : 'origin_employee',
        empleadoDestino: isForBorrador ? 'target_worker' : 'destination_employee',
        empleadoResponsable: isForBorrador ? 'worker_id' : 'employee',
      };
  
      const { empleadoDestino, empleadoOrigen, empleadoResponsable, empresa } = filters;
      const company = isForBorrador ? 'company_id' : 'company';
  
      if ((!empleadoDestino && !empleadoOrigen && !empleadoResponsable) && !items.length) {
        return [];
      }
  
      if ((empleadoDestino || empleadoOrigen || empleadoResponsable) && !items.length) {
        const filtroActivo = empleadoDestino
          ? 'empleadoDestino'
          : empleadoOrigen
          ? 'empleadoOrigen'
          : empleadoResponsable
          ? 'empleadoResponsable'
          : null;
  
        if (filtroActivo !== type) {
          return [];
        }
      }
  
      const usersByFilter =
        items.length
          ? Array.from(
              new Map(
                items
                  .flatMap((i: Record<string, any>) =>
                    findUserByCompanyAndEmployeeIds(users, i[company], i[mapTypes[type]]) || []
                  )
                  .map((user: any) => [user.id, user])
              ).values()
            )
          : users;
  
      return sortEmpleados(usersByFilter)
        .map((user) => {
          const { internal = [], external = [] } = user.data || {};
          const idsEmpresas = [...internal, ...external].map((e) => +e.empresa);
  
          if (empresa && !idsEmpresas.includes(+empresa)) return null;
  
          const idEmpresaInterna = internal[0]?.empresa;
          const empresaObject = empresas.find((e) => e.Empresa === Number(idEmpresaInterna));
  
          return {
            label: `${user.nombre} ${user.apellidos} - ${empresaObject?.Nombre ?? '-'}`,
            value: user.id,
          };
        })
        .filter(Boolean);
    },
    [users, filters, empresas, items]
  );
  
  const obrasOptions = useMemo(() => {
    const uniqueWorkIds = new Set(items.map((i: any) => Number(i[isForBorrador ? 'work_id': 'project'])));
    if (!items.length) return [];
  
    return obras
      .filter((obra) => uniqueWorkIds.has(obra.Obra))
      .map((obra) => ({ label: obra.Descripcion, value: obra.Obra }));
  }, [obras, filters.empresa, items]);

  const noticesTypes = useMemo(() => {
    if (!items.length) return [];
  
    const uniqueNotice = new Set(items.map((i: any) => i[isForBorrador ? 'notice_type' : 'notification_type']));
  
    return noticeTypes
      .filter((e) => uniqueNotice.has(e.notification_type))
      .map((e: any) => ({ label: e.description, value: e.notification_type }));
  }, [items, noticeTypes]);

const statuses = useMemo(() => {
  if (!items.length && !filters.status) return [];
  if (!items.length && filters.status) {
    return isForBorrador
    ? draftStatusOptions
    : noticeStatuses
    .map((e: any) => ({ label: e.description, value: e.status }))
  }

  const uniqueStatus = new Set(items.map((i: any) => i[isForBorrador ? 'draft_status': 'status']));

  return isForBorrador
    ? draftStatusOptions.filter((e) => uniqueStatus.has(e.value))
    : noticeStatuses
    .filter((e) => uniqueStatus.has(e.status))
    .map((e: any) => ({ label: e.description, value: e.status }))
}, [items, isForBorrador, draftStatusOptions, noticeStatuses]);

  const companiesOptions = useMemo(() => {
    const uniqueStatus = new Set(items.map((i: any) => i[isForBorrador ? 'company_id': 'company']));

    return empresas
    .filter((e) => uniqueStatus.has(e.Empresa))
    .map((e: any) => ({ label: e.Nombre, value: e.Empresa }))
  }, [items])
  
  const customersOptions = useMemo(() => {
    return items.reduce((acc: any, item: any) => {
      const customer = item.customer_or_potencial_id ?? item.customer
      if (!acc.find((e: any) => e.value == customer)) {
        acc.push({ label: customer, value: customer })
      }
      return acc
    }, [])
  }, [items])

  const userCanSupervise = isAdmin() || isSupervisor()

  // Opciones del filtro de "para mí" y estados de un borrador.

  useEffect(() => {
    if (!userCanSupervise) {
      handleChangeForMe(OPCIONES_USER_FOR_ME_FILTER.DESTINADOS)
    }
  }, [])

  const handleChangeForMe = (e: any) => {
    const userId = String(getUserId())
    setForMeValue(e)

    if (e === OPCIONES_USER_FOR_ME_FILTER.EMPTY) {
      setDisableEmployeeFilters({ origin: false, target: false, responsible: false })
      setFilters((prevFilters: any) => ({ ...prevFilters, empleadoDestino: "", empleadoOrigen: "", empleadoResponsable: "" }))
    } else if (e === OPCIONES_USER_FOR_ME_FILTER.DESTINADOS) {
      setDisableEmployeeFilters({ origin: false, target: true, responsible: false })
      setFilters((prevFilters: any) => ({ ...prevFilters, empleadoDestino: userId, empleadoOrigen: "", empleadoResponsable: "" }))
    } else if (e === OPCIONES_USER_FOR_ME_FILTER.ORIGEN) {
      setDisableEmployeeFilters({ origin: true, target: false, responsible: false })
      setFilters((prevFilters: any) => ({ ...prevFilters, empleadoDestino: "", empleadoOrigen: userId, empleadoResponsable: "" }))
    } else if (e === OPCIONES_USER_FOR_ME_FILTER.RESPONSABLE) {
      setDisableEmployeeFilters({ origin: false, target: false, responsible: true })
      setFilters((prevFilters: any) => ({ ...prevFilters, empleadoDestino: "", empleadoOrigen: "", empleadoResponsable: userId }))
    }
  }

  return (
    <div className='ef-avisos-filtros'>
      <Grid container spacing={1}>
        {/* Filtro de fecha inicio */}
        <Grid item xs={12} sm={6} md={1.5}>
          <DatePickerUI
            value={filters.dateFrom}
            fill={!!filters.dateFrom}
            handleChange={newDate => {
              const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
              const isCorrect = !filters.dateTo || dateSelected.isBefore(filters.dateTo)
              if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateFrom: dateSelected }))
            }}
          />
        </Grid>

        {/* Filtro de fecha fin */}
        <Grid item xs={12} sm={6} md={1.5}>
          <DatePickerUI
            value={filters.dateTo}
            fill={!!filters.dateFrom}
            handleChange={newDate => {
              const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
              const isCorrect = !filters.dateFrom || dateSelected.isAfter(filters.dateFrom)
              if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateTo: dateSelected }))
            }}
          />
        </Grid>

        {/* Filtro de cliente */}
        <Grid item xs={12} sm={6} md={3}>
          <EsfirusSelect
            options={customersOptions}
            value={filters.customer}
            fill={!!filters.customer}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, customer: e }))}
            placeholder="Todos los clientes"
          />
        </Grid>

        {/* Filtro de empresa */}
        {userCanSupervise && (
          <Grid item xs={12} sm={6} md={3}>
            <EsfirusSelect
              placeholder='Todas las empresas'
              options={companiesOptions}
              value={filters.empresa}
              fill={!!filters.empresa}
              change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empresa: e }))}
            />
          </Grid>
        )}

        {/* Filtro de obra */}
        <Grid item xs={12} sm={6} md={3}>
          <EsfirusSelect
            options={obrasOptions as EsfirusSelectPropsOption[]}
            value={filters.obra}
            fill={!!filters.obra}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, obra: e }))}
            placeholder="Todas las obras"
          />
        </Grid>

        {/* Filtro de combo para mí */}
        <Grid item xs={12} sm={6} md={3}>
          <EsfirusSelect
            placeholder='Seleccionar para mí'
            options={userCanSupervise ? userForMeOptions : userForMeOptions.filter(opt => !!opt.value)}
            disableBlank
            value={forMeValue}
            fill={!!forMeValue}
            change={handleChangeForMe}
          />
        </Grid>

        {/* Filtro de empleado origen */}
        {userCanSupervise && (
          <Grid item xs={12} sm={6} md={3}>
            <EsfirusSelect
              options={usersOptions('empleadoOrigen') as EsfirusSelectPropsOption[]}
              value={filters.empleadoOrigen}
              fill={!!filters.empleadoOrigen}
              change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleadoOrigen: e }))}
              disabled={disableEmployeeFilters.origin}
              placeholder="Todos los empleados origen"
            />
          </Grid>
        )}

        {/* Filtro de empleado destino */}
        {userCanSupervise && (
          <Grid item xs={12} sm={6} md={3}>
            <EsfirusSelect
              options={usersOptions('empleadoDestino') as EsfirusSelectPropsOption[]}
              value={filters.empleadoDestino}
              fill={!!filters.empleadoDestino}
              change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleadoDestino: e }))}
              disabled={disableEmployeeFilters.target}
              placeholder="Todos los empleados destino"
            />
          </Grid>
        )}

        {/* Filtro de empleado responsable */}
        {userCanSupervise && (
          <Grid item xs={12} sm={6} md={3}>
            <EsfirusSelect
              options={usersOptions('empleadoResponsable') as EsfirusSelectPropsOption[]}
              value={filters.empleadoResponsable}
              fill={!!filters.empleadoResponsable}
              change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleadoResponsable: e }))}
              disabled={disableEmployeeFilters.responsible}
              placeholder="Todos los empleados responsable"
            />
          </Grid>
        )}

        {/* Filtro de estado */}
        <Grid item xs={12} sm={6} md={3}>
          <EsfirusSelect
            options={statuses}
            value={filters.status}
            fill={!!filters.status}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, status: e }))}
            placeholder="Todos los estados"
          />
        </Grid>

        {/* Filtro de tipo */}
        <Grid item xs={12} sm={6} md={3}>
          <EsfirusSelect
            options={noticesTypes}
            value={filters.type}
            fill={!!filters.type}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, type: e }))}
            placeholder="Todos los tipos"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ListadoAvisosFilters;