import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { v4 as uuid } from 'uuid';

import "./EsfirusDropdownMenu.scss";

const EsfirusDropdownMenu = ({ Button, actions, className }: EsfirusDropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl
  const menuLabelId = uuid()
  const menuId = uuid()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <Button
        id={menuLabelId}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': menuLabelId,
        }}
      >
        {actions.length > 0 && actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={async () => {
              handleClose()
              await action.action()
            }}
          >{action.label}</MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export interface EsfirusDropdownMenuActionProps {
  label: string;
  action: () => void | Promise<void>;
}

export interface EsfirusDropdownMenuProps {
  Button: React.FC<any>;
  actions: Array<EsfirusDropdownMenuActionProps>;
  className?: string;
}

export default EsfirusDropdownMenu