import moment, { Moment } from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput"

interface TimePickerUIProps {
  value: Moment | null;
  handleChange: (a: Moment | null) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
}

const TimePickerUI = ({ value, handleChange, disabled = false, readOnly = false, className = "" }: TimePickerUIProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <TimePicker
        value={value?.toString()}
        onChange={(nv => handleChange(!!nv ? moment(nv) : null))}
        renderInput={(params) => (
          <EsfirusTextInput fullWidth {...params} />
        )}
        inputFormat="HH:mm"
        disabled={disabled}
        readOnly={readOnly}
        className={className}
      />
    </LocalizationProvider>
  )
}

export default TimePickerUI