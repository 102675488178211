import Grid from "@mui/material/Grid";
import manoDeObraIcon from "@assets/svgs/lineasManoDeObra.svg";
import "./LineasManoDeObra.scss";
import EsfirusSelect from "@ui/Select/EsfirusSelect";
import EsfirusHourInput from "@ui/Hour-input/EsfirusHourInput";
import { useDispatch, useSelector } from "@services/hooks/redux-hooks";
import { updateLine } from "@store/slices/lines";
import { calculateSeconds, calculateTime } from "@services/hooks/TimeHook.js";
import { configurationService } from "@services/configApp";
import { EsfirusInputTextAreaNative, EsfirusInputTextNative } from "@components/ui/InputTextNative/EsfirusInputTextNative";
import { Capitulo } from "@models/obra";
import { useEffect, useMemo, useRef, useState } from "react";
import { reportLinesService } from "@services/reportLines";
import selectedObra from "@store/slices/selectedObra";
import { constants } from "@helpers/constants";
import { getCategoriasByCompany, getCosteEmpleado, getTokenDecoded, getUserId } from "@services/hooks/UserHook";
import { erpService } from "@services/erp";
import useRefState from "@services/hooks/useRefState";
import { getInternalCompany } from "@helpers/companyData";
import { UserWithDataParsed } from "@models/user";
import { Activity, SubActivity } from "@models/activitiesAndSubActivities";

interface Categoria {
  Categoria: number;
  NombreCategoria: string;
}
interface TipoHora {
  NombreTipoHora: string;
  TipoHora: number;
}

interface DatosLinea {
  inicio: number;
  fin: number;
  userWorker?: { id: number };
  user?: number;
  idParte?: any;
}

interface Linea {
  datos: DatosLinea;
}

const NO_MATCH_CATEGORY_TYPEHOUR = "no-match-category-typeHour"

function LineasmanoDeObra(props: any) {
  const dispatch = useDispatch();
  const lineas = useSelector((state) => (state as any).lines);
  // console.log("propsL", props, lineas);
  const categorias = useSelector((state) => (state as any).categorias).list;
  const tipoHoras = useSelector((state) => (state as any).tipoHora).list;
  const tipoHorasByEmpleado = useSelector((state) => (state as any).tipoHora).listByEmpleado;
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;
  const manoObrasList = useSelector((state) => (state as any).mano_obra).list;
  const calendarios = useSelector((state) => (state as any).calendarios).list;
  const empresas = useSelector((state) => (state as any).empresas).list;
  const users = useSelector(state => state.users.list)
  const { activities, subActivities } = useSelector(state => state.activitiesAndSubActivities);

  const [allLinesUser, setAllLinesUser] = useState<any>([]);
  const [loaded, setLoaded] = useState(false)
  const abortControllerRef = useRef<AbortController | null>(null);

  // TODO: Esto podria ir a un HookDE Congif. pero hay que pasar todas los get para allà. 
  // Dejo esto por acà para hacer a futuro
  const config = localStorage?.getItem("ef-configuration") || "";
  const config_JSON = JSON.parse(config);
  const config_calendarios = config_JSON?.calendarios ?? {};

  // Calendarios cuando tipo de hora es varios.
  const [calendariosEnTipoDeHoraVarios, setCalendariosEnTipoDeHoraVarios] = useRefState<any[]>([])

  const empleadosOptions = useMemo(() => {
    const empleadosOfInternalCompany = users.filter(em => {
      const isInInternalArray = em.data.internal.some(v => v.empresa == getInternalCompany())
      return isInInternalArray
    })

    const options = empleadosOfInternalCompany.map(empleado => ({
      label: `${empleado.nombre} ${empleado.apellidos}`,
      value: empleado.id,
    }))

    options.sort((a, b) => a.label.localeCompare(b.label))

    return options
  }, [users])

  const allowToSelectEmpleado = useMemo(() => config_JSON?.lineasParte?.manoObraSeleccionarEmpleado && !config_JSON?.buscadorObra?.filtroObraShowEmpresa, [config_JSON])

  const isEqual = (a: any, b: any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const changeLineHandler = (changes: any) => {
    if (!isEqual(lineas.list[props.index], changes)) {
        dispatch(updateLine(changes));
    }
  };

  useEffect(() => {
    fetchData();
  }, [lineas?.list[props.index]?.userWorker])

  useEffect(() => {
    recalculate(lineas?.list[props.index]?.startTime, false);
  }, [selectedobra])

  useEffect(() => {
    const refreshSalesPricingData = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
  
      let categoriaValue: any = lineas?.list[props.index]?.category ?? null
      let categoriaExternoValue: any = lineas?.list[props.index]?.categoryExterno ?? null
      let typeHourValue: any = lineas?.list[props.index]?.typeHour ?? null
      let costeEmpleadoValue: any = lineas?.list[props.index]?.costeEmpleado ?? lineas?.list[props.index]?.userWorker?.data?.coste ?? getCosteEmpleado()
      const horasDecimal = getHoursDecimal();

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      const optionalParams: any = {}
      if (selectedobra.Administracion) {
        const { precioValue, precioExternoValue, salesValue, salesExternoValue } = await getPrecioManoObra(categoriaValue, categoriaExternoValue, typeHourValue, costeEmpleadoValue, horasDecimal, signal);
        optionalParams.precio = precioValue
        optionalParams.precioExterno = precioExternoValue
        optionalParams.data_sales_pricing = salesValue
        optionalParams.data_sales_pricing_externo = salesExternoValue
      }

      const { article, articleExterno } = getArticleManoObra(categoriaValue, categoriaExternoValue, typeHourValue);

      changeLineHandler({
        ...lineas.list[props.index],
        article,
        articleExterno,
        ...optionalParams,
        user: lineas?.list[props.index]?.userWorker?.id
      });
    }

    if (loaded) {
      refreshSalesPricingData()
    }
  }, [lineas?.list?.[props.index]?.category, lineas?.list?.[props.index]?.typeHour, lineas?.list[props.index]?.costeEmpleado, lineas?.list[props.index]?.startTime, lineas?.list[props.index]?.numberHours, lineas?.list[props.index]?.endTime])
  const getPrecioManoObra = async (categoriaValue: any, categoriaExternoValue: any, typeHourValue: any, costeEmpleado: any, quantity: any, signal?: AbortSignal) => {
    let precioValue = null
    let precioExternoValue = null
    let salesValue = null
    let salesExternoValue = null

    const fetching = async (categoria: any, typeHourValue: any, costeEmpleado: any, quantity: any, company: any) => {
      let precioValue = null
      let salesValue = null

      if (categoria && typeHourValue) {
        const empresaSelected = empresas.find((emp: any) => emp.Empresa == company)
        const manoObraSelected = manoObrasList.find(
          (c: any) => c.Categoria == categoria && c.Tipo_Hora == typeHourValue && c.Ambito == empresaSelected.Ambito
        )

        if (!manoObraSelected) {
          precioValue = NO_MATCH_CATEGORY_TYPEHOUR
          salesValue = NO_MATCH_CATEGORY_TYPEHOUR
        } else {
          try {
            const salesResponse = await erpService.getSalesPricing(manoObraSelected, selectedobra, quantity, lineas.list[props.index], signal)
            const salesPricing = salesResponse?.data?.data?.data_sales_pricing

            precioValue = costeEmpleado
            salesValue = salesPricing
          } catch (error) {
            precioValue = 0
            salesValue = {}
          }
        }
      }

      return { precioValue, salesValue }
    }

    if (selectedobra.Administracion && categoriaValue && typeHourValue && manoObrasList && manoObrasList.length > 0 && config_JSON?.ftp_method == "endpoint") {
      if (typeHourValue == constants.tipo_hora_varios.TipoHora) {
        const dataFetchingPromises = calendariosEnTipoDeHoraVarios.map(async calendario => {
          const dataFetching = await fetching(categoriaValue, calendario.TipoHora, costeEmpleado, quantity, selectedobra.company)
          const dataFetchingExterno = await fetching(categoriaExternoValue, calendario.TipoHora, costeEmpleado, quantity, selectedobra.companyDestino)
          const precio = dataFetching.precioValue
          const precioExterno = dataFetchingExterno.precioValue
          const sales = dataFetching.salesValue
          const salesExterno = dataFetchingExterno.salesValue
          return { calendario, precio, precioExterno, sales, salesExterno }
        })
        const allResults = await Promise.all(dataFetchingPromises)
        precioValue = allResults.map(result => ({ calendario: result.calendario, precio: result.precio }))
        precioExternoValue = allResults.map(result => ({ calendario: result.calendario, precio: result.precioExterno }))
        salesValue = allResults.map(result => ({ calendario: result.calendario, sales: result.sales }))
        salesExternoValue = allResults.map(result => ({ calendario: result.calendario, sales: result.salesExterno }))
      } else {
        const dataFetching = await fetching(categoriaValue, typeHourValue, costeEmpleado, quantity, selectedobra.company)
        const dataFetchingExterno = await fetching(categoriaExternoValue, typeHourValue, costeEmpleado, quantity, selectedobra.companyDestino)
        precioValue = dataFetching.precioValue
        precioExternoValue = dataFetchingExterno.precioValue
        salesValue = dataFetching.salesValue
        salesExternoValue = dataFetchingExterno.salesValue
      }
    } else if (!selectedobra.Administracion) {
      precioValue = costeEmpleado ?? 0
      precioExternoValue = costeEmpleado ?? 0
      salesValue = null
      salesExternoValue = null
    }

    return { precioValue, precioExternoValue, salesValue, salesExternoValue }
  }

  const getArticleManoObra = (categoriaValue: any, categoriaExternoValue: any, typeHourValue: any) => {
    let articleValue: any = null
    let articleExternoValue: any = null

    const getting = (categoriaValue: any, typeHourValue: any, company: any) => {
      let articleValue = null

      if (categoriaValue && typeHourValue) {
        const empresaSelected = empresas.find((emp: any) => emp.Empresa == company)

        const manoObraSelected = manoObrasList.find(
          (c: any) => c.Categoria == categoriaValue && c.Tipo_Hora == typeHourValue && c.Ambito == empresaSelected.Ambito
        )

        if (!manoObraSelected) {
          articleValue = NO_MATCH_CATEGORY_TYPEHOUR
        } else {
          articleValue = manoObraSelected.Articulo
        }
      }

      return articleValue
    }

    if (categoriaValue && typeHourValue && manoObrasList && manoObrasList.length > 0) {
      if (typeHourValue == constants.tipo_hora_varios.TipoHora) {
        const dataGettingPromises = calendariosEnTipoDeHoraVarios.map(calendario => {
          const dataGetting = getting(categoriaValue, calendario.TipoHora, selectedobra.company)
          const dataGettingExterno = getting(categoriaExternoValue, calendario.TipoHora, selectedobra.companyDestino)
          return { calendario, article: dataGetting, articleExterno: dataGettingExterno }
        })
        articleValue = dataGettingPromises.map(result => ({ calendario: result.calendario, article: result.article }))
        articleExternoValue = dataGettingPromises.map(result => ({ calendario: result.calendario, article: result.articleExterno }))
      } else {
        articleValue = getting(categoriaValue, typeHourValue, selectedobra.company)
        articleExternoValue = getting(categoriaExternoValue, typeHourValue, selectedobra.companyDestino)
      }
    }

    return {
      article: articleValue,
      articleExterno: articleExternoValue
    }
  }

  const updateData = async (resetByUserWorker?: UserWithDataParsed) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    let userWorkerValue: UserWithDataParsed | undefined = (resetByUserWorker || (lineas?.list[props.index]?.userWorker ?? null))
    let categoriaValue: any = lineas?.list[props.index]?.category ?? null
    let categoriaExternoValue: any = lineas?.list[props.index]?.categoryExterno ?? null
    let typeHourValue: any = lineas?.list[props.index]?.typeHour ?? null
    let precioValue: any = lineas?.list[props.index]?.precio ?? null
    let precioExternoValue: any = lineas?.list[props.index]?.precioExterno ?? null
    let newDataSales: any = lineas?.list[props.index]?.data_sales_pricing ?? null
    let newDataSalesExterno: any = lineas?.list[props.index]?.data_sales_pricing_externo ?? null
    let articleValue: any = lineas?.list[props.index]?.article ?? null
    let articleExternoValue: any = lineas?.list[props.index]?.articleExterno ?? null
    let costeEmpleadoValue: any = (lineas?.list[props.index]?.costeEmpleado ?? lineas?.list[props.index]?.userWorker?.data?.coste) ?? getCosteEmpleado()
    let allowParteFuturo: boolean = false;
    const horasDecimal = getHoursDecimal()

    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    //ponemos el empleado de la mano de obra por defecto
    if (!lineas?.list[props.index]?.userWorker) {
      const userCabecera = users.find(user => user.id == (selectedobra?.userEmpleadoId ?? getUserId()))
      userWorkerValue = userCabecera
    }

    //ponemos la categoria por defecto
    if ((categorias && categorias.length > 0 && !lineas?.list[props.index]?.category) || resetByUserWorker) {
      const categoriasDelEmpleado = getCategoriasByCompany(userWorkerValue)
      const categoriaInterno = categoriasDelEmpleado.find(c => c.empresa == selectedobra.company)?.categoria ?? null
      const categoriaExterno = categoriasDelEmpleado.find(c => c.empresa == selectedobra.companyDestino)?.categoria ?? null

      const categoriaDefault = categorias.find(
        (c: any) => c.Categoria == manoObraCategoriaDefault
      );
      categoriaValue = categoriaInterno ?? categoriaDefault?.Categoria
      categoriaExternoValue = categoriaExterno
    }

    //ponemos el tipo de hora por defecto
    const availableHoras = getTipoHoras(categoriaValue, resetByUserWorker);
    const linea = lineas?.list[props.index];
    const hayTipoHoras = tipoHoras && tipoHoras.length > 0;

    if ((hayTipoHoras && !linea?.typeHour) || resetByUserWorker) {
      if (availableHoras.length > 1) {
        const defaultIsInAvailable = availableHoras.some((th: any) => th.value === manoObraTipoHoraDefault);
        const typeHour = tipoHoras.find((c: any) => c.TipoHora === manoObraTipoHoraDefault);

        allowParteFuturo = typeHour?.ParteFuturo === 'Si';
        typeHourValue = defaultIsInAvailable ? typeHour?.TipoHora : availableHoras[0].value;
      }
    } else if (hayTipoHoras && linea?.typeHour) {
      const typeHour = tipoHoras.find((c: any) => c.TipoHora === +linea.typeHour);
      allowParteFuturo = typeHour?.ParteFuturo === 'Si';
    }

    // Si typeHourValue no está en availableHoras, asignamos el primero disponible
    if (availableHoras.length && availableHoras.every((th: any) => th.value !== +typeHourValue)) {
      typeHourValue = availableHoras[0].value;
    }

    //ponemos el precio de la mano de obra por defecto
    if ((!lineas?.list[props.index]?.precio) || resetByUserWorker) {
      const { precioValue: newPrecio, precioExternoValue: newPrecioExterno } = await getPrecioManoObra(categoriaValue, categoriaExternoValue, typeHourValue, costeEmpleadoValue, horasDecimal, signal)
      precioValue = newPrecio
      precioExternoValue = newPrecioExterno
    }

    //ponemos el coste si es un parte de admin
    if ((!lineas?.list[props.index]?.data_sales_pricing) || resetByUserWorker) {
      const { salesValue: newSales, salesExternoValue: newSalesExterno } = await getPrecioManoObra(categoriaValue, categoriaExternoValue, typeHourValue, costeEmpleadoValue, horasDecimal, signal)
      newDataSales = newSales
      newDataSalesExterno = newSalesExterno
    }

    //ponemos el articulo de la mano de obra por defecto
    if ((!lineas?.list[props.index]?.article) || resetByUserWorker) {
      const { article, articleExterno } = getArticleManoObra(categoriaValue, categoriaExternoValue, typeHourValue)
      articleValue = article
      articleExternoValue = articleExterno
    }

    let extraOpcional = {}
    if (newDataSales) {
      extraOpcional = {
        ...extraOpcional,
        data_sales_pricing: newDataSales,
        data_sales_pricing_externo: newDataSalesExterno
      }
    }

    const toUpdate = {
      category: categoriaValue,
      categoryExterno: categoriaExternoValue,
      typeHour: typeHourValue,
      precio: precioValue,
      precioExterno: precioExternoValue,
      article: articleValue,
      allowParteFuturo,
      articleExterno: articleExternoValue,
      costeEmpleado: costeEmpleadoValue,
      userWorker: userWorkerValue,
      user: null,
      ...extraOpcional
    }

    if (toUpdate) {
      changeLineHandler({
        ...lineas.list[props.index],
        ...toUpdate
      });
    }

    setLoaded(true)
  }

  useEffect(() => {
    updateData()
  }, [categorias, tipoHoras, lineas.list[props.index]?.typeHour]);

  const fetchData = async () => {
    const res: any = await reportLinesService.getReporteLinesUser(lineas?.list[props.index]?.userWorker?.id ?? props?.data?.usuario, props?.data?.id ?? null);
    setAllLinesUser(res?.data);
  }

  const existeSolapamiento = (linea: Linea, allLinesUserDataMO: Linea[]): boolean => {
    if (!linea?.datos) return false;

    const { inicio: linea_inicio, fin: linea_finRaw, userWorker } = linea.datos;
    const madrugada = linea_inicio > linea_finRaw;
    const linea_fin = madrugada ? linea_finRaw + 86400000 : linea_finRaw;

    const userId = userWorker?.id;
    if (!userId) return false;

    const checkOverlap = (_linea: Linea): boolean => {
      const { inicio: _linea_inicio, fin: _linea_finRaw } = _linea.datos;
      const _madrugada = _linea_inicio > _linea_finRaw;
      const _linea_fin = _madrugada ? _linea_finRaw + 86400000 : _linea_finRaw;

      return _linea_inicio < linea_fin && _linea_fin > linea_inicio;
    };

    const linesWithInicioAndFinEditing = allLinesUserDataMO?.filter(
      (_linea) => _linea.datos.inicio && _linea.datos.fin && (_linea.datos?.user || _linea.datos?.userWorker?.id) === userId && !_linea.datos.idParte
    ) ?? [];

    const linesWithInicioAndFinInApi = allLinesUserDataMO?.filter(
      (_linea) => _linea.datos.inicio && _linea.datos.fin && _linea.datos.idParte
    ) ?? [];

    return linesWithInicioAndFinInApi.some(checkOverlap) || linesWithInicioAndFinEditing.some(checkOverlap);
  };

  const validaErrores = (linea: any) => {

    const allLinesUserData = allLinesUser?.data?.map((linea: any) => {
      const date = new Date(linea?.date);

      const lineParsed = reportLinesService.parseReportLine(linea, date)
      return {
        ...lineParsed,
        idLinea: linea.id,
        date_format: date.toLocaleDateString(),
      }
    });

    const date = selectedobra?.date ? new Date(selectedobra.date) : new Date();

    // Validamos si hay horas solapadas
    let solapamiento = 0;
    let solapamientoLinea = 0;
    const date_parte = date.toLocaleDateString();



    const lineParsed = reportLinesService.parseReportLine(linea, date)
    if (existeSolapamiento(lineParsed, allLinesUserData)) {
      solapamiento = solapamientoLinea = 1;
    }

    const madrugada = lineParsed?.datos?.inicio > lineParsed?.datos?.fin;
    let noMadrugada = madrugada && !manoObraDiferenteDia


    const _lineas = lineas?.list?.filter((_l: any) => _l?.index !== linea?.index)?.map((_l: any) => {
      const lineParsed2 = reportLinesService.parseReportLine(_l, date)

      return {
        ...lineParsed2,
        date_format: date_parte,

      }
    }
    );
    if (existeSolapamiento(lineParsed, _lineas)) {
      solapamiento = solapamientoLinea = 1;
    }

    // Validamos si el precio es correcto
    let incorrectPrice = 0
    if (linea && linea.typeHour == constants.tipo_hora_varios.TipoHora && linea?.precio && Array.isArray(linea.precio)) {
      const hasAnyPriceError = linea.precio.some((calendario: any) => calendario.precio == NO_MATCH_CATEGORY_TYPEHOUR)
      if (hasAnyPriceError) incorrectPrice = 1
    } else if (linea && linea.precio == NO_MATCH_CATEGORY_TYPEHOUR) {
      incorrectPrice = 1
    }

    // Validamos si el article es correcto
    let incorrectArticle = 0
    if (linea && linea.article == NO_MATCH_CATEGORY_TYPEHOUR) {
      incorrectArticle = 1
    }


    if (solapamiento || noMadrugada || incorrectPrice || incorrectArticle) {
      if (props?.setSolapamiento) props?.setSolapamiento({
        index: linea.index,
        value: 1
      });
    } else {
      if (props?.setSolapamiento) props?.setSolapamiento({
        index: linea.index,
        value: 0
      });
    }


    if (solapamientoLinea && props.editable) {
      return (
        <Grid item xs={12} md={12}>
          <p style={{ color: "red" }}>Existen horas ya imputadas para la fecha seleccionada</p>
        </Grid>
      )
    }
    if (noMadrugada && props.editable) {
      return (
        <Grid item xs={12} md={12}>
          <p style={{ color: "red" }}>No puede poner horas en la madrugada, crear un nuevo parte</p>
        </Grid>
      )
    }
    if (incorrectPrice && props.editable) {
      return (
        <Grid item xs={12} md={12}>
          <p style={{ color: "red" }}>
            {linea.typeHour == constants.tipo_hora_varios.TipoHora ? (
              "No hay un precio disponible para algún calendario, teniendo en cuenta la categoría y el tipo de hora del calendario."
            ) : (
              "No hay un precio disponible para la categoría y tipo de hora seleccionadas."
            )}
          </p>
        </Grid>
      )
    }
    if (incorrectArticle && props.editable) {
      return (
        <Grid item xs={12} md={12}>
          <p style={{ color: "red" }}>No hay un articulo disponible para la categoría y tipo de hora seleccionadas</p>
        </Grid>
      )
    }
  }

  // 

  const filterCalendar = ({ c, linea_fin, linea_inicio, madrugada, hora_fin, hora_inicio, referencia_obra, diaSemana, diaSemanaM }: any) => {
    const horaIncluded =
      (
        (
          hora_inicio < Number(c?.HoraFin) &&
          hora_fin > Number(c?.HoraInicio) &&
          c?.[diaSemana]
        )
        ||
        (
          madrugada &&
          (
            (
              0 < Number(c?.HoraFin) &&
              hora_fin > Number(c?.HoraInicio) &&
              c?.[diaSemanaM]
            )
            ||
            (
              hora_inicio < Number(c?.HoraFin) &&
              2400 > Number(c?.HoraInicio) &&
              c?.[diaSemana]
            )
          )
        )
      )
    // console.log('horaIncluded', c,  linea_inicio, linea_fin, horaIncluded, c?.[diaSemanaM], linea_fin >= c?.inicio, linea_inicio <= c?.fin);
    return (
      linea_fin >= c?.inicio &&
      linea_inicio <= c?.fin &&
      horaIncluded

    )
  }

  const cleanSolapados = (_calendarios: any) => {
    let _calendariosClean: any = [];
    _calendarios.map((c: any) => {
      const _calendariosClean2 = _calendariosClean.filter((c2: any) => Number(c2?.HoraInicio) < Number(c?.HoraFin) && Number(c2?.HoraFin) > Number(c?.HoraInicio));
      if (_calendariosClean2.length == 0) {
        _calendariosClean.push(c);
      }
    })
    return _calendariosClean;
  }


  const obtenerTipoHora = (lineaA: any, dateMod: any) => {
    const date = selectedobra?.date ? new Date(selectedobra.date) : new Date();

    const linea: any = reportLinesService.parseReportLine({ ...lineaA, ...dateMod }, date);
    // Validamos que calendarios de config estè en true;
    if (!config_calendarios?.calendario) return linea?.datos?.typeHour;
    if (!linea?.datos?.inicio || !linea?.datos?.fin || !linea?.datos?.endTime || !linea?.datos?.startTime) {
      return linea?.datos?.typeHour;
    }




    let _calendarios = [];
    // Validamos config para usar el calendario correcto
    const referencia_obra = selectedobra?.calRef
    const linea_inicio = linea?.datos?.inicio;
    const hora_inicio = Number(linea?.datos?.startTime.replace(":", ""));
    const hora_fin = Number(linea?.datos?.endTime.replace(":", ""));
    let linea_fin = linea?.datos?.fin;
    const madrugada = linea_inicio > linea_fin;
    linea_fin = madrugada ? linea_fin + 86400000 : linea_fin;
    //TODO: revisar las que son de madrugada que dia de la semana son
    const days = ["D", "L", "M", "X", "J", "V", "S"]
    const diaK = new Date(linea_inicio).getDay();
    const diaSemana = days[diaK];
    const diaSemanaM = days[diaK + 1];

    if (
      config_calendarios.referencia_obra
    ) {

      // Antes que cualquier cosa validamos que no sea una Obra = 1000 (  Informar tipos de horas especiales ) 
      // Si el capítulo seleccionado no tiene referencia seguimos con el proceso actual
      if (
        (
          selectedobra?.tipo_obra == 1000
          && linea?.datos?.chapter?.Referencia
        )
      ) {
        const _tipo_hora = tipoHoras.find((t: any) => t.TipoHora == linea?.datos?.chapter?.Referencia);
        if (!_tipo_hora) alert('Tipo hora no encontrada');
        return _tipo_hora.TipoHora;
      }

      _calendarios =
        calendarios
          .filter((c: any) => c?.Codigo == referencia_obra)
          .filter((c: any) => filterCalendar({ c, linea_fin, linea_inicio, madrugada, hora_fin, hora_inicio, referencia_obra, diaSemana, diaSemanaM }))
          .sort((a: any, b: any) => b?.Orden - a?.Orden);

    }
    // console.log('_calendarios', _calendarios);

    if (_calendarios?.length == 0) {
      // Usamos calendario por defecto
      _calendarios =
        calendarios
          .filter((c: any) => c?.Codigo == config_calendarios.calendario_defecto)
          .filter((c: any) => filterCalendar({ c, linea_fin, linea_inicio, madrugada, hora_fin, hora_inicio, referencia_obra, diaSemana, diaSemanaM }))
          .sort((a: any, b: any) => b?.Orden - a?.Orden);
    }

    if (_calendarios?.length == 0) {
      alert('Ningun calendario encontrado');
      return linea?.datos?.typeHour;
    }

    // console.log('_calendarios', _calendarios);
    _calendarios = cleanSolapados(_calendarios)
    // console.log('_calendarios', _calendarios);


    if (
      _calendarios?.length > 1
    ) {
      setCalendariosEnTipoDeHoraVarios(_calendarios)
      return constants.tipo_hora_varios.TipoHora;
    }

    const tipoHoraMax = _calendarios[0]?.TipoHora;
    const _tipo_hora = tipoHoras.find((t: any) => t.TipoHora == tipoHoraMax)?.NombreTipoHora;
    if (!_tipo_hora && _calendarios?.length) alert('Tipo hora no encontrada');

    return tipoHoraMax

  }


  const { manoObra, manoObraTipoHora, manoObraTipoHoraDefault, descManoObra, showManoObraCategoria, showManoObraTipoHora, manoObraCategoria, manoObraCategoriaDefault, manoObraDiferenteDia } =
    configurationService.getConfigLineasParte();

  let startTime = lineas?.list[props.index]?.startTime;



  const getEndHour = (number?: string) => {
    const numberHours = number
      ? number
      : lineas?.list[props.index]?.numberHours;

    if (!startTime || !numberHours) {
      return;
    }

    const startSecs = calculateSeconds(startTime.replace('null', '00').split(":"));

    const nHoursSeconds = calculateSeconds(numberHours.replace('null', '00').split(":"));

    return calculateTime(startSecs + nHoursSeconds);
  };

  const getHours = (hour?: string) => {
    const endTime = hour ? hour : lineas?.list[props.index]?.endTime;
    if (!startTime || !endTime) {
      return;
    }

    const startSecs = calculateSeconds(startTime.replace('null', '00').split(":"));
    const endSecs = calculateSeconds(endTime.replace('null', '00').split(":"));

    const difference = Math.abs(startSecs - endSecs);

    let totalHour = calculateTime(difference);
    if (endSecs < startSecs) {
      const x = calculateTime(difference).split(":");
      totalHour = `${24 - Number(x[0])}:${x[1]}`;
    }

    return totalHour;
  };

  const recalculate = (hour: string, changeInitial: boolean) => {
    startTime = hour;

    let dates = {
      startTime: startTime,
      endTime: changeInitial ? undefined : lineas.list[props.index]?.endTime,
      numberHours: changeInitial ? undefined : lineas.list[props.index]?.numberHours,
    }
    if (getHorasFinDisabled() && !changeInitial) dates = { ...dates, endTime: getEndHour() }
    if (getNumberHoursDisabled()) dates = { ...dates, numberHours: getHours() }

    const newTypeHour = obtenerTipoHora(lineas.list[props.index], dates)

    changeLineHandler({
      ...lineas.list[props.index],
      ...dates,
      typeHour: newTypeHour,
      user: lineas?.list[props.index]?.userWorker?.id
    });
  };

  const activitiesOptions = (activities: Activity[]) =>
    activities.map((act) => ({
      ...act,
      label: act.description,
      value: act.activity_code,
    }));

  const subActivitiesOptions = (subActivities: SubActivity[]) =>
    lineas.list[props.index]?.activity
      ? subActivities.reduce<(SubActivity & { label: string; value: string })[]>(
        (acc, act) => {
          if (act.activity_code === lineas.list[props.index]?.activity) {
            acc.push({
              ...act,
              label: act.description,
              value: act.subactivity_code,
            });
          }
          return acc;
        },
        []
      )
      : [];


  const getChapterOptions = (): any[] => {
    if (!selectedobra.capitulosByPresupuesto) {
      return [];
    }
    return selectedobra.capitulosByPresupuesto?.map((cap: Capitulo) => ({
      ...cap,
      label: cap.label,
      value: cap.Capitulo,
    }));
  };

  const categoriasOptions = useMemo(() => {
    return categorias.map((cat: Categoria) => ({
      value: cat.Categoria,
      label: cat.NombreCategoria,
    }));
  }, [categorias]);

  const getTipoHoras = (category = null, userWorker: UserWithDataParsed | null = null) => {
    const categoriaSelected = category ?? lineas.list[props.index]?.category
    const userWorkerSelected = userWorker ?? (lineas?.list[props.index]?.userWorker as UserWithDataParsed)
    let resultTipoHoras = tipoHoras

    if (tipoHoras && tipoHoras.length && manoObrasList && manoObrasList.length && (categoriaSelected || categoriaSelected == 0)) {
      // Primero filtro de tabla tipoHorasByEmpleado.
      let flagByEmpleado = false
      if (tipoHorasByEmpleado && tipoHorasByEmpleado.length && userWorkerSelected) {
        const companies_employees = [...userWorkerSelected.data.internal, ...userWorkerSelected.data.external]
        const empleadoFiltro = tipoHorasByEmpleado.filter((th: any) => th.Empresa == selectedobra.company && th.Empleado == companies_employees.find(ce => ce.empresa == selectedobra.company)?.empleado)
        if (empleadoFiltro.length > 0) {
          const empleadoOpciones = empleadoFiltro.map((th: any) => th.Tipo_Hora)
          resultTipoHoras = tipoHoras.filter((th: any) => empleadoOpciones.includes(th.TipoHora))
          flagByEmpleado = true
        }
      }

      // Si no entramos en el primero, filtramos por categoría en mano de obra.
      if (!flagByEmpleado) {
        const manoObrasByCategorySelected = manoObrasList.filter((manoObra: any) => manoObra.Categoria == categoriaSelected)
        const tiposDeHorasParaSeleccionar = manoObrasByCategorySelected.map((manoObra: any) => manoObra.Tipo_Hora)
        resultTipoHoras = tipoHoras.filter((hora: any) => tiposDeHorasParaSeleccionar.includes(hora.TipoHora))
      }
    }

    resultTipoHoras = [...resultTipoHoras, constants.tipo_hora_varios]
    return resultTipoHoras.map((tipHora: TipoHora) => ({
      value: tipHora.TipoHora,
      label: tipHora.NombreTipoHora,
      disabled: tipHora.TipoHora == 1000
    }));
  };

  const getHorasFinDisabled = () => {
    if (!props.editable) {
      return true;
    }
    return manoObra !== "hora";
  };

  const getNumberHoursDisabled = () => {
    if (!props.editable) {
      return true;
    }
    return manoObra !== "cantidad";
  };

  const getCategoryDisabled = () => {
    if (!props.editable) {
      return true;
    }
    return !manoObraCategoria;
  };

  const getTipoHorasDisabled = () => {
    if (!props.editable || lineas.list[props.index]?.typeHour == constants.tipo_hora_varios.TipoHora) {
      return true;
    }
    return !manoObraTipoHora;
  };

  const getHoursDecimal = (linea?: any) => {
    const date = selectedobra?.date ? new Date(selectedobra.date) : new Date();
    const line = linea ?? lineas.list[props.index]
    const lineParsed = reportLinesService.parseReportLine(line, date)
    return lineParsed?.horas ? Number(lineParsed?.horas) : 0
  }

  return (
    <Grid
      className="line-services-container"
      container
      spacing={1}
      alignItems={"center"}
    >
      <Grid
        className="flex-row"
        item
        xs={12}
        md={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img className="logo mr-10" src={manoDeObraIcon} alt="service" />
        <h4>MO</h4>
      </Grid>
      <Grid item xs={12} md={10} container spacing={2}>
        {allowToSelectEmpleado && (
          <Grid item xs={12} md={3}>
            <label>Empleado</label>
            <EsfirusSelect
              options={empleadosOptions}
              change={(e: string) => {
                const userSelected = users.find(user => user.id == e)
                updateData(userSelected)
              }}
              value={lineas.list[props.index]?.userWorker?.id}
              disabled={!props.editable || !!lineas.list[props.index]?.idLinea}
              placeholder="Seleccionar"
            />
          </Grid>
        )}
        {selectedobra.SolActividad &&
          <>
            <Grid item xs={12} sm={6} md={3}>
              <label>Actividad</label>
              <EsfirusSelect
                options={activitiesOptions(activities)}
                optionsStyle={{ textAlign: "left", fontWeight: "bold" }}
                value={lineas.list[props.index]?.activity}
                disabled={!props.editable}
                change={(activity: string) => {
                  changeLineHandler({
                    ...lineas.list[props.index],
                    activity: activity,
                    subactivity: ''
                  });
                }}
                placeholder="Actividad"
              ></EsfirusSelect>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <label>Subactividad</label>
              <EsfirusSelect
                options={subActivitiesOptions(subActivities)}
                optionsStyle={{ textAlign: "left", fontWeight: "bold" }}
                value={lineas.list[props.index]?.subactivity}
                change={(subactivity: string) => {
                  changeLineHandler({
                    ...lineas.list[props.index],
                    subactivity: subactivity
                  });
                }}
                placeholder="Subactividad"
                disabled={
                  !props.editable ||
                  !lineas.list[props.index]?.activity ||
                  !subActivitiesOptions(subActivities).length}
              ></EsfirusSelect>
            </Grid>
          </>
        }




        {(!selectedobra.Administracion && (selectedobra.SolCapitulo &&
          selectedobra.capitulosByPresupuesto.length !== 0)) && (
            <Grid item xs={12} md={3}>
              <label>Capítulo</label>
              <EsfirusSelect
                options={getChapterOptions()}
                optionsStyle={{ textAlign: "left", fontWeight: "bold" }}
                value={lineas?.list[props.index]?.chapter?.Capitulo}
                change={(e: any) => {
                  const selectedChapter =
                    selectedobra.capitulosByPresupuesto.find(
                      (el: Capitulo) => el.Capitulo == e
                    );
                  const newTypeHour = obtenerTipoHora(lineas.list[props.index], { chapter: selectedChapter })

                  changeLineHandler({
                    ...lineas.list[props.index],
                    typeHour: newTypeHour,
                    chapter: selectedChapter,
                    user: lineas?.list[props.index]?.userWorker?.id
                  });
                }}
                placeholder={"Capítulo"}
                disabled={!props.editable}
                modified={!!lineas.list[props.index]?.validationModifications?.datos?.chapter}
              ></EsfirusSelect>
            </Grid>
          )}

        {showManoObraCategoria && (
          <Grid item xs={12} md={3}>
            <label>Categoria</label>
            <EsfirusSelect
              options={categoriasOptions}
              value={lineas.list[props.index]?.category}
              change={(e: any) => {
                const selectedCategoria = categorias.find(
                  (cat: Categoria) => cat.Categoria == Number(e)
                );

                changeLineHandler({
                  ...lineas.list[props.index],
                  category: selectedCategoria?.Categoria,
                  user: lineas?.list[props.index]?.userWorker?.id
                });
              }}
              disabled={getCategoryDisabled()}
              modified={!!lineas.list[props.index]?.validationModifications?.datos?.category}
            ></EsfirusSelect>
          </Grid>
        )}



        {showManoObraTipoHora && <Grid item xs={12} md={3}>
          <label>Tipo de hora</label>
          <EsfirusSelect
            options={getTipoHoras()}
            change={(e: any) => {
              changeLineHandler({
                ...lineas.list[props.index],
                typeHour: e,
                user: lineas?.list[props.index]?.userWorker?.id
              });
            }}
            value={lineas.list[props.index]?.typeHour}
            disabled={getTipoHorasDisabled()}
            placeholder={"Tipo Hora"}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.typeHour}
          ></EsfirusSelect>
        </Grid>
        }
        {descManoObra && (
          <Grid item xs={12} md={12}>
            <label>Descripción</label>
            <EsfirusInputTextAreaNative
              min={10}
              max={800}
              value={lineas.list[props.index]?.description}
              required={true}
              change={(e: any) =>
                changeLineHandler({
                  ...lineas.list[props.index],
                  description: e,
                  user: lineas?.list[props.index]?.userWorker?.id
                })
              }
              disabled={!props.editable}
              modified={!!lineas.list[props.index]?.validationModifications?.datos?.description}
            ></EsfirusInputTextAreaNative>
          </Grid>
        )}

        <Grid item xs={6} md={2}>
          <label>Hora inicio</label>
          <EsfirusHourInput
            id={"start-time-date"}
            value={lineas?.list[props.index]?.startTime}
            change={(hour: any) => {
              recalculate(hour, true);
            }}
            disabled={!props.editable}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.startTime}
          ></EsfirusHourInput>
        </Grid>

        <Grid item xs={6} md={2}>
          <label>Nº Horas</label>
          <EsfirusHourInput
            id={"number-hours-date"}
            style={{ backgroundColor: lineas?.list[props.index]?.numberHours == "00:00" ? "#ff42426b" : "" }}
            disabled={getNumberHoursDisabled()}
            value={lineas?.list[props.index]?.numberHours}
            change={(hour: any) => {
              const newEndTime = getEndHour(hour)
              const newTypeHour = obtenerTipoHora(lineas.list[props.index], { endTime: getEndHour(hour), startTime: lineas?.list[props.index]?.startTime })

              changeLineHandler({
                ...lineas.list[props.index],
                endTime: newEndTime,
                numberHours: hour,
                typeHour: newTypeHour,
                user: lineas?.list[props.index]?.userWorker?.id
              });
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.numberHours}
            isQuantity
            initialHourQuantity={lineas.list[props.index]?.startTime ?? "00:00"}
          ></EsfirusHourInput>
        </Grid>

        <Grid item xs={6} md={2}>
          <label>Hora fin</label>
          <EsfirusHourInput
            id={"end-hours-date"}
            disabled={getHorasFinDisabled()}
            value={lineas?.list[props.index]?.endTime}
            change={(hour: any) => {
              const newNumberHours = getHours(hour)
              const newTypeHour = obtenerTipoHora(lineas.list[props.index], { endTime: hour, startTime: lineas?.list[props.index]?.startTime })

            changeLineHandler({
                ...lineas.list[props.index],
                endTime: hour,
                numberHours: newNumberHours,
                typeHour: newTypeHour,
                user: lineas?.list[props.index]?.userWorker?.id
              });
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.endTime}
          ></EsfirusHourInput>
        </Grid>

        {
          validaErrores(lineas.list[props.index])
        }

      </Grid>
    </Grid>
  );
}

export default LineasmanoDeObra;
