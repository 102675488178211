import { configureStore } from '@reduxjs/toolkit';

import lines from './slices/lines';
import partes from './slices/partes';
import obras from './slices/obras';
import presupuestos from './slices/presupuestos';
import capitulos from './slices/capitulos';
import categorias from './slices/categorias';
import empleados from './slices/empleados';
import empresas from './slices/empresas';
import tipoHora from './slices/tipoHora';
import selectedobra from './slices/selectedObra';
import articulosGastosServicios from './slices/articulos-gastos-servicios';
import user from './slices/user';
import reports from './slices/reports';
import validationParte from './slices/validation';
import configurations from './slices/configs';
import calendarios from './slices/calendarios';
import mano_obra from './slices/manoObra';
import proveedores from './slices/proveedores';
import notices from './slices/notices';
import timeTracking from './slices/timeTracking';
import productsBarcodes from './slices/productsBarcodes';
import users from './slices/users';
import tableFilters from './slices/tableFilters';
import activitiesAndSubActivities from './slices/activitiesAndSubActivities';

const store = configureStore({
  reducer: {
    lines,
    partes,
    obras,
    selectedobra,
    presupuestos,
    capitulos,
    categorias,
    empleados,
    empresas,
    articulosGastosServicios,
    tipoHora,
    user,
    validationParte,
    reports,
    configurations,
    calendarios,
    mano_obra,
    proveedores,
    notices,
    timeTracking,
    productsBarcodes,
    users,
    tableFilters,
    activitiesAndSubActivities
  },
});

export type AppStore = typeof store
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

export default store