import Header from "@components/Header/Header";
import Loader from "@components/ui/Loader/Loader";
import EsfirusResumeAsync from "@components/ui/ResumeAsync/EsfirusResumeAsync";
import { emptyResourceRedirect } from "@helpers/navigate";
import { Box } from "@mui/material";
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";
import usePartesGet from "@services/hooks/usePartesGet";
import { canSupervise, isEmpleado } from "@services/hooks/UserHook";
import { usersService } from "@services/users";
import { resetLines } from "@store/slices/lines";
import { setSelectedEmpleadoHome } from "@store/slices/partes";
import { deleteReport } from "@store/slices/reports";
import { removeSelectedObra } from "@store/slices/selectedObra";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PartesHeader from "./BBs/header/header";
import PartesSubHeader from "./BBs/subheader/subheader";
import EsfirusPartesTable from "./BBs/table/Table";
import { sortEmpleados } from "@helpers/utils";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partes = useSelector((state) => (state as any).partes);
  const selectedDateFrom = useSelector((state) => (state as any).partes.selectedDateFrom);
  const selectedEmpresa = useSelector((state) => (state as any).partes.selectedEmpresa);
  const selectedEmpleado = useSelector((state) => (state as any).partes.selectedEmpleadoHome);
  const customFilters = useSelector((state) => (state as any).tableFilters['partes']?.sortOrder);
  const empresas = useSelector((state) => (state as any).empresas).list;
  const selectedDateTo = useSelector((state) => (state as any).partes.selectedDateTo);
  const configuration = useSelector((state) => (state as any).configurations.config);
  const [search, setFilterBySearch] = useState<any>("");
  const selectedTypeHour = partes.selectedTypeHour;
  const obras = useSelector((state) => (state as any).obras).list;

  const filterByType = useSelector((state) => (state as any).partes.filterByType);

  const [currentFilters, setCurrentFilters] = useState([]);
  const totalItems = partes.totalItems;
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>({ page: 0, sizePage: 50 })


  const getPartes = usePartesGet()
  const [empleadosList, setEmpleadosList] = useState<any>([]);

  useEffect(() => {
    emptyResourceRedirect({ check: obras.length === 0, path: "/partes", navigate });

    const filters = {
      dateFrom: selectedDateFrom,
      dateTo: selectedDateTo,
      search: search,
      empresa: selectedEmpresa,
      empleado: selectedEmpleado,
    }

    let sort: Array<string> = [];

    if (customFilters) {
      sort = [customFilters.field.keymap, customFilters.ascending ? 'asc' : 'desc'];
    }

    const differentFilters = JSON.stringify(customFilters) !== JSON.stringify(currentFilters);

    if (differentFilters) {
      setCurrentFilters(customFilters);
    }

    dispatch(getPartes(pagination.page, pagination.sizePage, filterByType, filters, sort, differentFilters) as any);
    dispatch(removeSelectedObra());
    dispatch(resetLines());

    if (empresas && empresas.length) {
      const permitirSeleccionDeEmpleados = canSupervise()
      if (permitirSeleccionDeEmpleados) {
        fetchEmpleados()
      } else {
        dispatch(setSelectedEmpleadoHome(localStorage?.getItem("ef-user-id") as any))
      }
    }
  }, [empresas, configuration, pagination, filterByType, selectedDateFrom, selectedDateTo, search, selectedEmpresa, selectedEmpleado]);

  const fetchEmpleados = async () => {
    const filters = {
      dateFrom: selectedDateFrom,
      dateTo: selectedDateTo,
      search: search,
      empresa: selectedEmpresa,
      empleado: selectedEmpleado,
    }

    try {
      const resp = await usersService.getListByPartesFilter({ estado: filterByType, filters });
      const empleados = sortEmpleados(resp?.data?.data ?? []);
      const empleadosWithData = empleados.map((empleado: any) => ({ ...empleado, data: JSON.parse(empleado.data) }));
      const empleadosOptions = empleadosWithData.map((empleado: any) => {
        const { internal = [], external = [] } = empleado.data;
        const idsEmpresas = [...internal, ...external].map(e => e.empresa)
        const empresaObject = empresas.find((empresa: any) => idsEmpresas.includes(empresa.Empresa));

        const label = `${empleado.nombre} ${empleado.apellidos} - ${empresaObject.Nombre}`;
        const value = empleado.id;

        return ({
          label,
          value,
        })
      });

      setEmpleadosList(empleadosOptions);
    } catch (error) {
      setEmpleadosList([]);
    }
  }

  const deleteParte = (id: any) => {
    dispatch(deleteReport(id) as any)
      .then(
        () => dispatch(getPartes(0, pagination.sizePage, filterByType) as any)
      );
  };
  
  const partesOrdered = useMemo(() => {
    return partes?.list?.toSorted((a: any, b: any) => b.date - a.date);
  }, [partes]);

  const handleChangePagination: HandleChangePaginationType = (newPaginationData) => {
    setPagination(newPaginationData)
  }

  return (
    <>
      <Header />
      <div className="center">
        <PartesHeader showResumenButton={!isEmpleado() || configuration?.report?.reportCerrarDias}></PartesHeader>
        <PartesSubHeader
          setFilterBySearch={setFilterBySearch}
          selectedEmpresa={selectedEmpresa}
          selectedDate={selectedDateFrom}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          selectedEmpleado={selectedEmpleado}
          empleadosList={empleadosList}
          selectedTypeHour={selectedTypeHour}
       />
        
        <EsfirusPartesTable
          partes={(partesOrdered as any) || []}
          empresas={empresas}
          deleteReport={deleteParte}
          pagination={pagination}
          totalItems={totalItems}
          handleChangePagination={handleChangePagination}
          tableId="partes"
          loading={partes.loading}
        >
          <Box className="ml-20 mr-20 mt-40 mb-40">
            <Loader message="Cargando partes" />
          </Box>
        </EsfirusPartesTable>
        <EsfirusResumeAsync />
      </div>
    </>
  );
}

export default Home;
