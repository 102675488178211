import { Empresa } from "@models/empresa";
import { createSlice } from "@reduxjs/toolkit";

interface EmpresasSliceType {
  list: Empresa[]
}

const initial: EmpresasSliceType = {
  list: [],
};

export const empresasSlice = createSlice({
  name: "empresas",
  initialState: { ...initial },
  reducers: {
    resetEmpresas: (state) => {
      state.list = [...initial.list];
    },
    setempresas: (state, action) => {
      state.list = [...action.payload];
    },
  },
});

export default empresasSlice.reducer;
export const { setempresas, resetEmpresas } = empresasSlice.actions;
