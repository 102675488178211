import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSelector } from "@services/hooks/redux-hooks";
import { Grid, InputAdornment } from "@mui/material";

import EsfirusIcon from "@components/ui/icons/EsfirusIcon";
import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import ModalNewObra from "@screens/NewParte/BBs/ModalNewObra/ModalNewObra";
import DatePickerUI from "@components/ui/DatePickerUI/DatePickerUI";

import zoomIcon from "@assets/images/icons/zoomIcon.png";
import { FormDataAviso } from "@components/FormParte/FormAviso";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import { CustomerAddress } from "@models/customer-address";
import TimePickerUI from "@components/ui/TimePickerUI/TimePickerUI";
import { getInternalCompany } from "@helpers/companyData";
import { Obra } from "@models/obra";

interface HeaderFormAvisoProps {
  formData: FormDataAviso
  setFormData: Dispatch<SetStateAction<FormDataAviso>>
  editable: boolean
  customerAddressesData: {
    loading: boolean
    list: CustomerAddress[]
  }
}

const HeaderFormAviso = ({ formData, setFormData, editable, customerAddressesData }: HeaderFormAvisoProps) => {
  const noticesTypes = useSelector(state => state.notices.listTypes)
  const noticesStatuses = useSelector(state => state.notices.listStatuses)
  const noticesPriorities = useSelector(state => state.notices.listPriorities)
  const users = useSelector(state => state.users.list)

  const [openObraModal, setOpenObraModal] = useState(false)

  const empleadosOptions = useMemo(() => {
    const empleadosOfInternalCompany = users.filter(em => {
      const isInInternalArray = em.data.internal.some(v => v.empresa == getInternalCompany())
      const isInExternalArray = em.data.external.some(v => v.empresa == getInternalCompany())
      return (isInInternalArray || isInExternalArray)
    })

    const options = empleadosOfInternalCompany.map(empleado => ({
      label: `${empleado.nombre} ${empleado.apellidos}`,
      value: empleado.id,
    }))

    return options
  }, [users])

  const selectObra = async (obra: Obra) => {
    let customerAddress = formData.customerAddress

    if (formData.obra?.Cliente != obra.Cliente) {
      customerAddress = null
    }

    setFormData({ ...formData, obra: obra, customerAddress })
  }

  return (
    <>
      <Grid container spacing={{ xs: 1, md: 4 }} >
        <Grid item xs={12} sm={6} md={4}>
          <label>Empresa</label>
          <EsfirusTextInput disabled fullWidth value={formData.company?.Nombre} />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Obra</label>
          <EsfirusTextInput
            fullWidth
            aria-readonly
            value={formData?.obra?.description || formData?.obra?.name || formData?.obra?.Descripcion}
            size="small"
            inputProps={{ readOnly: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className="pointer"
                  onClick={() => setOpenObraModal(true)}
                  disablePointerEvents={!editable}
                >
                  <EsfirusIcon path={zoomIcon} />
                </InputAdornment>
              ),
            }}
            disabled={!editable}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Direcciones de clientes</label>
          <EsfirusSelect
            options={customerAddressesData.list.map(address => ({ value: address.address_id, label: address.name }))}
            change={(e: any) => {
              setFormData({ ...formData, customerAddress: customerAddressesData.list.find(ca => ca.address_id == e) ?? null })
            }}
            disabled={!editable || !formData.obra?.codigoCliente || customerAddressesData.loading}
            value={formData.customerAddress?.address_id}
            placeholder="Seleccionar..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Tipo de aviso</label>
          <EsfirusSelect
            options={noticesTypes.map(nt => ({ value: nt.notification_type, label: nt.description }))}
            change={(e: any) => {
              setFormData({ ...formData, noticeType: noticesTypes.find(nt => nt.notification_type == e) ?? null })
            }}
            disabled={!editable}
            value={formData.noticeType?.notification_type}
            placeholder="Seleccionar..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Estado</label>
          <EsfirusSelect
            options={noticesStatuses.map(ns => ({ value: ns.status, label: ns.description }))}
            change={(e: any) => {
              setFormData({ ...formData, noticeStatus: noticesStatuses.find(nt => nt.status == e) ?? null })
            }}
            disabled={!editable}
            value={formData.noticeStatus?.status}
            placeholder="Seleccionar..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Prioridad</label>
          <EsfirusSelect
            options={noticesPriorities.map(np => ({ value: np.priority, label: np.description }))}
            change={(e: any) => {
              setFormData({ ...formData, noticePriority: noticesPriorities.find(nt => nt.priority == e) ?? null })
            }}
            disabled={!editable}
            value={formData.noticePriority?.priority}
            placeholder="Seleccionar..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <label>Fecha</label>
          <DatePickerUI
            value={formData.date.toString()}
            readOnly
            disabled={!editable}
            handleChange={() => { }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <label>Hora</label>
          <TimePickerUI
            value={formData.time}
            readOnly
            disabled={!editable}
            handleChange={() => { }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Empleado responsable</label>
          <EsfirusSelect
            options={empleadosOptions}
            change={(e: any) => {
              setFormData({ ...formData, responsibleEmployee: users.find(em => em.id == e) ?? null })
            }}
            disabled={!editable}
            value={formData.responsibleEmployee?.id}
            placeholder="Seleccionar..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Empleado destino</label>
          <EsfirusSelect
            options={empleadosOptions}
            change={(e: any) => {
              setFormData({ ...formData, targetEmployee: users.find(em => em.id == e) ?? null })
            }}
            disabled={!editable}
            value={formData.targetEmployee?.id}
            placeholder="Seleccionar..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Empleado origen</label>
          <EsfirusSelect
            options={empleadosOptions}
            change={(e: any) => {
              setFormData({ ...formData, originEmployee: users.find(em => em.id == e) ?? null })
            }}
            disabled={!editable}
            value={formData.originEmployee?.id}
            placeholder="Seleccionar..."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <label>Decripción corta</label>
          <EsfirusTextInput
            fullWidth
            disabled={!editable}
            value={formData.shortDescription}
            onChange={(e: any) => {
              setFormData({ ...formData, shortDescription: e.target.value })
            }}
            placeholder="Escribe una descripción"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <label>Comentarios</label>
          <EsfirusTextInput
            fullWidth
            disabled={!editable}
            value={formData.comments}
            onChange={(e: any) => {
              setFormData({ ...formData, comments: e.target.value })
            }}
            placeholder="Escribe los comentarios"
          />
        </Grid>
      </Grid>

      {/* Modales de selección */}
      {openObraModal && (
        <ModalNewObra
          open={openObraModal}
          setOpen={setOpenObraModal}
          selectObra={selectObra}
          onlyInternalCompany={true}
        />
      )}
    </>
  );
}

export default HeaderFormAviso