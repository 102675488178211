import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { configurationService } from "@services/configApp";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EsfirusButton from "../../../../components/ui/Button/EsfirusButton";
import EsfirusTextInput from "../../../../components/ui/Text-input/EsfirusTextInput";
import { Obra, ObraStatus } from "../../../../models/obra";
import "./ModalNewObra.scss";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import { getAvailableCompanies, getInternalCompany, getInternalReference, getReferenceByCompany } from "@helpers/companyData";
import alertIcon from "@assets/svgs/alert-triangle-outline.svg";
import { checkResponsableObraIsIntern } from "@screens/NewParte/helpers";
import useDebounce from "@services/hooks/useDebounce";
import { InputAdornment } from "@mui/material";
import EsfirusIcon from "@components/ui/icons/EsfirusIcon";
import zoomIcon from "@assets/images/icons/zoomIcon.png";
import { isAdmin, isEmpleado, isJefeObra, isSupervisor } from "@services/hooks/UserHook";
import { Empleado } from "@models/empleado";
import { Empresa } from "@appTypes/common";

interface ModalNewObraProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectObra: (obra: Obra) => Promise<void>;
  onlyInternalCompany?: boolean;
}

function ModalNewObra({ open, setOpen, selectObra, onlyInternalCompany = false }: ModalNewObraProps) {
  const dispatch = useDispatch();
  const obras = useSelector((state) => (state as any).obras).list;
  const empresas = useSelector((state) => (state as any).empresas).list;
  const empleados = useSelector((state) => (state as any).empleados).list;
  const {
    filtroObraShowEmpresa,
    filtroObraShowReferencia,
    filtroObraShowResponsable,
    filtroObraShowCliente,
    allowObraWithoutResponsable,
    strictFindObra
  } = configurationService.getConfigBuscadorObra();

  const { autoRefParte } =
    configurationService.getConfigCabeceraParte();

  const [selectedEmpresa, setSelectedEmpresa] = useState(getInternalCompany());
  const [selectedCliente, setSelectedCliente] = useState('');
  const [empresasDisponibles, setEmpresasDisponibles] = useState<any>([]);
  const [empresasList, setEmpresasList] = useState<any>([]);
  const [obrasList, setObrasList] = useState([]);
  const [loadingObras, setLoadingObras] = useState<boolean>(false);
  const [strictFound, setStrictFound] = useState<boolean>(true);
  const [currentInput, setCurrentInput] = useState<string>();
  
  const inputRefs = useRef<{ [key: string]: HTMLInputElement }>({});
  
  const timeDebounce = 1000
  const [filterDescription, setFilterDescription] = useState('');
  const [filterObra, setFilterObra] = useState('');
  const [filterReferencia, setFilterReferencia] = useState('');
  const [filterResponsable, setFilterResponsable] = useState('');
  const [filterCliente, setFilterCliente] = useState('');
  // Debounce values
  const debouncedFilterDescription = useDebounce(filterDescription, timeDebounce);
  const debouncedFilterObra = useDebounce(filterObra, timeDebounce);
  const debounceFilterReferencia = useDebounce(filterReferencia, timeDebounce);
  const debounceFilterResponsable = useDebounce(filterResponsable, timeDebounce);
  const debounceFilterCliente = useDebounce(filterCliente, timeDebounce);


  const [currentObra, setCurrentObra] = useState<Obra>({
    name: "",
    description: "",
    obraCode: 0,
    reference: "",
    calRef: "",
    responsible: 0,
    status: ObraStatus.OPEN,
    company: 0,
    companyDestino: 0,
    Administracion: false,
    SolActividad: false,
    SolCapitulo: false,
    SolPresupuesto: false,
  });

  const collectData = async () => {
    setLoadingObras(true);
  
    const idEmpleado = getInternalReference();
    const idEmpresaBase = getInternalCompany();
    const estadosValidos = ['EC', 'GA'];
  
    // Procesar obras con responsables
    const obrasPreParsed = obras?.map((obra: any) => {
      const responsable = findResponsableByCodigos(obra.Empresa, obra.Responsable);
      return {
        ...obra,
        responsableLabel: responsable ? `${responsable.Empleado} - ${responsable.Nombre}` : `${obra.Responsable}`
      };
    }) ?? [];

    // Función para filtrar por código de obra
    const filterObraCode = (obra: any) => {
      if (filterObra === '' && !strictFound) return true;

      const obraNombre = obra?.Obra?.toString()?.toLowerCase();
      const filterValue = filterObra.toLowerCase();
      return strictFound ? obraNombre === filterValue : obraNombre?.includes(filterValue);
    };

    const checkDelegaciones = (obra: Obra) => {
      if (filtroObraShowEmpresa) {
        return false;
      }

      let delegaciones = Array.from(new Set(empleados
        .filter((e: Empleado)=> e.Empleado === idEmpleado && e.Delegacion !== null && e.Delegacion !== undefined)
        .map((e: any) => e.Delegacion)
      ));

      if ((isEmpleado() || isJefeObra()) && !delegaciones.length) {
        delegaciones = [0];
      }
  
      if (isSupervisor() && !delegaciones.length) {
        const empresasDelEmpleado = empleados
          .filter((e: Empleado)=> e.Empleado === idEmpleado)
          .map((e: Empleado) => e.Empresa);
  
          delegaciones = Array.from(new Set(
            empresas
              .filter((e: Empresa) => empresasDelEmpleado.includes(e.Empresa))
              .map((e: Empresa) => e.Delegacion)
          ));
        }
        
        return (!isAdmin() && !delegaciones.includes(obra.Delegacion))
    }

    // Filtrado y transformación de datos en una sola pasada
    const obrasDef = obrasPreParsed.reduce((acc: any[], obra: any) => {
      if (
        (selectedEmpresa !== '' && obra.Empresa != selectedEmpresa) ||
        (filterDescription !== '' && !obra.Descripcion?.toLowerCase()?.includes(filterDescription.toLowerCase())) ||
        (filterObra !== '' && !obra.Obra?.toString()?.toLowerCase()?.includes(filterObra.toLowerCase())) ||
        (filterReferencia !== '' && !obra.Referencia?.toLowerCase()?.includes(filterReferencia.toLowerCase())) ||
        (filterResponsable !== '' && !obra.responsableLabel?.toString()?.toLowerCase()?.includes(filterResponsable.toLowerCase())) ||
        (filterCliente !== '' && !obra.NombreCliente?.toString()?.toLowerCase()?.includes(filterCliente.toLowerCase())) ||
        obra.EmpresaDestino || !estadosValidos.includes(obra.Estado) || checkDelegaciones(obra) || !filterObraCode(obra)
      ) {
        return acc;
      }

      acc.push({
        ...obra,
        id: obra.id,
        prefixId: obra.prefixId,
        name: obra.NombreCliente,
        nombreCliente: obra.NombreCliente,
        codigoCliente: obra.Cliente,
        description: obra.Descripcion,
        obraCode: obra.Obra,
        delegacion: obra.Delegacion,
        obraExternaCode: obra.ObraExterna,
        empleado: getReferenceByCompany(obra.Empresa),
        empleadoExterno: idEmpresaBase != obra.Empresa ? idEmpleado : null,
        reference:
          autoRefParte == 'codEmpleado'
            ? getReferenceByCompany(obra.Empresa)
            : autoRefParte == 'codReferencia'
            ? obra.Referencia
            : '',
        referenciaExterna:
          autoRefParte == 'codReferencia'
            ? obra.Referencia
            : idEmpresaBase != obra.Empresa
            ? idEmpleado
            : "",
        responsible: obra.Responsable,
        status: obra.Estado,
        company: obra.Empresa,
        companyDestino: idEmpresaBase != obra.Empresa ? idEmpresaBase : null,
        Administracion: obra.Administracion,
        date: obra.Fecha,
        calRef: obra.Referencia,
        SolActividad: obra.SolActividad,
        SolCapitulo: obra.SolCapitulo,
        SolPresupuesto: obra.SolPresupuesto,
        duplicated: obra.duplicated,
        descripcion_list: `${obra?.Obra} - ${obra?.Descripcion} (${obra?.Empresa}-${String(obra?.Delegacion).padStart(2, '0')})`,
        tipo_obra: obra.Tipo
      });

      return acc;
    }, []);

    // Verificar responsables internos
    const checksResponsable = await checkResponsableObraIsIntern(
      obrasDef.map((obra: Obra) => {
          const id = `${obra.Obra}-${obra.company}-${obra.responsible}`;

          return { empresa: obra.company, empleado: obra.responsible, id };
        }
      )
    );
    
    // Asignar verificación de responsables a cada obra
    const resultObras = obrasDef.map((obra: Obra) => {
      const id = `${obra.Obra}-${obra.company}-${obra.responsible}`;
      
      return {
        ...obra,
        checkResponsable: checksResponsable[id] ?? false,
      };
    });
    
    setObrasList(resultObras);
    setLoadingObras(false);
  };

  useEffect(() => {
    const isExternal = Number(selectedEmpresa) !== getInternalCompany();

    if(strictFindObra === 'all' || (strictFindObra === 'only_external' && isExternal)) {
      setObrasList([]);
      return setStrictFound(true);
    }

    setStrictFound(false);
  }, [setStrictFound, strictFindObra, selectedEmpresa])

  useEffect(() => {
    let companies: any[] | "*" = []
    if (onlyInternalCompany) companies = [getInternalCompany()]
    else companies = getAvailableCompanies(true)
    setEmpresasDisponibles(companies)
  }, [])

  useEffect(() => {
    if (!loadingObras && currentInput) {
      inputRefs.current[currentInput]?.focus();
    }
  }, [loadingObras]);

  useEffect(() => {
    setEmpresasList(empresas.filter((e: any) => empresasDisponibles.includes(e.Empresa) || empresasDisponibles === "*"));
  }, [empresasDisponibles, selectedEmpresa])

  useEffect(() => {
    if (!strictFound) {
      const timeout = setTimeout(() => {
        if (!strictFound) collectData();
      });
  
      return () => clearTimeout(timeout);
    }
  }, [selectedEmpresa, strictFound, debounceFilterCliente, obras, debouncedFilterDescription, debouncedFilterObra, debounceFilterReferencia, debounceFilterResponsable]);
  
  const findResponsableByCodigos = (empresaId: number | string, empleadoId: number | string) => {
    if (!empleados || empleados.length === 0) return null
    const responsable = empleados.find((empleado: any) => empleado.Empresa === empresaId && empleado.Empleado === empleadoId)
    return responsable
  }

  const acceptClose = async () => {
    await selectObra(currentObra);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-obra-modal">
        <div className="header">
          <h5 className="text-center">Selecciona la obra</h5>
        </div>
        <div className="body">
          <p className="text-center-mobile">
            Filtra el listado de obras mediante los siguientes campos:
          </p>

          <Grid container spacing={4} md={12} >
            {
              filtroObraShowEmpresa &&
              <Grid item md={6} xs={12}>
                <label >Empresa</label>
                <div className="selectWrap">
                  <EsfirusSelect
                    options={empresasList.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}
                    value={selectedEmpresa}
                    change={(e: any) => {
                      let val = !!e ? e : 10000000
                      setSelectedEmpresa(val);
                    }}
                    disabled={loadingObras || onlyInternalCompany}
                    placeholder="Seleccionar empresa"></EsfirusSelect>
                </div>
              </Grid>
            }
            <Grid item md={6} xs={12}>
              <label>Descripción</label>
              <EsfirusTextInput
                ref={(el: HTMLInputElement) => (inputRefs.current['description'] = el)}
                value={filterDescription || ""}
                onChange={(e: any) => {
                  setCurrentInput('description');
                  setFilterDescription(e.target.value)
                  }
                }
                placeholder="Descripción"
                disabled={loadingObras || strictFound}
                fullWidth
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <label >Código obra</label>
              <EsfirusTextInput
                ref={(el: HTMLInputElement) => (inputRefs.current['obra'] = el)}
                value={filterObra || ""}
                onChange={(e: any) => {
                  setCurrentInput('obra');
                  setFilterObra(e.target.value)
                }}
                placeholder="Código obra"
                disabled={loadingObras}
                fullWidth
                InputProps={strictFound && {
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      className="pointer"
                      onClick={collectData}
                    >
                      <EsfirusIcon path={zoomIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>


            {
              filtroObraShowReferencia && (
                <Grid item md={6} xs={12}>
                  <label >Referencia</label>
                  <EsfirusTextInput
                    ref={(el: HTMLInputElement) => (inputRefs.current['reference'] = el)}
                    value={filterReferencia}
                    onChange={(e: any) => {
                      setCurrentInput('reference');
                      setFilterReferencia(e.target.value)
                    }}
                    placeholder="Referencia"
                    disabled={loadingObras || strictFound}
                    fullWidth
                  />
                </Grid>
              )}

            {
              filtroObraShowResponsable && (
                <Grid item md={6} xs={12}>
                  <label >Responsable</label>
                  <EsfirusTextInput
                    ref={(el: HTMLInputElement) => (inputRefs.current['responsible'] = el)}
                    value={filterResponsable}
                    onChange={(e: any) => {
                      setCurrentInput('responsible');
                      setFilterResponsable(e.target.value)
                    }}
                    placeholder="Responsable"
                    disabled={loadingObras || strictFound}
                    fullWidth
                  />
                </Grid>
              )}

            {filtroObraShowCliente &&
              <Grid item md={6} xs={12}>
                <label >Cliente</label>
                <div className="selectWrap">

                  <EsfirusTextInput
                    ref={(el: HTMLInputElement) => (inputRefs.current['client'] = el)}
                    value={filterCliente}
                    onChange={(e: any) => {
                      setCurrentInput('client');
                      setFilterCliente(e.target.value)
                    }}
                    placeholder="Cliente"
                    disabled={loadingObras || strictFound}
                    fullWidth
                  />
                </div>
              </Grid>
            }

          </Grid>

          <Grid container spacing={4} md={12}  >
            <Grid className="mt-20" item md={12} xs={12}>

              <div className="obra-container">
                <div className="scroll">
                  {loadingObras ? (
                    <div className="obra-item">
                      Cargando obras...
                    </div>
                  ) : (
                    <>
                      {obrasList.map((obra: any, index: any) => {
                        const isSelected = currentObra.obraCode === obra.obraCode
                        const isDuplicated = obra.duplicated
                        const hasResponsable = obra.checkResponsable
                        const showErrorFormat = isDuplicated || !hasResponsable
                        const isSelectable = !isDuplicated && (allowObraWithoutResponsable || hasResponsable)

                        return (
                          <div
                            key={`${obra.name}-${obra.reference}-${index}`}
                            className={`obra-item${isSelected ? " selected" : ""}${showErrorFormat ? " duplicated" : ""}${isSelectable ? " selectable" : ""}`}
                            onClick={() => {
                              if (isSelectable) setCurrentObra(obra)
                            }}
                            onDoubleClick={async () => {
                              if (isSelectable) await acceptClose()
                            }}
                          >
                            {obra.descripcion_list || "--"}
                            {isDuplicated ? <span><img src={alertIcon} /> Existe mas de una obra en empresaDestino en el mismo año</span> : <></>}
                            {!hasResponsable ? <span><img src={alertIcon} /> No tiene un responsable interno.</span> : <></>}
                          </div>
                        );
                      })}
                      {obrasList.length === 0 && (
                        <div className={`obra-item`}>
                          No existe ninguna obra con los filtros aplicados.
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Grid>

          </Grid>

          <div className="button-container">
            <EsfirusButton
              onClick={async () => await acceptClose()}
              fullWidth
              color="secondary"
            >
              Aceptar
            </EsfirusButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalNewObra;
