import Grid from "@mui/material/Grid";
import manoDeObraIcon from "@assets/svgs/lineasManoDeObra.svg";
import { EsfirusInputTextNative } from "@ui/InputTextNative/EsfirusInputTextNative";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import EsfirusButton from "@components/ui/Button/EsfirusButton";

import { useEffect, useContext } from "react";
import { AppPerfilContext } from './context/AppPerfil.context'
import { useNavigate, useParams } from 'react-router-dom';

import { getRequest, putRequest } from '../../utils/requests'

import { APP_USERS_ROLES, textos, types } from './helpers';

const AppPerfil = () => {
    const history = useNavigate();
    const { id } = useParams();
    const { state, setState } = useContext(AppPerfilContext)

    const handleChange = (e, atribute) => {
        setState(prev => ({ ...prev, [atribute]: e }));
    }

    const actualizarConfig = async (forceState) => {
        const _state = forceState ?? state
        const dataUpdate = { id, ..._state };
        await putRequest('app-users', dataUpdate);
    }

    const fetchData = async () => {
        const { data } = await getRequest(`app-users/${id}`)
        setState(prev => ({
            ...prev,
            email: data.email,
            nombre: data.nombre,
            apellidos: data.apellidos,
            rol: data.rol,
            activo: data.activo,
            data: JSON.parse(data.data)
        }))
    }

    useEffect(() => {
        fetchData();
    }, []);

    const renderAtribute = (_keyName, disabled = false) => {
        const _types = types?.find(e => e.property === _keyName);
        let _value = state?.[_keyName];

        if (_keyName === 'data') {
            _value = JSON.stringify(state?.data)
        }

        switch (_types?.type?.toString()) {
            case "select":
                return (
                    <EsfirusSelect
                        disabled={disabled}
                        options={_keyName === 'rol' ? _types?.options?.filter(rol => rol.value == _value || rol.value == APP_USERS_ROLES.admin || rol.value == state.data.originalRol) : _types?.options}
                        value={_value}
                        disableBlank
                        change={(e) => {
                            handleChange(e, _keyName)
                        }}
                    />
                )
            case "textarea":
                return (
                    <textarea
                        style={{
                            width: "100%",
                            height: 400,
                            border: "1px solid #075577",
                            borderRadius: "30px",
                            padding: "16px",
                            color: "rgba(0, 0, 0, 0.38)",
                            outline: "none",
                            background: "transparent",
                            cursor: "pointer",
                            backgroundColor: "#efefef",
                        }}
                        disabled={disabled}
                        value={_value}
                        onChange={(e) => {
                            handleChange(e.target.value, _keyName)
                        }}
                    />
                )
            default:
                return (
                    <EsfirusInputTextNative
                        disabled={disabled}
                        value={_value}
                        change={(e) =>
                            handleChange(e, _keyName)
                        }
                    />
                )
        }
    }

    const handlerSave = async () => {
        if (state?.password && state.password !== "") {
            if (window.confirm("La contraseña del usuario va a ser modificada, ¿estás seguro que deseas continuar?")) {
                await actualizarConfig()
            }
        } else {
            await actualizarConfig()
        }
    }

    return (
        <>
            <Grid
                className="line-services-container"
                container
                spacing={1}
                alignItems={"left"}
                style={{ marginBottom: 50 }}
            >
                <Grid
                    className="flex-row"
                    item
                    xs={12}
                    md={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <img className="logo mr-10" src={manoDeObraIcon} alt="service" />
                    <h4>Datos del usuario</h4>
                </Grid>
                <Grid item xs={12} md={10} container spacing={3}>
                    {
                        Object.keys(state).map((_keyName, _i) => (
                            <Grid item xs={12} md={8}>
                                <label>{textos[_keyName] ?? _keyName}</label>
                                {
                                    renderAtribute(_keyName, _keyName !== "rol" && _keyName !== "password")
                                }
                                {_keyName === "password" && (
                                    <span>Si no desea cambiar la contraseña, déjelo vacío.</span>
                                )}
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid
                className="line-services-container"
                container
                spacing={1}
                alignItems={"left"}
                justifyContent={"center"}
            >
                <Grid
                    className="flex-row"
                    item
                    xs={5}
                    md={5}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <div className="button-container center">
                        <EsfirusButton
                            onClick={() => {
                                history(-1)
                            }}
                            fullWidth
                            color="primary"
                        >
                            Regresar
                        </EsfirusButton>

                    </div>
                </Grid>
                <Grid
                    className="flex-row"
                    item
                    xs={5}
                    md={5}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <div className="button-container center">
                        <EsfirusButton
                            onClick={handlerSave}
                            fullWidth
                            color="primary"
                        >
                            Actualizar
                        </EsfirusButton>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}

export { AppPerfil };