export const TIPOS_ESTADOS_PEDIDOS = {
  ABIERTO_CONFIRMADO: {
    label: "Abierto + Confirmado",
    value: "Confirmado&Abierto",
    excludeStatus: true
  },
  ABIERTO: {
    label: "Abierto",
    value: "Abierto",
    excludeStatus: false
  },
  CONFIRMADO: {
    label: "Confirmado",
    value: "Confirmado",
    excludeStatus: false
  },
  RECEPCIONADO: {
    label: "Recepcionado",
    value: "Recepcionado",
    excludeStatus: false
  }
} as const