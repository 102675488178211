import { UserWithDataParsed } from "@models/user";
import moment from "moment";

/**
 * This function converts a number into price format string.
 * @param value Number to convert into price format.
 * @returns String with the price format
 */
export const priceFormat = (value: number): string => {
  const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(value)
}

export const dateFormat = (date: any) => {
  return moment(date).format("DD-MM-YYYY kk:mm:ss")
}

export const dateDifferenceHours = (_dateOne: any, _dateTwo: any) => {
  const dateOne = moment(_dateOne)
  const dateTwo = moment(_dateTwo)

  return dateOne.diff(dateTwo, "hours", true)
}

export const getCurrentMonth = () => {
  const now = moment()
  return ({
    monthName: now.format('MMMM'),
    yearName: now.format('YYYY'),
    monthIndex: now.month()
  })
}

export const getPreviousMonths = (numberOfMonths = 1) => {
  const previousMonths = []

  for (let i = 0; i < numberOfMonths; i++) {
    const currentMonth = moment().subtract(i, 'months')
    previousMonths.push({
      monthName: currentMonth.format('MMMM'),
      yearName: currentMonth.format('YYYY'),
      monthIndex: currentMonth.month()
    })
  }

  return previousMonths
}

export const getDaysOfMonth = (month = "January", year = "2024") => {
  const monthYearString = `${month}-${year}`
  const date = moment(monthYearString, 'MMMM-YYYY')
  const days = []

  const monthDays = date.daysInMonth()

  for (let i = 1; i <= monthDays; i++) {
    days.push(i)
  }

  return days
}

export const roundUpNumber = (number: number, precision: number) => {
  const multiplier = Math.pow(10, precision)
  return Math.ceil(number * multiplier) / multiplier
}

export const getBase64FromCanvaDiv = (idCanvaDiv: string) => {
  const canvaRef = document.getElementById(idCanvaDiv);
  const canvas = canvaRef?.querySelector("canvas");
  const dataURL = canvas?.toDataURL();

  return dataURL
}

export const convertToBase64FromFileInput = (file: File, withoutBase64Header = false): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (e: ProgressEvent<FileReader>) {
      let base64String = ""

      if (withoutBase64Header) {
        base64String = (e.target?.result as string).split(',')[1];
      } else {
        base64String = (e.target?.result as string)
      }

      resolve(base64String)
    }

    reader.onerror = () => {
      reject(null)
    }

    reader.readAsDataURL(file)
  })
}

export const delay = (temp: number) => {
  let t = temp / 1;

  return new Promise(resolve => setTimeout(resolve, t));
}

export const filterUniqueByKey = (array: any[], key: string) => {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
};

export const safeJsonParse = (input: string | null): any => {
  if (input === null) return null;

  try {
    const result = JSON.parse(input);
    return result;
  } catch (error) {
    return null;
  }
}

export const findUserByCompanyAndEmployeeIds = (users: UserWithDataParsed[], companyId: number | string, employeeId: number | string) => {
  if (companyId === "" || employeeId === "") return undefined

  return users.find(user => {
    const isInInternalArray = user.data.internal.some(v => v.empresa == companyId && v.empleado == employeeId)
    const isInExternalArray = user.data.external.some(v => v.empresa == companyId && v.empleado == employeeId)
    return (isInInternalArray || isInExternalArray)
  })
}

interface NombreSeparado {
  nombre: string;
  apellidos: string;
  empresa: string;
  value: string;
}

const separarNombreApellidos = ({ label, value }: { label: string, value: string }): NombreSeparado => {
  const [ nombreCompleto, empresa ] = label.split(' - ');
    // Separamos la cadena por espacios
    const partes = nombreCompleto.split(' ');

    let nombre = '', apellidos = '';

    if (partes.length === 2) {
        // Si tiene 2 partes, es un nombre y un apellido
        nombre = partes[0];
        apellidos = partes[1];
    } else if (partes.length === 3) {
        // Si tiene 3 partes, es un nombre y dos apellidos
        nombre = partes[0];
        apellidos = partes[1] + ' ' + partes[2];
    } else if (partes.length === 4) {
        // Si tiene 4 partes, es un nombre compuesto con dos apellidos
        nombre = partes[0] + ' ' + partes[1];
        apellidos = partes[2] + ' ' + partes[3];
    } else {
      const [ ,,,, ...rest ] = partes
        nombre = partes[0] + ' ' + partes[1];
        apellidos = partes[2] + ' ' + partes[3] + ' ' + rest.join(' ');
    }

  return {
    nombre,
    apellidos,
    empresa,
    value
  };
};


export const sortEmpleados = (empleados: any[], needsToSplit: boolean = false): any[] => {
  // Lista de preposiciones que podrían estar en los apellidos
  const preposiciones = ['santa'];

  let _empleados = needsToSplit
    ? empleados.map((e: any) => separarNombreApellidos(e))
    : empleados;

  // Función para separar el nombre y los apellidos
  const separarNombreYApellidos = ({ nombre, apellidos }: { nombre: string, apellidos: string }) => ({ nombre, apellidos });

  // Función para limpiar las preposiciones de los apellidos para comparación
  const limpiarPreposiciones = (texto: string): string => {
    return texto
      .split(' ')
      .filter((parte) => (parte.length === 2 || parte.length === 3) || !preposiciones.includes(parte.toLowerCase()))
      .join(' ');
  };

  // Función para separar los nombres compuestos
  const separarNombreCompuesto = (nombre: string): { nombreSimple: string, nombreCompuesto: string | null } => {
    const partes = nombre.split(' ');
    
    // Si el nombre tiene más de una palabra, lo tratamos como compuesto
    if (partes.length > 1) {
      return {
        nombreSimple: partes[0],  // El primer nombre es el simple
        nombreCompuesto: partes.slice(1).join(' ')  // El resto es el compuesto
      };
    }

    return { nombreSimple: partes[0], nombreCompuesto: null };
  };

  let empleadosSorted = _empleados.toSorted((a, b) => {
    let userA = a, userB = b;
    if (!needsToSplit) {
      userA = separarNombreYApellidos(a);
      userB = separarNombreYApellidos(b);  
    }

    // Separar el nombre en simple y compuesto
    const { nombreSimple: nombreA, nombreCompuesto: nombreCompuestoA } = separarNombreCompuesto(userA.nombre);
    const { nombreSimple: nombreB, nombreCompuesto: nombreCompuestoB } = separarNombreCompuesto(userB.nombre);

    // Ordenar por nombre simple (de A a Z)
    if (nombreA !== nombreB) {
      return nombreA.localeCompare(nombreB);
    }

    // Si los nombres simples son iguales, ordenamos por la segunda parte del nombre compuesto
    if (nombreCompuestoA && nombreCompuestoB) {
      const segundaParteA = nombreCompuestoA.split(' ')[0];  // Tomamos la segunda palabra del nombre compuesto
      const segundaParteB = nombreCompuestoB.split(' ')[0];
      return segundaParteA.localeCompare(segundaParteB);
    }

    // Si solo uno tiene nombre compuesto, el simple va primero
    if (nombreCompuestoA) return 1;
    if (nombreCompuestoB) return -1;

    // Ordenar por primer apellido (de A a Z)
    const apellidosA = limpiarPreposiciones(userA.apellidos);
    const apellidosB = limpiarPreposiciones(userB.apellidos);
    
    if (apellidosA !== apellidosB) {
      return apellidosA.localeCompare(apellidosB);
    }

    // Ordenar por segundo apellido (de A a Z)
    return userA.apellidos.localeCompare(userB.apellidos);
  });

  if (needsToSplit) {
    empleadosSorted = empleadosSorted.map((e: any) => ({
      label: `${e.nombre} ${e.apellidos} - ${e.empresa}`,
      value: e.value
    }));
  }

  return empleadosSorted;
};
