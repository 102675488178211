import './header.scss';
import EsfirusButton from '@ui/Button/EsfirusButton';
import EsfirusIcon from '@ui/icons/EsfirusIcon';
import userIcon from '@assets/images/icons/user.png';
import listIcon from '@assets/images/icons/listIcon.png';

import { useNavigate } from 'react-router-dom';
import { removeSelectedObra } from '@store/slices/selectedObra';
import { useDispatch } from 'react-redux';

function Header({ showResumen, showRegister, showMonthlySummary }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="ef-fichar-header noPrint">
      <EsfirusButton
        color="secondary"
        startIcon={<EsfirusIcon path={userIcon} />}
        onClick={() => {
          dispatch(removeSelectedObra());
          navigate('/time-tracking');
        }}
      >
        Fichar
      </EsfirusButton>

      {showRegister && (
        <EsfirusButton
          color="primary"
          variant="outlined"
          startIcon={<EsfirusIcon path={listIcon} />}
          onClick={() => navigate('/time-report')}
        >
          Registro
        </EsfirusButton>
      )}

      {showMonthlySummary && (
        <EsfirusButton
          color="info"
          startIcon={<EsfirusIcon path={userIcon} />}
          onClick={() => navigate('/time-month-report')}
        >
          Resumen mensual
        </EsfirusButton>
      )}
    </div>
  );
}

export default Header;
