import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";

import Header from "@components/Header/Header"
import AvisosHeader from "./BBs/AvisosHeader/AvisosHeader"

import { emptyResourceRedirect } from "@helpers/navigate"

const AvisosWrapper = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Configuración de módulo de avisos.
  const configuration = useSelector((state) => (state as any).configurations).config;
  const isAvisosActivated = configuration?.modulos?.avisos ?? false

  // Cargado recursos.
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const checkNoResources = !configuration?.modulos
    emptyResourceRedirect({ check: checkNoResources, path: pathname, navigate })
    setReady(true)
  }, [])

  if (!ready) return (<></>)

  return (
    <>
      <Header />
      {isAvisosActivated ? (
        <>
          <AvisosHeader />
          <div className="center">
            <Outlet />
          </div>
        </>
      ) : (
        <>
          <div className="center">
            <h1>No tienes el módulo de avisos activado.</h1>
          </div>
        </>
      )}
    </>
  )
}

export default AvisosWrapper