import { AppPerfil } from './AppPerfil'
import { AppPerfilProvider } from './context/AppPerfil.context'

const AppPerfilWithProvider = (props) => {
    return (
        <AppPerfilProvider>
            <AppPerfil {...props} />
        </AppPerfilProvider>
    )
}
export default AppPerfilWithProvider