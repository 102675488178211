import { fichajesService } from "@services/fichajes";

export const TIPOS_FICHAJES = {
  ENTRADA: 1,
  SALIDA: 2,
}

export const registerFichaje = (time, gpsCoords) => {
  const dataUser = JSON.parse(localStorage.getItem('ef-user-external-data')) || {};
  const { delegacion = 0, internal } = dataUser;
  const { empresa, empleado } = internal?.[0] || {empresa: null, empleado: null};

  return fichajesService.post({
    empleado,
    empresa,
    fecha: time.valueOf(),
    gps: gpsCoords,
    extra: {
      localDate: time.format('YYYY-M-D'),
      localHours: time.hours(),
      localMinutes: time.minutes(),
      localSeconds: time.seconds(),
      delegacion
    }
  });
};


export const getPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject({ source: "getPosition", error }));
  });
};

export const getFichajeType = (fichaje) => {
  const isEntrada = !fichaje.dateOut
  return isEntrada ? TIPOS_FICHAJES.ENTRADA : TIPOS_FICHAJES.SALIDA
}

export const timeFormatted = (time) => {
  return time < 10 ? "0" + time : time;
}