import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
import ModalConfirmAviso from "./BBs/ModalConfirmAviso/ModalConfirmAviso"
import FormAviso, { FormDataAviso } from "@components/FormParte/FormAviso"
import { avisosService } from "@services/avisos"
import "./NuevoAviso.scss"
import { NoticeDefinitive } from "@models/notices"
import { configurationService } from "@services/configApp"

const NuevoAviso = () => {
  const navigate = useNavigate()
  const { avisosConfirmar } = configurationService.getConfigAvisos();
  
  const [openModalConfirmAviso, setOpenModalConfirmAviso] = useState<boolean>(false)
  const [dataAvisoCreated, setDataAvisoCreated] = useState<NoticeDefinitive | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  // Función de manejo del save de datos.
  const handleSave = async (form: FormDataAviso) => {
    setIsSaving(true)

    try {
      const { data: { data } } = await avisosService.createBorrador(form);      
      let transferedStatus;
      let transferData;

      if (!avisosConfirmar) {
        try {
          const response = await avisosService.validateBorradorAviso({ id: data.unique_id });
          const transfer = response?.data?.data ?? [];
      
          transferData = transfer[0];
          transferedStatus = 'ok';
        } catch (error) {
          transferedStatus = 'ko';
        }
      }
  
      setDataAvisoCreated({ ...data, transferedStatus, transferData });
      setOpenModalConfirmAviso(true);
    } catch (error) {
      setDataAvisoCreated(null)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <>
      <div className="new-aviso-wrapper">
        <div className="new-aviso-title-wrapper">
          <h4>Aviso</h4>
          <h5>Rellena el formulario para completar la creación del aviso</h5>
        </div>
        <div className="new-aviso-body-wrapper">
          <Box className="ml-20 mr-20 mt-40">
            <FormAviso
              isSaving={isSaving}
              editable
              handleSave={handleSave}
              handleClose={() => navigate("/borradores-avisos")}
            />
          </Box>
        </div>
      </div>

      {/* Modales */}
      {openModalConfirmAviso && (
        <ModalConfirmAviso
          open={openModalConfirmAviso}
          data={dataAvisoCreated}
        />
      )}
    </>
  )
}

export default NuevoAviso