import { Activity, SubActivity } from '@models/activitiesAndSubActivities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ActivitiesAndSubActivitiesState {
  activities: Activity[];
  subActivities: SubActivity[];
}

const initialState: ActivitiesAndSubActivitiesState = {
  activities: [],
  subActivities: [],
};

export const activitiesAndSubActivitiesSlice = createSlice({
  name: 'activitiesAndSubActivities',
  initialState,
  reducers: {
    resetActivitiesAndSubActivities: (state) => {
      Object.assign(state, initialState);
    },
    setActivitiesAndSubActivities: (state, action: PayloadAction<ActivitiesAndSubActivitiesState>) => {
      state.activities = action.payload.activities;
      state.subActivities = action.payload.subActivities;
    },
  },
});

export default activitiesAndSubActivitiesSlice.reducer;
export const { resetActivitiesAndSubActivities, setActivitiesAndSubActivities } = activitiesAndSubActivitiesSlice.actions;
