import { createSlice } from "@reduxjs/toolkit";
import { UserWithDataParsed } from "@models/user";

interface UsersSliceType {
  list: UserWithDataParsed[]
}

const initial: UsersSliceType = {
  list: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState: { ...initial },
  reducers: {
    resetUsers: (state) => {
      state.list = [...initial.list];
    },
    setUsers: (state, action) => {
      state.list = [...action.payload];
    },
  },
});

export default usersSlice.reducer;
export const { setUsers, resetUsers } = usersSlice.actions;
