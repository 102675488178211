import { NoticePriority, NoticeStatuses, NoticeTypes } from "@models/notices";
import { createSlice } from "@reduxjs/toolkit";

export interface NoticesSliceType {
  listPriorities: NoticePriority[]
  listStatuses: NoticeStatuses[]
  listTypes: NoticeTypes[]
}

const initial: NoticesSliceType = {
  listPriorities: [],
  listStatuses: [],
  listTypes: [],
};

export const noticesSlice = createSlice({
  name: "notices",
  initialState: initial,
  reducers: {
    resetNotices: (state) => {
      state.listPriorities = [...initial.listPriorities];
      state.listStatuses = [...initial.listStatuses];
      state.listTypes = [...initial.listTypes];
    },
    setNoticesPriorities: (state, action) => {
      state.listPriorities = [...(action.payload ?? [])];
    },
    setNoticesStatuses: (state, action) => {
      state.listStatuses = [...(action.payload ?? [])];
    },
    setNoticesTypes: (state, action) => {
      state.listTypes = [...(action.payload ?? [])];
    },
  },
});

export default noticesSlice.reducer;
export const { setNoticesPriorities, setNoticesStatuses, setNoticesTypes, resetNotices } = noticesSlice.actions;

