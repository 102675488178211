import { useEffect, useState } from "react"
import { Add, Delete, Edit } from '@mui/icons-material';
import moment from "moment";

import ModalConfirmar from "./ModalConfirmar"
import { IconButton } from "@mui/material";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";

import { getRequest, postRequest } from "@screens/Backoffice/utils/requests"

const BuildsList = () => {
  // Carga de builds.
  const [loading, setLoading] = useState(true)
  const [builds, setBuilds] = useState([])

  // Creando nueva build.
  const [loadingCreateBuild, setLoadingCreateBuild] = useState(false)
  const [createBuildFinished, setCreateBuildFinished] = useState(false)
  const [createBuildFailed, setCreateBuildFailed] = useState(false)
  const [showModalCreateBuild, setShowModalCreateBuild] = useState(false)

  // Eliminación de una build.
  const [loadingDeleteBuild, setLoadingDeleteBuild] = useState(false)
  const [deleteBuildFinished, setDeleteBuildFinished] = useState(false)
  const [deleteBuildFailed, setDeleteBuildFailed] = useState(false)
  const [msgDeleteBuildFailed, setMsgDeleteBuildFailed] = useState(null)
  const [showModalDeleteBuild, setShowModalDeleteBuild] = useState(false)
  const [buildSelectedToDelete, setBuildSelectedToDelete] = useState(null)

  // Edición de una build.
  const [loadingEditBuild, setLoadingEditBuild] = useState(false)
  const [editBuildFinished, setEditBuildFinished] = useState(false)
  const [editBuildFailed, setEditBuildFailed] = useState(false)
  const [showModalEditBuild, setShowModalEditBuild] = useState(false)
  const [buildSelectedToEdit, setBuildSelectedToEdit] = useState(null)

  useEffect(() => {
    fetchBuilds()
  }, [])

  const fetchBuilds = async () => {
    setLoading(true)
    try {
      const compilacionesResponse = await getRequest("compilaciones")
      const compilaciones = compilacionesResponse.data
      setBuilds(compilaciones)
    } catch (error) {
      console.error(error)
      setBuilds([])
    } finally {
      setLoading(false)
    }
  }

  const createNewBuild = async (description) => {
    setLoadingCreateBuild(true)
    try {
      await postRequest("modules/sas/buildcode", { description })
      await fetchBuilds()
    } catch (error) {
      console.error(error)
      setCreateBuildFailed(true)
    } finally {
      setLoadingCreateBuild(false)
      setCreateBuildFinished(true)
    }
  }

  const deleteBuild = async (hash) => {
    setLoadingDeleteBuild(true)
    try {
      await postRequest("modules/sas/deletecode", { hash })
      await fetchBuilds()
    } catch (error) {
      const hashUsed = error?.status == 409
      setMsgDeleteBuildFailed(hashUsed ? "Hay al menos un cliente que está utilizando esta compilación. No se puede borrar." : null)
      setDeleteBuildFailed(true)
    } finally {
      setLoadingDeleteBuild(false)
      setDeleteBuildFinished(true)
    }
  }

  const editBuild = async (description) => {
    setLoadingEditBuild(true)
    try {
      await postRequest("modules/sas/editcode", { description, build: buildSelectedToEdit })
      await fetchBuilds()
    } catch (error) {
      console.error(error)
      setEditBuildFailed(true)
    } finally {
      setLoadingEditBuild(false)
      setEditBuildFinished(true)
    }
  }

  return (
    <div className="builds-list-wrapper">
      <EsfirusButtonNative
        label={(
          <>
            <Add />
            <span>Crear nueva compilación</span>
          </>
        )}
        disabled={loadingCreateBuild}
        click={() => setShowModalCreateBuild(true)}
      />
      <table className="builds-list-table">
        <thead>
          <tr>
            <th colSpan={3}>
              Listado de compilaciones
            </th>
          </tr>
          <tr>
            <th>Identificador</th>
            <th>Fecha</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr className="tr-loading">
              <td>
                Cargando las compilaciones...
              </td>
            </tr>
          ) : (
            builds.map(build => (
              <tr key={`build-item-list-${build.hash}`}>
                <td>
                  {build.hash}
                </td>
                <td>
                  {moment(build.time).format("DD-MM-YYYY, hh:mm:ss")}
                </td>
                <td>
                  {build.description || "-"}
                </td>
                <td>
                  <IconButton onClick={() => {
                    setShowModalEditBuild(true)
                    setBuildSelectedToEdit(build)
                  }}>
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton onClick={() => {
                    setShowModalDeleteBuild(true)
                    setBuildSelectedToDelete(build.hash)
                  }}>
                    <Delete color="error" />
                  </IconButton>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Modal de confirmación de creación de nueva build */}
      {(showModalCreateBuild || showModalEditBuild) && (
        <ModalConfirmar
          title={showModalEditBuild ? "Edición de una nueva compilación" : "Creación de una nueva compilación"}
          open={(showModalCreateBuild || showModalEditBuild)}
          onClose={() => {
            setShowModalCreateBuild(false)
            setShowModalEditBuild(false)
            setLoadingCreateBuild(false)
            setLoadingEditBuild(false)
            setCreateBuildFinished(false)
            setCreateBuildFailed(false)
            setEditBuildFailed(false)
            setEditBuildFinished(false)
            setBuildSelectedToEdit(null)
          }}
          initialText=""
          loading={(loadingCreateBuild || loadingEditBuild)}
          msgLoading={showModalEditBuild ? "Editando la compilación..." : "Creando nueva compilación. Este proceso tardará unos minutos."}
          finished={(createBuildFinished || editBuildFinished)}
          msgFinished="El proceso ha terminado."
          failed={(createBuildFailed || editBuildFailed)}
          msgFailed="Algo ha salido mal, vuelve a intentarlo en unos minutos."
          action={showModalEditBuild ? editBuild : createNewBuild}
          initialDescription={buildSelectedToEdit?.description || ""}
          isCreateBuild={showModalCreateBuild}
          isEditBuild={showModalEditBuild}
        />
      )}

      {/* Modal de confirmación de eliminación de una build */}
      {showModalDeleteBuild && (
        <ModalConfirmar
          title="Eliminar una compilación"
          open={showModalDeleteBuild}
          onClose={() => {
            setShowModalDeleteBuild(false)
            setLoadingDeleteBuild(false)
            setDeleteBuildFinished(false)
            setDeleteBuildFailed(false)
            setBuildSelectedToDelete(null)
          }}
          initialText={`¿Estás seguro que quieres eliminar la compilación ${buildSelectedToDelete}?`}
          loading={loadingDeleteBuild}
          msgLoading={`Eliminando la compilación ${buildSelectedToDelete}.`}
          finished={deleteBuildFinished}
          msgFinished={`Compilación ${buildSelectedToDelete} eliminada correctamente.`}
          failed={deleteBuildFailed}
          msgFailed={msgDeleteBuildFailed ?? "Algo ha salido mal, vuelve a intentarlo en unos minutos."}
          action={async () => await deleteBuild(buildSelectedToDelete)}
        />
      )}
    </div>
  )
}

export default BuildsList