import './Table.scss'
import EsfirusTable from '@components/ui/Table/EsfirusTable';
import checkIcon from "@assets/svgs/check.svg";
import confirmedIcon from "@assets/images/lock.png";
import closeIcon from "@assets/svgs/close.svg";
import { constants, rolesSupervisor } from '@helpers/constants';
import { useEffect } from 'react';
import { configurationService } from '@services/configApp';
import CollapsableText from '@components/ui/CollapsableText/EsfirusCollapsableText';

export default function EsfirusPartesTable(props: EsfirusPartesTableProps) {
  useEffect(() => { }, [
    props.partes,
    props.rol,
    props.onCheckRow,
    props.pendingSelected,
    props.seletedAll
  ])
  const columns = [
    {
      name: "Empresa - Nº Parte",
      keymap: "_id",
      see: true,
      order: true,
      orderKeymap: "id",
      width: 150

    },
    {
      name: "Fecha",
      keymap: "date",
      parse: (d: number) => 
        new Intl.DateTimeFormat('es-ES', { 
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(new Date(d)),
      order: true,
      width: 90
    },
    {
      name: "T",
      keymap: "tipo",
      order: true,
      width: 20


    },
    {
      name: "Nº y Desc. Obra",
      keymap: "nombreObra2",
      order: true,
      orderKeymap: "nombreObra",
      width: 250

    },
    {
      name: "Empleado",
      keymap: "nombre_empleado",
      order: true,
      width: 150

    },
    {
      name: "Responsable",
      keymap: "nombre_responsable",
      order: true,
      width: 150


    },
    {
      name: "Descripción",
      keymap: "lineText",
      parse: (d: Array<string>) => {
        const resp = d.map((el: string) =>
        (
          <p>{el}</p>
        )
        )
        return <CollapsableText>{resp} </CollapsableText>


      },
      order: false,
      width: 150,

    },
    {
      name: "Horario",
      keymap: "horaInicioFin",
      order: false,
      parse: (d: any, row: any) => {
        return <p>{row.horaInicio}<br />{row.horaFin}</p>
      },
      width: 60
    },
    // {
    //   name: "Hora Inicio",
    //   keymap: "horaInicio",
    //   order: false,
    //   width: 70
    // },
    // {
    //   name: "Hora Fin",
    //   keymap: "horaFin",
    //   order: false,
    //   width: 65
    // },
    {
      name: "Horas",
      keymap: "horas",
      order: true,
      width: 65
    },
    {
      name: "Imp.",
      keymap: "importe",
      parse: (d: any) => {
        return d ? Number(d).toFixed(2).replace(".00", "").replace(".", ",") + "€" : "";
      },
      order: true,
      width: 50
    },
    {
      name: "Estado",
      keymap: "validadoImg",
      width: 60
    },
  ];

  const parseRows = (rows: any) => {
    return rows.map((el: any) => {
      const empresa: any = props.empresas?.find((e: any) => e.Empresa == el.empresa);
      return {
        ...el,
        validadoImg: setValidation(el.validado),
        _id: (empresa?.Nombre ?? el.empresa) + "-" + el.id,
        nombreObra2: `${el?.idObra} - ${el.nombreObra}`,

      };
    });
  };

  const setValidation = (type: number) => {
    const icons: any = {
      0: { icon: "", class: "", type },
      1: { icon: confirmedIcon, class: "confimed-icon", type },
      2: { icon: checkIcon, class: "validated-icon", type },
      3: { icon: closeIcon, class: "rejected-icon", type },
    };

    return icons[type];
  };

  const { partesValidar } = configurationService.getConfigCabeceraParte();


  //si es es algun revisor, y la configuracion no es de cerrar dias y no es de cerrar partes, entonces se puede editar
  const editableRows = rolesSupervisor.includes(Number(props?.rol) as number) && partesValidar ? [constants.estadoParte.confirmado, constants.estadoParte.abierto] : [];

  return (
    <EsfirusTable
      rows={parseRows(props?.partes)}
      columns={columns}
      editableRows={editableRows}
      validating={true}
      onCheckRow={props.onCheckRow}
      deleteReport={props?.deleteReport}
      onCheckAll={props.onCheckAll}
      onOpenEdit={props.onOpenEdit}
      pendingSelected={props.pendingSelected}
      seletedAll={props.seletedAll}
      tableId={props.tableId}
    ></EsfirusTable>
  );
}

export interface EsfirusPartesTablePropsOption {
  label: string;
  value: any;
}
export interface EsfirusPartesTableProps {
  partes?: [];
  empresas?: [];
  rol?: number;
  onCheckRow?: Function;
  onCheckAll?: Function;
  onOpenEdit?: Function;
  deleteReport?: Function;
  pendingSelected?: any;
  seletedAll?: boolean;
  tableId: string;
}
