import Grid from "@mui/material/Grid";
import servicioIcon from "@assets/svgs/lineasServicio.svg";
import EsfirusTextInput from "@ui/Text-input/EsfirusTextInput";
import "./LineasServicio.scss";
import EsfirusSelect from "@ui/Select/EsfirusSelect";
import { useDispatch, useSelector } from "react-redux";
import { updateLine } from "@store/slices/lines";
import { Capitulo } from "@models/obra";
import { useEffect, useState } from "react";
import { erpService } from "@services/erp";
import useDebounce from "@services/hooks/useDebounce";
import { configurationService } from "@services/configApp";

interface Servicio {
  Articulo: string;
  Ambito: number;
  Descripcion: string;
  Auxiliar: string;
  Marca: string;
  Coste: number;
  Gasto: boolean;
  Publicable: boolean;
  Referencia: string;
  Servicio: boolean;
  Tarifa: number;
}

function LineasServicio(props: any) {
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);

  const config = configurationService.getConfig();
  const lineas = useSelector((state) => (state as any).lines);
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;
  const cantidadDebounced = useDebounce(lineas?.list[props.index]?.amount, 500)
  const gastosServicios = useSelector((state) => (state as any).articulosGastosServicios).list;
  const empresas = useSelector((state) => (state as any).empresas).list;

  useEffect(() => {
    const updateSalesPricing = async () => {
      const servicioRef = lineas.list[props.index]?.service
      const selectedServicio = gastosServicios.find(
        (el: Servicio) => el.Articulo == servicioRef
      )
      const salesPricingData = await fetchSalesPricingData(selectedServicio, Number(cantidadDebounced) ?? 1)

      dispatch(
        updateLine({
          ...lineas.list[props.index],
          data_sales_pricing: salesPricingData?.data_sales_pricing ?? {}
        })
      )
    }

    const goToFetchSalesPricing = selectedobra.Administracion && lineas?.list?.[props.index]?.service && config?.ftp_method == "endpoint"
    if (goToFetchSalesPricing) {
      updateSalesPricing()
    }
  }, [cantidadDebounced, lineas.list[props.index]?.service])

  const getChapterOptions = (): any[] => {
    if (!selectedobra.capitulosByPresupuesto) {
      return [];
    }

    return selectedobra.capitulosByPresupuesto?.map((cap: Capitulo) => ({
      ...cap,
      label: cap.label,
      value: cap.Capitulo,
    }));
  };

  const getServicios = () => {
    const empresaSelected = empresas.find((emp: any) => emp.Empresa == selectedobra.company)
    const servicios: Servicio[] = gastosServicios.filter(
      (el: Servicio) => el.Servicio && el.Ambito == empresaSelected.Ambito
    );
    servicios.sort((a: Servicio, b: Servicio) => {
      if (a.Articulo < b.Articulo) return -1
      if (a.Articulo > b.Articulo) return 1
      return 0
    })
    return servicios.map((el: Servicio) => ({
      value: el.Articulo,
      label: el.Articulo,
    }));
  };

  const fetchSalesPricingData = async (servicio: any, quantity: any) => {
    if (!servicio || !quantity) return {}

    const salesResponse = await erpService.getSalesPricing(servicio, selectedobra, quantity, lineas.list[props.index])
    const sales = salesResponse?.data?.data ?? {}
    return sales
  }

  return (
    <Grid
      className="line-services-container"
      container
      spacing={1}
      alignItems={"center"}
    >
      <Grid
        className="flex-row"
        item
        xs={12}
        md={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img className="logo mr-10" src={servicioIcon} alt="service" />
        <h4>SE</h4>
      </Grid>
      <Grid item xs={12} md={10} container spacing={2}>
        {!selectedobra.Administracion && (selectedobra.SolCapitulo &&
          selectedobra?.capitulosByPresupuesto?.length !== 0) && (
            <Grid item xs={12} md={4}>
              <label>Capítulo</label>
              <EsfirusSelect
                disabled={!props.editable}
                options={getChapterOptions()}
                optionsStyle={{ textAlign: "left", fontWeight: "bold" }}
                value={lineas?.list[props.index]?.chapter?.Capitulo}
                change={(e: any) => {
                  const selectedChapter =
                    selectedobra.capitulosByPresupuesto.find(
                      (el: Capitulo) => el.Capitulo == e
                    );

                  dispatch(
                    updateLine({
                      ...lineas.list[props.index],
                      chapter: selectedChapter,
                    })
                  );
                }}
                placeholder={"Capítulo"}
                modified={!!lineas.list[props.index]?.validationModifications?.datos?.chapter}
              ></EsfirusSelect>
            </Grid>
          )}

        <Grid item xs={12} md={4}>
          <label>Servicio</label>
          <EsfirusSelect
            disabled={!props.editable}
            options={getServicios()}
            value={lineas.list[props.index]?.service}
            change={(ref: any) => {
              const selectedServicio = gastosServicios.find(
                (el: Servicio) => el.Articulo == ref
              );

              // Nunca se muestra según PARTRAB-305.
              // if ((selectedobra.Administracion && !selectedServicio.Coste_admin) || (!selectedobra.Administracion && !selectedServicio.Coste)) {
              //   setHide(true)
              // } else {
              //   setHide(false)
              // }

              const precio = selectedServicio.Coste

              dispatch(
                updateLine({
                  ...lineas.list[props.index],
                  service: selectedServicio.Articulo,
                  description: selectedServicio.Descripcion,
                  amount: 1,
                  tarifa: selectedServicio.Tarifa,
                  totalPrice: precio ?? 0,
                  precio: precio ?? 0,
                })
              );
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.service}
          ></EsfirusSelect>
        </Grid>

        <Grid item xs={12} md={4}>
          <label>Descripción</label>
          <EsfirusTextInput
            fullWidth
            disabled={!props.editable}
            value={lineas?.list[props.index]?.description}
            onChange={(e: any) => {
              dispatch(
                updateLine({
                  ...lineas.list[props.index],
                  description: e.target.value,
                })
              );
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.description}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <label>Cantidad</label>
          <EsfirusTextInput
            type="number"
            disabled={!props.editable}
            value={lineas?.list[props.index]?.amount}
            InputProps={{ inputProps: { min: 1 } }}
            fullWidth
            onChange={(e: any) => {
              const newCantidad = e.target.value

              const totalPrice = Number(lineas.list[props.index].precio) * Number(newCantidad);

              dispatch(
                updateLine({
                  ...lineas.list[props.index],
                  amount: newCantidad,
                  totalPrice,
                })
              );
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.amount}
          />
        </Grid>

        {/* Ocultamos siempre precio por requerimiento de Jesús en PARTRAB-274 */}
        {/* {!hide && (
          <Grid item xs={6} md={2}>
            <label>Precio</label>
            <EsfirusTextInput
              type="number"
              fullWidth
              disabled={!props.editable}
              value={lineas?.list[props.index]?.precio}
              onChange={(e: any) => {
                const totalPrice = Number(e.target.value ?? 0) * Number(lineas.list[props.index].amount ?? 0)
                dispatch(
                  updateLine({
                    ...lineas.list[props.index],
                    precio: e.target.value,
                    totalPrice,
                  })
                );
              }}
              modified={!!lineas.list[props.index]?.validationModifications?.datos?.precio}
            />
          </Grid>
        )} */}
      </Grid>
    </Grid>
  );
}

export default LineasServicio;
