import "./LinesContainer.scss";

import { useDispatch } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { addLine, removeLine } from "@store/slices/lines";

import EsfirusButton from "@components/ui/Button/EsfirusButton";
import { LineasArticulo } from "../LineasArticulo/LineasArticulo";
import LineasGasto from "../LineasGasto/LineasGasto";
import LineasmanoDeObra from "../LineasManoDeObra/LineasManoDeObra";
import LineasServicio from "../LineasServicio/LineasServicio";
import { LineasTexto } from "../LineasTexto/LineasTexto";

import closeIcon from "@assets/images/close.png";
import servicioIcon from "@assets/svgs/lineasServicio.svg";
import lineasArticuloIcon from "@assets/svgs/lineasArticulo.svg";
import lineasmanoDeObraIcon from "@assets/svgs/lineasManoDeObra.svg";
import lineasGastoIcon from "@assets/svgs/lineasGasto.svg";
import lineasTextoIcon from "@assets/svgs/lineasTexto.svg";
import { configurationService } from "@services/configApp";

const selectConfigParteOrPedido = (isPedido = false) => {
  const configParte = configurationService.getConfigLineasParte()
  const configPedido = configurationService.getConfigLineasPedido()
  return isPedido ? configPedido : configParte
}

export default function LinesContainer(props: any) {
  const dispatch = useDispatch();
  const config: any = selectConfigParteOrPedido(props?.isPedido)
  const [solapamientoList, setSolapamientoList] = useState({});

  const addSection = (type: string) => {
    const index = Math.floor(Math.random() * 1000000000)
    dispatch(addLine({ index, type }));
    setTimeout(() => {
      let element = document.getElementById(index as any);
      element?.scrollIntoView({ behavior: "smooth" });
    }, 300);

  };

  const remove = (s: any) => {
    dispatch(removeLine({ index: s.index, idLinea: s.idLinea }));
  };

  const getLineas = (type: string) => {
    const lineas = config.lineaParte || config.lineaPedido;
    if (lineas.includes("all")) {
      return true;
    }
    return lineas?.includes(type);
  };

  const handleSolapamientoList = useCallback(({ index, value }: any) => {
    setSolapamientoList((prevList: any) => {
      if (prevList[index] === value) return prevList;
      return { ...prevList, [index]: value };
    });
  }, []);

  const newList = useMemo(() => {
    return props.lineas.reduce((acc: any, l: any) => {
      acc[l.index] = 0;
      return acc;
    }, {});
  }, [props.lineas]);

  useEffect(() => {
    if(! props?.setSolapamiento) return;

    const haySolapamiento = Object.values(solapamientoList).some(Boolean);
    props?.setSolapamiento(haySolapamiento ? 1 : 0);
  }, [solapamientoList, props.setSolapamiento]); 
  

  useEffect(() => {
    setSolapamientoList(prevList => {
      if (Object.keys(prevList).length === Object.keys(newList).length &&
          Object.entries(prevList).every(([key, val]) => newList[key] === val)) {
        return prevList;
      }
      return newList;
    });
  }, [newList]);

  return (
    <>
      {props.lineas?.map((s: any, index: number) => {
        const tiposlinea: any = {
          servicio: (
            <LineasServicio
              key={`LineaServicio-${s.index}`}
              index={index}
              editable={props.editable}
            ></LineasServicio>
          ),
          manoDeObra: (
            <LineasmanoDeObra
              key={`LineamanoDeObra-${s.index}`}
              index={index}
              editable={props.editable}
              data={props?.data}
              setSolapamiento={handleSolapamientoList}
              solapamiento={props?.solapamiento}
            ></LineasmanoDeObra>
          ),
          gasto: (
            <LineasGasto
              key={`LineaGasto-${s.index}`}
              index={index}
              editable={props.editable}
            ></LineasGasto>
          ),
          texto: (
            <LineasTexto
              key={`LineaTexto-${s.index}`}
              index={index}
              editable={props.editable}
            ></LineasTexto>
          ),
          articulo: (
            <LineasArticulo
              key={`LineaArticulo-${s.index}`}
              index={index}
              editable={props.editable}
            ></LineasArticulo>
          ),
        };

        return (
          <div className="line" id={s.index as any} key={`line-${s.index}`}>  
            {tiposlinea[s.type]}
            {props.editable && (
              <div className="image-container" onClick={() => remove(s)}>
                <img src={closeIcon} alt="close" />
              </div>
            )}

          </div>
        );


      })}

      {props.editable && (
        <>
          <h5 className="text-center header-add">Añade una nueva línea</h5>
          <div className="new-lines">
            {getLineas("manoDeObra") && (
              <div>
                <img src={lineasmanoDeObraIcon} alt="SE" />
                <EsfirusButton
                  width={180}
                  color="primary"
                  variant="outlined"
                  onClick={() => addSection("manoDeObra")}
                >
                  Mano de obra
                </EsfirusButton>
              </div>
            )}

            {getLineas("servicio") && (
              <div>
                <img src={servicioIcon} alt="SE" />
                <EsfirusButton
                  width={180}
                  color="primary"
                  variant="outlined"
                  onClick={() => addSection("servicio")}
                >
                  Servicio
                </EsfirusButton>
              </div>
            )}

            {getLineas("gasto") && (
              <div>
                <img src={lineasGastoIcon} alt="GS" />
                <EsfirusButton
                  width={180}
                  color="primary"
                  variant="outlined"
                  onClick={() => addSection("gasto")}
                >
                  Gasto
                </EsfirusButton>
              </div>
            )}

            {getLineas("texto") && (
              <div>
                <img src={lineasTextoIcon} alt="TX" />
                <EsfirusButton
                  width={180}
                  color="primary"
                  variant="outlined"
                  onClick={() => addSection("texto")}
                >
                  Texto
                </EsfirusButton>
              </div>
            )}

            {getLineas("articulo") && (
              <div>
                <img src={lineasArticuloIcon} alt="AR" />
                <EsfirusButton
                  width={180}
                  color="primary"
                  variant="outlined"
                  onClick={() => addSection("articulo")}
                >
                  Artículo
                </EsfirusButton>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
