import "./header.scss";
import EsfirusButton from "@ui/Button/EsfirusButton";
import EsfirusIcon from "@ui/icons/EsfirusIcon";
import documentIcon from "@assets/images/icons/documentIcon.png";
import listIcon from "@assets/images/icons/listIcon.png";
import userIcon from "@assets/images/icons/user.png";
import notAllowedIcon from "@assets/images/icons/notAllowed.png";
import { useNavigate } from "react-router-dom";
import { isAdmin, isEmpleado, isJefeObra, isSupervisor } from "@services/hooks/UserHook";
import { removeSelectedObra } from "@store/slices/selectedObra";
import { useDispatch } from "react-redux";

function PartesHeader({ showResumenButton, showIAButton }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="ef-pates-header">
      <EsfirusButton
        color="secondary"
        startIcon={<EsfirusIcon path={documentIcon} />}
        onClick={() => {
          dispatch(removeSelectedObra());
          navigate("/partes/nuevo");
        }}
      >
        Nuevo parte
      </EsfirusButton>
      <EsfirusButton
        color="primary"
        variant="outlined"
        startIcon={<EsfirusIcon path={listIcon} />}
        onClick={() => window.location.pathname !== '/partes' && navigate('/partes')}
      >
        Listado de partes
      </EsfirusButton>
      {(!isEmpleado()) && (
        <EsfirusButton
          color="warning"
          startIcon={<EsfirusIcon path={notAllowedIcon} />}
          onClick={() => navigate("/partes/pendientes")}
        >
          Pendiente de validar
        </EsfirusButton>
      )}
      {showResumenButton && (
        < EsfirusButton
          color="info"
          startIcon={<EsfirusIcon path={userIcon} />}
          onClick={() => navigate("/partes/resumen")}
        >
          Resumen mensual
        </EsfirusButton>
      )}
      {showIAButton && (
        < EsfirusButton
          color="info"
          startIcon={<EsfirusIcon path={userIcon} />}
          onClick={() => navigate("/chat")}
        >
          Chat
        </EsfirusButton>
      )}
    </div >
  );
}

export default PartesHeader;
