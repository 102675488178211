import { apiConfig } from "./config";
import { Configuration } from "./swagger/configuration";
import { ReportLinesApi } from "./swagger";
import { getExternalReference } from "@helpers/companyData";
import { getDateFromTime } from "@helpers/hours";
import { configurationService } from "./configApp";
var _ = require("lodash");
class ReportLinesService extends ReportLinesApi {
  accessToken = "";

  constructor(_apiConfig: Configuration) {
    super(_apiConfig);
  }

  get(months: number = 1) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    const timeDiff = new Date().getTimezoneOffset();

    return this.reportLinesGet({ headers: { Authorization: accessToken }, params: { months, timeDiff } });
  }

  getReporteLinesUser(user: string = '', exclude_idParte: any = null) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportLinesGetUser({ headers: { Authorization: accessToken }, params: { user, exclude_idParte } });
  }

  getByParteId(id: string) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportLinesIdParteGet(id, { headers: { Authorization: accessToken } });
  }

  updateReportLine(line: any) {
    const id = line.idLinea;
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportLineIdPut(id, {
      data: line,
      headers: { Authorization: accessToken },
    });
  }

  postReportLine(line: any) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportLinePost({
      data: line,
      headers: { Authorization: accessToken },
    });
  }

  deleteReportLine(line: any) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportLineIdDelete(line, {
      headers: { Authorization: accessToken },
    });
  }

  parseReportLine(line: any, dateO?: Date) {
    let lineN = _.cloneDeep(line);
    delete lineN.datos; //evitamos recurrencia
    const date = dateO ?? new Date();
    if (line.datos) {
      const data = typeof line.datos === 'string' ? JSON.parse(line.datos) : line.datos;
      let datosBd: any = {}
      Object.keys(data).forEach(key => {
        datosBd[key] = lineN?.[key] ?? data?.[key]
      });
      lineN = { ...lineN, ...datosBd }

    }

    if (lineN.startTime) lineN.startTime = lineN.startTime.replace("null", "00")
    if (lineN.endTime) lineN.endTime = lineN.endTime.replace("null", "00")

    const dates = {
      inicio:
        lineN.startTime && lineN.startTime !== ""
          ? getDateFromTime(lineN.startTime, date).getTime()
          : undefined,
      fin:
        lineN.endTime && lineN.endTime !== ""
          ? getDateFromTime(lineN.endTime, date).getTime()
          : undefined,
    };

    let horas: number | string = 0
    if (dates.inicio && dates.fin) {
      horas = dates.fin < dates.inicio ? dates.fin + 86400000 - dates.inicio : dates.fin - dates.inicio;
      horas = (horas / 3600000).toFixed(2);
    }

    return {
      tipo: lineN.typeHour,
      horas,
      importe: lineN.totalPrice ?? 0,
      datos: {
        ...lineN,
        tipo: lineN.typeHour,
        inicio: dates.inicio,
        fin: dates.fin,
        horas,
      },
    };
  }

  getValidatorBtn(selectedobra: any, lineas: any, dateInvalid: boolean) {
    let isDisabled = dateInvalid;
    const { descManoObra } = configurationService.getConfigLineasParte();
    lineas.forEach((line: any) => {
      const { type, ...props } = line;
      const requiredProps = [];

      if (!selectedobra.Administracion && selectedobra.SolCapitulo && selectedobra?.capitulosByPresupuesto?.length > 0) {
        requiredProps.push('chapter')
      }

      if (type === "manoDeObra") {
        requiredProps.push(
          "category",
          "typeHour",
          "endTime",
          "startTime",
          "numberHours"
        );
        if (props?.numberHours == "00:00") {
          isDisabled = true;
          return;
        }
        if (descManoObra) {
          if (props?.description?.length < 10) {
            isDisabled = true;
            return;
          }
          requiredProps.push('description')
        }

        if (selectedobra.SolActividad) {
          requiredProps.push(
            'activity',
            'subactivity'
          )
        }
      }

      if (type === "gasto") {
        if (props?.amount == 0) {
          isDisabled = true;
          return;
        }
        requiredProps.push("gasto");
      }

      if (type === "articulo") {
        if (props?.amount == 0) {
          isDisabled = true;
          return;
        }
        requiredProps.push("article");
      }

      if (type === "servicio") {
        if (props?.amount == 0) {
          isDisabled = true;
          return;
        }
        requiredProps.push("service");
      }

      if (type === "texto") {
        if (props?.text?.length < 10) {
          isDisabled = true;
          return;
        }
        requiredProps.push("text");
      }

      if (type === "servicio" || type === "gasto" || type === "articulo") {
        requiredProps.push("description", "amount", "tarifa", "totalPrice");
      }

      for (const prop of requiredProps) {
        if (typeof props[prop] === "undefined" || props[prop] === "" || props[prop] === null) {
          isDisabled = true;
          break;
        }
      }

      return isDisabled;
    });

    return isDisabled;
  };

}

export const reportLinesService = new ReportLinesService(apiConfig);
